import React, { useEffect } from 'react';
import MainLayout from '@components/MainLayout';
import classes from './InboxSearchPage.styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchInboxSearchData,
	getInboxDataFromGlobalState,
	setCurrChatsDataSelectedChatVisited,
} from '@store/inboxSlice/inboxSlice';
import Text from '@components/ui/Text';
import useWindowSize from '@hooks/useWindow';
import { Navigate, useSearchParams } from 'react-router-dom';
import { INBOX_PATH, mixPanelEvents } from '@utils/constants';
import { AppDispatch } from '@store/index';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import mixpanelActions from '@utils/mixpanel';
import SearchInbox from '@components/SearchPage/SearchInbox';
import withAuth from '@src/hoc/withAuth';
import { isProUserFunc } from '@src/models/user';
import DotsLoader from '@components/ui/DotsLoader';

function InboxSearchPage() {
	const { isMobile } = useWindowSize();
	const [searchParams] = useSearchParams();
	const {
		searchFetchStatus,
		searchChatsData,
		searchTermsQueryMap,
		currChatsDataSelectedChatId,
		currChatsDataSelectedChatInboxType,
		currChatDataSelectedChatVisited,
	} = useSelector(getInboxDataFromGlobalState);
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const dispatch = useDispatch<AppDispatch>();

	const isLoading = searchFetchStatus === 'loading';
	const isError = searchFetchStatus === 'error';

	const searchStr = decodeURIComponent(searchParams.get('search') ?? '');

	useEffect(() => {
		if (!searchStr) return;

		if (
			currChatsDataSelectedChatId &&
			currChatsDataSelectedChatInboxType === 'SEARCH_VIEW' &&
			currChatDataSelectedChatVisited
		) {
			dispatch(setCurrChatsDataSelectedChatVisited({ isVisited: false }));
			return;
		}

		const searchTermQuery = searchTermsQueryMap[searchStr] ?? null;

		dispatch(fetchInboxSearchData({ searchStr: searchStr, searchQuery: searchTermQuery }));
	}, [searchStr]);

	useEffect(() => {
		if (!searchStr) return;
		if (searchFetchStatus === 'success') {
			mixpanelActions.trackSearch(
				mixPanelEvents.SEARCH_RESULTS_SHOWN,
				searchStr,
				searchChatsData.length
			);
		}
	}, [searchStr, searchChatsData, searchFetchStatus]);

	const showInitialText = !isLoading && !searchStr.length;

	const showEmptyText = !isLoading && !isError && searchStr.length > 0 && !searchChatsData.length;

	const showData = !isLoading && !isError && searchStr.length > 0 && searchChatsData.length > 0;

	const showError = isError && !showInitialText;

	if (!isMobile || !isProUserFunc(userDetails)) {
		return <Navigate to={INBOX_PATH} replace />;
	}

	return (
		<MainLayout withSearchMobile>
			<div className={classes.container}>
				{showInitialText && (
					<Text variant="p" secondary small light customClass={classes.text}>
						{`You can search for specific people or a category for people.`}
					</Text>
				)}
				{showEmptyText && (
					<Text variant="p" secondary small light customClass={classes.text}>
						{'No results found. Try searching another term.'}
					</Text>
				)}
				{showError && (
					<Text variant="p" secondary small light customClass={classes.text}>
						{'Something went wrong. Please check your network or try again later.'}
					</Text>
				)}
				{isLoading && (
					<div className={classes.loaderContainer}>
						<DotsLoader />
					</div>
				)}
				{showData && <SearchInbox />}
			</div>
		</MainLayout>
	);
}

export default withAuth(InboxSearchPage);
