import React, { useRef, useState } from 'react';
import classes from './ChatsContent.styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearSelectedChat,
	fetchMoreChatMessages,
	getSelectedChatDetailsFromGlobalState,
	setBookmarkNudgeMessageId,
	setSelectedChatMessageToRetry,
} from '@store/selectedChatSlice/selectedChatSlice';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import Text from '@components/ui/Text';
import { AppDispatch } from '@store/index';
import clsx from 'clsx';
import {
	getAmPmTimeFromUTC,
	getMinutesDiffFromUTC,
	getMonthDayFromUTC,
	isSameDayTime,
	isTodayTime,
} from '@utils/date';
import Button from '@components/ui/Button';
import Modal from '@components/ui/Modal';
import { deleteMessage, getPdfDataToDownload, reactToMessage, reportChatUser } from '@api/chats';
import useFetch from '@hooks/useFetch';
import {
	IDeleteMessagePayload,
	IDownloadPdfPayload,
	IReactToMessagePayload,
	IReportChatUserPayload,
	TAttachmentType,
	TReactionType,
} from '@api/chats/chats.types';
import { CHAT_SEARCH_PARAM, mixPanelEvents } from '@utils/constants';
import {
	getInboxDataFromGlobalState,
	setCurrReportChatsMap,
	setReportChatActionCount,
} from '@store/inboxSlice/inboxSlice';
import Toast from '@components/ui/Toast';
import {
	AlertIcon,
	CheckCircleIcon,
	DeleteIcon,
	DownloadIcon,
	ErrorIcon,
	ImageIcon,
	ImageWhiteIcon,
	MoreOptionsIcon,
	PdfFileIcon,
	PdfFileWhiteIcon,
	ThumpsUpIcon,
	ThumpsUpIconColored,
} from '@src/hoc/withIconStyles';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import Linkify from 'linkify-react';
import mixpanelActions from '@utils/mixpanel';
import { AttachmentMetaData, ChatMessage } from '@src/models/message';
import useWindowSize from '@hooks/useWindow';
import { IDeletedMessagesIds, IReactedMessagesIdsWithReaction } from './ChatsContent.types';
import Loader from '@components/ui/Loader';
import { formatFirstName, isSuperDMApp } from '@utils/common';
import { IRNDownloadEventData } from '@components/RootLayout/RootLayout.types';
import * as Popover from '@radix-ui/react-popover';
import LinkedInProfileOverview from '../LinkedInProfileOverview';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';
import EmailSignifier from './EmailSignifier';
import BookmarkNudge from './BookmarkNudge';
import Avatar from '@components/ui/Avatar';
import { getFullNameSenderProfileFunc } from '@src/models/inbox';
import SlidingText from '@components/Onboarding/SlidingText';

const CAN_DELETE_MESSAGE_WITHIN_MINUTES = 10;

function ChatsContent() {
	const { isMobile } = useWindowSize();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const {
		allChatMessages: chatData,
		fetchMoreChatMessagesStatus,
		selectedChat,
		cursorPagination,
		showBookmarkNudgeMessageId,
		triggerMessagesToScrollBottom,
		currSessionInitiatedChatsIdMap,
	} = useSelector(getSelectedChatDetailsFromGlobalState);
	const { currChatsDataSelectedChatInboxType, currBookmarkChatsMap } = useSelector(
		getInboxDataFromGlobalState
	);
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const [reactedMessageIds, setReactedMessageIds] = useState<IReactedMessagesIdsWithReaction>({});
	const [showUndoReactionModal, setShowUndoReactionModal] = useState<boolean>(false);
	const [chatMessageToUndoReaction, setChatMessageToUndoReaction] = useState<ChatMessage | null>(
		null
	);
	const [deletedMessageIds, setDeletedMessageIds] = useState<IDeletedMessagesIds>({});
	const [chatMessageToDelete, setChatMessageToDelete] = useState<ChatMessage | null>(null);
	const [showReportModal, setShowReportModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [toastState, setToastState] = useState({ error: '', info: '' });
	const [clickedMessageId, setClickedMessageId] = useState('');

	const recentEleRef = React.useRef<HTMLDivElement | null>(null);
	const loadingRef = React.useRef<HTMLDivElement | null>(null);
	const prevLastMessageIdRef = React.useRef<string | null>(null);
	const prevLastMessageEleRef = React.useRef<HTMLDivElement | null>(null);
	const eleContainerRef = React.useRef<HTMLDivElement | null>(null);
	const senderInfoContainerRef = React.useRef<HTMLDivElement | null>(null);
	const toastRef = useRef<IRefProps>(null);

	const dispatch = useDispatch<AppDispatch>();

	const { callApi: reportChat, status: reportUserStatus } = useFetch(reportChatUser);
	const { callApi: callGetPdfDataToDownload, status: callGetPdfDataToDownloadStatus } =
		useFetch(getPdfDataToDownload);
	const { callApi: callReactToMessage, status: callReactToMessageStatus } =
		useFetch(reactToMessage);
	const { callApi: callDeleteMessage, status: callDeleteMessageStatus } = useFetch(deleteMessage);

	const selectedChatUsername = selectedChat?.senderDetails?.username;

	const senderFirstName = selectedChat?.senderDetails?.profileData?.firstName ?? '';
	const selectedChatUserProfileData = selectedChat?.senderDetails?.profileData;
	const introUpdatesText = selectedChat?.senderDetails?.introUpdatesText ?? [];
	const profilePicture = selectedChat?.senderDetails?.profileData?.profilePicture ?? '';

	const selectedChatUserId = selectedChat?.senderDetails?.userId ?? null;
	const currUserId = userDetails?.userId ?? null;
	const selectedChatId = selectedChat?.chatId;
	const chatBookmarked =
		currBookmarkChatsMap[selectedChatId ?? -1] ?? selectedChat?.bookmark ?? false;

	const recentMessageId = React.useMemo(() => {
		return chatData[chatData.length - 1]?.id;
	}, [chatData]);

	const recentMessageEmailSignifierLength = React.useMemo(() => {
		return chatData[chatData.length - 1]?.emailSignifier?.length;
	}, [chatData]);

	React.useEffect(() => {
		const obsEle = loadingRef.current;

		if (!obsEle) return;

		const obsCallback = (entries: IntersectionObserverEntry[]) => {
			const entry = entries[0];
			prevLastMessageIdRef.current = chatData[0]?.id;

			if (entry.isIntersecting && selectedChat?.chatId) {
				dispatch(
					fetchMoreChatMessages({
						chatId: selectedChat.chatId,
						lastMessageId: prevLastMessageIdRef.current,
						username: userDetails?.username ?? '',
						userId: userDetails?.userId ?? -1,
					})
				);
			}
		};

		const observer = new IntersectionObserver(obsCallback, {
			root: eleContainerRef.current,
			threshold: 0.5,
		});

		observer.observe(obsEle);

		return () => {
			if (!obsEle) return;
			observer.unobserve(obsEle);
		};
	}, [chatData, selectedChat]);

	React.useLayoutEffect(() => {
		if (fetchMoreChatMessagesStatus === 'idle') {
			prevLastMessageEleRef.current?.scrollIntoView({ behavior: 'instant' });
		}
	}, [fetchMoreChatMessagesStatus]);

	React.useLayoutEffect(() => {
		recentEleRef.current?.scrollIntoView({ behavior: 'instant' });
	}, [recentMessageId, recentMessageEmailSignifierLength]);

	React.useEffect(() => {
		const chatsDataLength = chatData.length;

		if (!chatsDataLength) return;

		eleContainerRef.current?.scrollTo({
			behavior: 'instant',
			top: eleContainerRef.current?.scrollHeight,
		});
	}, [triggerMessagesToScrollBottom]);

	React.useEffect(() => {
		const containerEle = eleContainerRef.current;
		const senderEle = senderInfoContainerRef.current;

		if (!containerEle || !senderEle) return;

		const containerWidth = containerEle.clientWidth;

		senderEle.style.maxWidth = `${containerWidth - 32}px`;
	}, []);

	const selectedCategory = selectedChat?.label;

	const showSelectedCategory =
		!cursorPagination?.hasNextMessage &&
		chatData.length > 0 &&
		selectedCategory &&
		chatData[0]?.receiverId === userDetails?.userId;

	const showSenderPreferences =
		!cursorPagination?.hasNextMessage &&
		selectedChat?.senderDetails?.preferences &&
		chatData[0]?.receiverId !== userDetails?.userId;

	const displaySenderInfo = !selectedChatId || currSessionInitiatedChatsIdMap[selectedChatId];

	const isLoadingPaginatedMessages = cursorPagination?.hasNextMessage;

	const handleOnClickReportBtn = () => {
		mixpanelActions.trackInboxChat(
			mixPanelEvents.INBOX_REPORT_CLICKED,
			selectedChatUsername ?? '',
			'',
			selectedCategory ?? ''
		);
		setShowReportModal(true);
	};

	const handleReportUser = async () => {
		toastRef.current?.unPublish();

		try {
			const chatId = selectedChatId;
			const senderId = selectedChat?.senderDetails?.userId;
			const userId = userDetails?.userId;

			if (!chatId || !senderId || !userId || reportUserStatus === 'loading') return;

			const payload: IReportChatUserPayload = {
				chat_id: chatId,
				sender_id: senderId,
				user_id: userId,
			};

			await reportChat(payload);
			mixpanelActions.trackInboxChat(
				mixPanelEvents.INBOX_REPORT_SUBMITTED,
				selectedChatUsername ?? '',
				'',
				selectedCategory ?? ''
			);
			setShowReportModal(false);
			setTimeout(() => {
				dispatch(
					setReportChatActionCount({
						chatReported: true,
						chatId: chatId,
					})
				);

				dispatch(
					setCurrReportChatsMap({
						chatReported: true,
						chatId: chatId,
						inboxChatsDataType: currChatsDataSelectedChatInboxType,
					})
				);

				dispatch(clearSelectedChat());

				if (searchParams.has(CHAT_SEARCH_PARAM)) {
					setSearchParams(
						(prevParams) => {
							const params = new URLSearchParams(prevParams);
							params.delete(CHAT_SEARCH_PARAM);
							return params;
						},
						{ replace: true }
					);
				} else {
					// doing this as all chat pages opens inbox path chat page in mobile
					isMobile && navigate(-1);
				}
			}, 1000);
			setToastState({ error: '', info: 'Report sent' });
			toastRef.current?.publish();
		} catch (error) {
			setToastState({ error: error, info: '' });
			toastRef.current?.publish();
		}
	};

	const handleCloseReportModal = () => {
		if (reportUserStatus === 'loading') return;
		setShowReportModal(false);
	};

	const handlePdfDownload = async (
		messageId: string,
		userId: number,
		attachmentMetaData: AttachmentMetaData,
		attachmentType?: TAttachmentType
	) => {
		if (callGetPdfDataToDownloadStatus === 'loading') return;

		const payload: IDownloadPdfPayload = {
			message_id: messageId,
			user_id: userId,
			attachment_metadata: {
				content_type: attachmentMetaData.contentType,
				name: attachmentMetaData.name,
				id: attachmentMetaData.id,
			},
		};
		try {
			const res = await callGetPdfDataToDownload(payload);

			const isSuperDMMobileApp = isSuperDMApp();

			const downloadFileType =
				attachmentType === 'JPEG'
					? 'image/jpeg'
					: attachmentType === 'PNG'
					? 'image/png'
					: 'application/pdf';

			const blob = new Blob([res], { type: downloadFileType });

			// convert to base64 and trigger download in superDM app
			if (isSuperDMMobileApp) {
				const reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = function () {
					const base64data = reader.result;
					if (window.ReactNativeWebView) {
						const eventData: IRNDownloadEventData = {
							eventType: 'DOWNLOAD_FILE',
							eventData: {
								blobData: base64data,
								fileName: attachmentMetaData.name,
								fileType: attachmentType,
							},
						};
						window.ReactNativeWebView.postMessage(JSON.stringify(eventData));
					}
				};
				return;
			}

			const fileURL = window.URL.createObjectURL(blob);

			const linkEle = document.createElement('a');
			linkEle.href = fileURL;
			linkEle.download = `${attachmentMetaData.name}`;
			linkEle.click();
			document.body.removeChild(linkEle);
		} catch (error) {
			// handle error
		}
	};

	const handleReactToMessageClick = async (reactionType: TReactionType, message: ChatMessage) => {
		if (!message.chatId || !userDetails?.userId) return;
		if (callReactToMessageStatus === 'loading') return;

		const payload: IReactToMessagePayload = {
			chat_id: message.chatId,
			message_id: message.id,
			reaction: reactionType,
			user_id: userDetails?.userId,
		};

		const messageId = message.id;
		const messageReaction = reactionType;

		try {
			await callReactToMessage(payload);

			setReactedMessageIds((prevState) => {
				return {
					...prevState,
					[messageId]: messageReaction ?? null,
				};
			});
		} catch (error) {
			// handle react to message error
		}
	};

	const handleCloseUndoReactionModal = () => {
		if (callReactToMessageStatus === 'loading') return;
		setShowUndoReactionModal(false);
	};

	const handleDeleteMessage = async () => {
		toastRef.current?.unPublish();
		const chatId = chatMessageToDelete?.chatId;
		const messageId = chatMessageToDelete?.id;
		const userId = userDetails?.userId;

		if (!chatId || !userId || !messageId) return;

		if (callDeleteMessageStatus === 'loading') return;

		const messageTimeStamp = chatMessageToDelete.createdAt;
		const minutesDiff = getMinutesDiffFromUTC(messageTimeStamp);

		const canDeleteMessage =
			typeof minutesDiff === 'number' && minutesDiff < CAN_DELETE_MESSAGE_WITHIN_MINUTES;

		if (!canDeleteMessage) {
			setToastState({ error: 'The message could not be deleted', info: '' });
			toastRef.current?.publish();
			return;
		}

		try {
			const payload: IDeleteMessagePayload = {
				chat_id: chatId,
				message_id: messageId,
				user_id: userId,
			};
			await callDeleteMessage(payload);
			mixpanelActions.trackDeleteChatMessage(
				mixPanelEvents.CHAT_DELETE_SUCCESS,
				selectedChatUsername ?? ''
			);
			setShowDeleteModal(false);
			setDeletedMessageIds((prevState) => {
				return {
					...prevState,
					[messageId]: chatMessageToDelete,
				};
			});
			setToastState({ error: '', info: 'Message deleted' });
			toastRef.current?.publish();
		} catch (error) {
			setToastState({ error: error, info: '' });
			toastRef.current?.publish();
		}
	};

	const handleCloseDeleteModal = () => {
		if (callDeleteMessageStatus === 'loading') return;
		setShowDeleteModal(false);
	};

	const handleDeleteMessageClick = (chat: ChatMessage) => {
		toastRef.current?.unPublish();
		mixpanelActions.trackDeleteChatMessage(
			mixPanelEvents.CHAT_DELETE_CLICKED,
			selectedChatUsername ?? ''
		);

		const messageTimeStamp = chat.createdAt;
		const minutesDiff = getMinutesDiffFromUTC(messageTimeStamp);

		const canDeleteMessage =
			typeof minutesDiff === 'number' && minutesDiff < CAN_DELETE_MESSAGE_WITHIN_MINUTES;

		if (!canDeleteMessage) {
			setToastState({ error: 'This message can no longer be deleted', info: '' });
			toastRef.current?.publish();
			return;
		}
		setChatMessageToDelete(chat);
		setShowDeleteModal(true);
	};

	return (
		<section className={classes.chatsContent}>
			<div className={clsx(classes.container)} ref={eleContainerRef}>
				{showSenderPreferences && (
					<div className={classes.preferenceContainer}>
						{displaySenderInfo && (
							<div className={classes.senderInfo} ref={senderInfoContainerRef}>
								<Avatar
									size={isMobile ? 8 : 6.4}
									profilePicURL={profilePicture}
									fallbackIcon={FallbackPicIcon}
								/>

								<Text variant="h2" semiBold medium={isMobile} lineHeight={isMobile ? 2.4 : 2}>
									{selectedChatUserProfileData
										? getFullNameSenderProfileFunc(selectedChatUserProfileData)
										: ''}
								</Text>

								{!!introUpdatesText.length && (
									<div className={classes.textWithBlinkContainer}>
										<SlidingText shouldSlide={true}>
											{introUpdatesText.map((text, index) => (
												<Text variant="p" key={index} customClass={classes.updatesText}>
													{text}
												</Text>
											))}
										</SlidingText>
									</div>
								)}
							</div>
						)}
						<Text variant="p" small light customClass={classes.senderPreferenceCard}>
							<Linkify options={{ target: '_blank' }}>
								{selectedChat?.senderDetails?.preferences ?? ''}
							</Linkify>
						</Text>
						{selectedCategory && (
							<Text variant="span" tiny customClass={classes.categoryLabel}>
								{selectedCategory}
							</Text>
						)}
					</div>
				)}

				{showSelectedCategory && (
					<div className={clsx(isMobile && classes.selectedCategoryWithLinkedIn)}>
						{isMobile && <LinkedInProfileOverview />}
						<div className={classes.selectedCategoryContainer}>
							<Text variant="span" tiny customClass={classes.categoryLabel}>
								{selectedCategory}
							</Text>
							<div className={classes.reportContainer}>
								<Text variant="span" secondary tiny light>
									{'Not the right category?'}
								</Text>
								<Button
									btnText={
										<Text variant="span" tiny secondary>
											{'Report'}
										</Text>
									}
									customClass={classes.reportBtn}
									onClick={handleOnClickReportBtn}
								/>
							</div>
						</div>
					</div>
				)}
				<div aria-hidden ref={loadingRef} className={classes.loadingContainer}>
					{isLoadingPaginatedMessages && (
						<Text variant="p" ultraLight small secondary>
							{'Loading DMs...'}
						</Text>
					)}
				</div>
				{chatData.map((chat, index) => {
					const isMessageDeleted = deletedMessageIds[chat.id];

					if (isMessageDeleted) {
						return <div key={chat.id} />;
					}

					const isSameUserSent = userDetails?.userId === chat.senderId;
					const isNextMsgDiffUserSent = chat.receiverId !== chatData[index + 1]?.receiverId;

					const monthDayFormat = getMonthDayFromUTC(chat.createdAt);
					const timeAmPmFormat = getAmPmTimeFromUTC(chat.createdAt);
					const isTodayMessage = isTodayTime(chat.createdAt);

					const timeDisplayText = isTodayMessage
						? `${timeAmPmFormat ?? ''}`
						: `${monthDayFormat ?? ''}, ${timeAmPmFormat}`;

					const isRecentMessage = index === chatData.length - 1;
					const isNextMessageOfSameTime = isSameDayTime(
						chat.createdAt,
						chatData[index + 1]?.createdAt
					);

					const statusText =
						chat.status === 'FAILED'
							? 'Failed to send. Tap to try again.'
							: chat.status === 'SENDING'
							? 'Sending...'
							: chat.status === 'SENT'
							? 'Sent'
							: '';

					const isChatFailed = chat.status === 'FAILED';
					const isChatSending = chat.status === 'SENDING';
					const isChatSent = chat.status === 'SENT';
					const isChatDelivered = chat.status === 'DELIVERED';

					const displayTimeText = !isNextMessageOfSameTime && (isChatSent || isChatDelivered);

					const attachmentMetaData = chat.attachmentMetaData;
					const isPdfAttachment = chat.attachmentType === 'PDF';
					const isImageAttachment = chat.attachmentType === 'JPEG' || chat.attachmentType === 'PNG';

					const showActionsInMobile = clickedMessageId === chat.id;

					const reaction = reactedMessageIds[chat.id] || chat.reaction;
					const showReactionBtn =
						(isMobile ? showActionsInMobile : true) &&
						!isSameUserSent &&
						(!reaction || reaction === 'NONE');
					const showDeleteOption =
						(isMobile ? showActionsInMobile : true) &&
						isSameUserSent &&
						index > 0 &&
						(isChatSent || isChatDelivered);
					const showReactedIcon = reaction === 'LIKE';

					const emailSignifier = chat.emailSignifier;

					const displayBookmarkNudge = showBookmarkNudgeMessageId === chat.id;

					return (
						<div
							key={chat.id}
							className={classes.chatMainContainer}
							onClick={() => {
								setClickedMessageId(clickedMessageId === chat.id ? '' : chat.id);
							}}
						>
							<div
								ref={(el) => {
									if (isRecentMessage) {
										recentEleRef.current = el;
									}
									if (prevLastMessageIdRef.current === chat.id) {
										prevLastMessageEleRef.current = el;
									}
								}}
								className={clsx(
									classes.chatContainer,
									isSameUserSent && classes.chatContainerColored,
									isNextMsgDiffUserSent && classes.chatContainerMarginBottom
								)}
							>
								<div
									className={clsx(
										classes.textWithReaction,
										showReactedIcon && classes.textContentMargin
									)}
								>
									<Text
										variant="p"
										small
										light
										white={isSameUserSent}
										customClass={clsx(classes.content, isSameUserSent && classes.contentColored)}
									>
										<Linkify options={{ target: '_blank' }}>{chat.content}</Linkify>
									</Text>
									{showReactionBtn && (
										<Button
											btnText={<ThumpsUpIcon size={1.6} />}
											customClass={classes.reactionBtn}
											onClick={() => {
												handleReactToMessageClick('LIKE', chat);
											}}
										/>
									)}
									{showReactedIcon && (
										<Button
											btnText={<ThumpsUpIconColored size={1.8} />}
											customClass={clsx(
												classes.selectedReactionBtn,
												isSameUserSent && classes.selectReactionSameUser
											)}
											onClick={() => {
												if (isSameUserSent) return;
												setShowUndoReactionModal(true);
												setChatMessageToUndoReaction(chat);
											}}
										/>
									)}
									{showDeleteOption && (
										<Popover.Root>
											<Popover.Trigger asChild>
												<button
													className={classes.moreActions}
													onClick={(e) => {
														e.stopPropagation();
													}}
												>
													<MoreOptionsIcon size={1.6} />
												</button>
											</Popover.Trigger>
											<Popover.Portal>
												<Popover.Content
													align="end"
													side="bottom"
													className={classes.moreActionsContent}
												>
													<Button
														btnText={
															<Text variant="span" small>
																{'Delete'}
															</Text>
														}
														onClick={() => handleDeleteMessageClick(chat)}
														prefixIcon={<DeleteIcon size={1.6} />}
													/>
												</Popover.Content>
											</Popover.Portal>
										</Popover.Root>
									)}
								</div>
								{attachmentMetaData && (
									<div
										className={clsx(
											classes.pdfAttachmentContainer,
											isSameUserSent && classes.contentColored
										)}
										onClick={() => {
											if (chat.id && userDetails?.userId && attachmentMetaData) {
												handlePdfDownload(
													chat.id,
													userDetails.userId,
													attachmentMetaData,
													chat.attachmentType
												);
											}
										}}
										role="button"
									>
										{isSameUserSent && isPdfAttachment && <PdfFileWhiteIcon size={1.6} />}
										{!isSameUserSent && isPdfAttachment && <PdfFileIcon size={1.6} />}
										{isSameUserSent && isImageAttachment && <ImageWhiteIcon size={1.6} />}
										{!isSameUserSent && isImageAttachment && <ImageIcon size={1.6} />}
										<Text
											variant="p"
											white={isSameUserSent}
											small
											light
											customClass={classes.pdfFileName}
										>
											{attachmentMetaData.name}
										</Text>
										<DownloadIcon
											size={1.6}
											className={clsx(!isSameUserSent && classes.downloadIconBlack)}
										/>
									</div>
								)}
								{isChatFailed && (
									<Button
										btnText={
											<Text variant="p" error extraTiny>
												{statusText}
											</Text>
										}
										prefixIcon={<ErrorIcon size={1.4} />}
										onClick={() => {
											dispatch(setSelectedChatMessageToRetry({ selectedChatMessageToRetry: chat }));
										}}
										customClass={classes.failedTextBtn}
									/>
								)}
								{isChatSending && (
									<Text
										variant="p"
										extraTiny
										tertiary
										light
										customClass={classes.timeIconContainer}
									>
										{statusText}
									</Text>
								)}
								{displayTimeText && (
									<Text
										variant="p"
										extraTiny
										tertiaryV0
										light
										customClass={classes.timeIconContainer}
									>
										{timeDisplayText}
										{isChatSent && (
											<Text variant="span" extraTiny tertiary light>
												{statusText}
											</Text>
										)}
									</Text>
								)}
							</div>
							<EmailSignifier
								emailSignifierList={emailSignifier}
								userId={userDetails?.userId}
								senderFirstName={formatFirstName(senderFirstName)}
								customClassName={
									!isRecentMessage || displayBookmarkNudge
										? classes.emailSignifierMarginBottom
										: classes.emailSignifierMarginBottomRecent
								}
							/>

							{displayBookmarkNudge && (
								<BookmarkNudge
									onCancel={() => {
										dispatch(setBookmarkNudgeMessageId({ messageId: null }));
									}}
									chatBookmarked={chatBookmarked}
									currUserId={currUserId}
									selectedChatId={selectedChatId}
									selectedChatUserId={selectedChatUserId}
									senderUsername={selectedChatUsername}
									customClassName={classes.bookmarkNudge}
								/>
							)}
						</div>
					);
				})}
			</div>

			{showReportModal && (
				<Modal
					header={'Report incorrect category'}
					onCloseModal={handleCloseReportModal}
					showModal={showReportModal}
					bottomInMobile
				>
					<div className={classes.reportModalContentContainer}>
						<Text variant="p" light small>
							{
								'The message will be forwarded to our safety team, who will block the user from our platform post review.'
							}
						</Text>
						<Text variant="p" light small>
							{'Meanwhile, this chat will be removed from your inbox.'}
						</Text>
						<div className={classes.buttonsContainer}>
							<Button
								btnText={
									<Text variant="span" small semiBold>
										{'Cancel'}
									</Text>
								}
								onClick={handleCloseReportModal}
								customClass={classes.modalCancelBtn}
								disabled={reportUserStatus === 'loading'}
							/>
							<Button
								btnText={
									<Text variant="span" white small semiBold>
										{'Report'}
									</Text>
								}
								onClick={handleReportUser}
								customClass={classes.modalReportBtn}
								customLoader={<Loader allWhite size={1.8} />}
								isLoading={reportUserStatus === 'loading'}
							/>
						</div>
					</div>
				</Modal>
			)}

			{showDeleteModal && (
				<Modal
					header={'Delete for everyone?'}
					onCloseModal={handleCloseDeleteModal}
					showModal={showDeleteModal}
					bottomInMobile
				>
					<div className={classes.deleteModalContentContainer}>
						<Text variant="p" light small>
							{`The message will be deleted permanently.`}
						</Text>

						<div className={classes.buttonsContainer}>
							<Button
								btnText={
									<Text variant="span" small semiBold>
										{'Cancel'}
									</Text>
								}
								onClick={handleCloseDeleteModal}
								customClass={classes.modalCancelBtn}
								disabled={callDeleteMessageStatus === 'loading'}
							/>
							<Button
								btnText={
									<Text variant="span" white small semiBold>
										{'Yes'}
									</Text>
								}
								onClick={handleDeleteMessage}
								customClass={classes.modalDeleteBtn}
								disabled={callDeleteMessageStatus === 'loading'}
							/>
						</div>
					</div>
				</Modal>
			)}

			{showUndoReactionModal && (
				<Modal
					header={'Undo Like?'}
					onCloseModal={handleCloseUndoReactionModal}
					showModal={showUndoReactionModal}
					bottomInMobile
				>
					<div className={classes.undoReactionAlertModalContentContainer}>
						<Text variant="p" light small secondary>
							{'Please confirm if you want to undo. '}
						</Text>

						<div className={classes.buttonsContainer}>
							<Button
								btnText={
									<Text variant="span" small semiBold>
										{'Cancel'}
									</Text>
								}
								onClick={handleCloseUndoReactionModal}
								customClass={classes.modalCancelBtn}
								disabled={callReactToMessageStatus === 'loading'}
							/>
							<Button
								btnText={
									<Text variant="span" white small semiBold>
										{'Yes, undo'}
									</Text>
								}
								onClick={() => {
									if (chatMessageToUndoReaction) {
										handleReactToMessageClick('NONE', chatMessageToUndoReaction);
									}
									setShowUndoReactionModal(false);
								}}
								customLoader={<Loader allWhite />}
								primary
								isLoading={callReactToMessageStatus === 'loading'}
								customClass={classes.modalUndoBtn}
							/>
						</div>
					</div>
				</Modal>
			)}

			<Toast
				ref={toastRef}
				toastType={toastState.info ? 'INFO' : 'ERROR'}
				header={toastState.info ? toastState.info : toastState.error}
				icon={
					toastState.info ? (
						<CheckCircleIcon size={1.8} />
					) : (
						<AlertIcon size={1.8} className={classes.toastIcon} />
					)
				}
			/>
		</section>
	);
}

export default React.memo(ChatsContent);
