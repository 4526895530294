import { IUserLocationData, TUserLinkedInVerificationState, TUserType } from '@api/auth/auth.types';
import {
	IDate,
	IFetchInboxDataAPIResponse,
	IFilterOption,
	IFilters,
	IGetTabOverviewApiResponse,
	ILastMessage,
	IPageDetails,
	ISenderChatData,
	ISenderChatTag,
	ISenderDetails,
	ISenderEducation,
	ISenderExperience,
	ISenderProfileData,
	TSenderChatIconType,
	TSenderChatTagType,
} from '@api/chats/chats.types';
import { filtersHeadingMappings } from '@utils/constants';
import {
	InboxCategories,
	Overview,
	getStructuredInboxCategories,
	getStructuredOverview,
} from './user';
import { getMonthYearText, getTotalYearsMonthText } from '@utils/date';
import {
	ChatMessage,
	getStructuredAttachmentMetaData,
	getStructuredEmailSignifier,
} from './message';

export interface PageDetails {
	pageNumber: number;
	pageSize: number;
	sortBy: null | string;
	sortOrder: null | string;
	totalPages: number;
	hasMoreChats?: boolean;
	lastElementIndex?: number;
}

export function getStructuredPageDetails(obj: IPageDetails) {
	if (!obj) return null;

	const structuredObj: PageDetails = {
		pageNumber: obj.page_number,
		pageSize: obj.page_size,
		sortBy: obj.sort_by,
		sortOrder: obj.sort_order,
		totalPages: obj.total_pages,
		hasMoreChats: obj.has_next,
		lastElementIndex: obj.last_element_position,
	};

	return structuredObj;
}

export interface FilterOption {
	value: string;
	count: number;
	valueMap?: number;
	valueDisplay?: string;
	isActiveOption?: boolean;
}

export function getStructuredFilterOption(obj: IFilterOption) {
	const structuredObj: FilterOption = {
		value: obj.value,
		count: obj.count,
		valueMap: obj.valueMap,
	};

	return structuredObj;
}

export type TFilterType = 'single' | 'multi' | 'singleClick';

export interface INewFilterOptionMap {
	[key: string]: FilterOption;
}

export interface INewFilters {
	options: INewFilterOptionMap | null;
	filterType: TFilterType;
	filterName: string;
	filterHeader: string;
}

export interface LastMessage extends ChatMessage {}

export function getStructuredLastMessage(obj: ILastMessage) {
	const structuredObj: LastMessage = {
		content: obj.content,
		contentType: obj.content_type,
		createdAt: obj.created_at,
		receiverId: obj.receiver_id,
		senderId: obj.sender_id,
		status: obj.status,
		id: obj.id,
		attachmentType: obj.attachment_type,
		attachmentMetaData: getStructuredAttachmentMetaData(obj.attachment_metadata),
		reaction: obj.reaction,
		isCannedResponse: false,
		canBookmarkChat: false,
		emailSignifier:
			obj.email_signifier?.map((emailSignifier) => getStructuredEmailSignifier(emailSignifier)) ??
			[],
	};

	return structuredObj;
}

export interface SenderEducation {
	startDate?: IDate;
	endDate?: IDate;
	schoolName: string;
	degreeName: string;
	fieldOfStudy: string;
	logo: string;
}

export function getStructuredSenderEducation(obj: ISenderEducation) {
	const structuredObj: SenderEducation = {
		startDate: obj.start_date,
		endDate: obj.end_date,
		schoolName: obj.school_name,
		degreeName: obj.degree_name,
		fieldOfStudy: obj.field_of_study,
		logo: obj.logo,
	};

	return structuredObj;
}

export function getTimePeriodTextEdFunc(obj: SenderEducation) {
	return getMonthYearText(null, obj.startDate?.year, null, obj.endDate?.year, false);
}

export interface SenderExperience {
	company: string;
	title: string;
	location: string;
	startDate?: IDate;
	endDate?: IDate;
	companyImageURL: string;
	employmentType: string;
	description: string;
	months: number;
}

export function getStructuredSenderExperience(obj: ISenderExperience) {
	const structuredObj: SenderExperience = {
		company: obj.company,
		companyImageURL: obj.company_image_url,
		employmentType: obj.employment_type,
		endDate: obj.end_date,
		location: obj.location,
		startDate: obj.start_date,
		title: obj.title,
		description: obj.description,
		months: obj.total_months,
	};

	return structuredObj;
}

export function getTotalExpYearsMonthTextFunc(obj: SenderExperience) {
	return getTotalYearsMonthText(obj.months);
}

export function getTimePeriodTextExpFunc(obj: SenderExperience) {
	return getMonthYearText(
		obj.startDate?.month,
		obj.startDate?.year,
		obj.endDate?.month,
		obj.endDate?.year,
		true
	);
}

export interface IExperienceMap {
	[key: string]: {
		allExperiences: SenderExperience[];
		showTimePeriod: boolean;
	};
}

function getSenderExperienceMap(experienceArr: SenderExperience[]) {
	const experienceMap: IExperienceMap = {};

	experienceArr.map((experience) => {
		const key = experience.company;
		if (experienceMap[key]) {
			const obj = experienceMap[key];
			experienceMap[key] = {
				...obj,
				allExperiences: [...obj.allExperiences, experience],
				showTimePeriod: obj.showTimePeriod ? !!experience.months : false,
			};
		} else {
			experienceMap[key] = {
				allExperiences: [experience],
				showTimePeriod: !!experience.months,
			};
		}
	});

	return experienceMap;
}

export interface SenderLocation {
	city: string;
	country: string;
	state: string;
	default: string;
}

export function getStructuredSenderLocation(obj: IUserLocationData) {
	if (!obj) return;

	const structuredObj: SenderLocation = {
		city: obj.city,
		country: obj.country,
		default: obj.default,
		state: obj.state,
	};

	return structuredObj;
}

export interface SenderProfile {
	linkedInURL: string;
	firstName: string;
	lastName: string;
	profilePicture?: string;
	headline: string;
	summary: string;
	experience: IExperienceMap;
	experienceCompanyOrder: string[];
	education: SenderEducation[];
	location?: SenderLocation;
	linkedInVerificationStatus?: TUserLinkedInVerificationState;
	isCompanyProfile: boolean;
	lastSyncTimestamp?: string; 
}

export function getExperienceCompanyOrder(experienceArr: ISenderExperience[]) {
	const experienceCompanyOrderSet = new Set<string>();

	experienceArr.map((experience) => {
		experienceCompanyOrderSet.add(experience.company);
	});

	return Array.from(experienceCompanyOrderSet);
}

export function getStructuredSenderProfile(
	obj?: ISenderProfileData,
	linkedInVerificationStatus?: TUserLinkedInVerificationState,
	lastSyncTimestamp?: string // Added lastSyncTimestamp parameter
) {
	if (!obj) return null;

	const structuredObj: SenderProfile = {
		firstName: obj.first_name,
		headline: obj.headline,
		lastName: obj.last_name,
		linkedInURL: obj.linkedin_url,
		profilePicture: obj.profile_picture,
		summary: obj.summary,
		education: obj.education?.map((education) => getStructuredSenderEducation(education)) ?? [],
		experience: getSenderExperienceMap(
			obj.experience?.map((experience) => getStructuredSenderExperience(experience)) ?? []
		),
		experienceCompanyOrder: getExperienceCompanyOrder(obj.experience ?? []),
		location: getStructuredSenderLocation(obj.location),
		linkedInVerificationStatus: linkedInVerificationStatus,
		isCompanyProfile: obj.is_company_profile ?? false,
		lastSyncTimestamp: lastSyncTimestamp, 
	};

	return structuredObj;
}

export function isLinkedInVerificationPendingSenderProfileFunc(obj: SenderProfile) {
	return (
		obj.linkedInVerificationStatus === 'PENDING' ||
		obj.linkedInVerificationStatus === 'PENDING_MESSAGE_SENT'
	);
}

export function getFullNameSenderProfileFunc(obj: SenderProfile) {
	return `${obj.firstName ?? ''} ${obj.lastName ?? ''}`;
}

export function getInboxCategoriesObjectMapping(arr: InboxCategories[]) {
	const obj: { [key: string]: InboxCategories } = {};

	arr.forEach((eachObj) => {
		const key = eachObj.name;
		obj[key] = eachObj;
	});

	return obj;
}

export interface SenderDetails {
	userId: number;
	userType: TUserType;
	preferences?: string;
	profileData: SenderProfile | null;
	username: string;
	introUpdatesText: string[];
	inboxCategories: InboxCategories[];
	InboxCategoriesMap: {
		[key: string]: InboxCategories;
	};
	linkedInVerificationStatus: TUserLinkedInVerificationState;
	email: string;
	maxCharCountEnabled?: boolean;
	minCharCountEnabled?: boolean;
	isCompanyProfile: boolean;
}

export function getStructuredSenderDetails(obj: ISenderDetails) {
	if (!obj) return null;

	const structuredObj: SenderDetails = {
		userId: obj.user_id,
		userType: obj.user_type,
		preferences: obj.preferences,
		introUpdatesText: obj.intro_fomo_text ?? [],
		profileData: getStructuredSenderProfile(obj.profile_data),
		username: obj.username,
		inboxCategories:
			obj.inbox_categories?.map((inboxCategory) => getStructuredInboxCategories(inboxCategory)) ??
			[],
		InboxCategoriesMap: getInboxCategoriesObjectMapping(
			obj.inbox_categories?.map((inboxCategory) => getStructuredInboxCategories(inboxCategory)) ??
				[]
		),
		linkedInVerificationStatus: obj.linkedin_verification_status,
		email: obj.email,
		maxCharCountEnabled: obj.max_char_count_enabled,
		minCharCountEnabled: obj.min_char_count_enabled,
		isCompanyProfile: obj.is_company_profile ?? false,
	};

	return structuredObj;
}

export function isLinkedInVerificationPendingChatSenderFunc(obj: SenderDetails) {
	return (
		obj.linkedInVerificationStatus === 'PENDING' ||
		obj.linkedInVerificationStatus === 'PENDING_MESSAGE_SENT'
	);
}

export interface SenderChatTag {
	type: TSenderChatTagType;
	imageURL: string | null;
	icon: TSenderChatIconType;
	text: string;
}

export function getStructuredSenderChatTag(obj: ISenderChatTag) {
	const structuredObj: SenderChatTag = {
		icon: obj.icon,
		imageURL: obj.image_url,
		text: obj.text,
		type: obj.type,
	};

	return structuredObj;
}

export interface SenderChatData {
	chatId: number | null;
	senderDetails: SenderDetails | null;
	lastFiveMessages: LastMessage[];
	lastMessage: LastMessage | null;
	read: boolean;
	label: string;
	bookmark: boolean;
	replied: boolean;
	tags: SenderChatTag[];
	receiverReplied?: boolean;
	topPick: boolean;
	personalLabels: string[];
}

export function getStructuredSenderChatData(obj: ISenderChatData) {
	const structuredObj: SenderChatData = {
		chatId: obj.chat_id ?? null,
		label: obj.label,
		lastFiveMessages:
			obj.last_5_messages?.map((message) => getStructuredLastMessage(message)) ?? [],
		lastMessage: obj.last_message ? getStructuredLastMessage(obj.last_message) : null,
		read: obj.read ?? true,
		senderDetails: getStructuredSenderDetails(obj.sender_details),
		bookmark: obj.bookmark,
		replied: obj.replied ?? false,
		tags: obj.tags?.map((tag) => getStructuredSenderChatTag(tag)) ?? [],
		receiverReplied: obj.received_reply,
		topPick: obj.top_pick ?? false,
		personalLabels: obj.personal_tags ?? [],
	};

	return structuredObj;
}

function getFiltersArrayMap(obj: IFilters) {
	if (!obj) return [];
	const newFiltersArr: INewFilters[] = [];

	Object.keys(obj).forEach((filterName) => {
		const filterOptions = obj[filterName];
		const newFiltersObj: INewFilterOptionMap = {};

		filterOptions.map((option) => {
			newFiltersObj[option.value] = {
				...option,
			};
		});

		newFiltersArr.push({
			filterName: filterName,
			filterType: 'multi',
			options: newFiltersObj,
			filterHeader: filtersHeadingMappings[filterName] ?? filterName,
		});
	});
	return newFiltersArr;
}

export interface InboxData {
	pageDetails: PageDetails | null;
	filters: INewFilters[];
	chats: SenderChatData[];
	searchQuery: { [key: string]: unknown } | null;
	totalCount?: number;
	archivedCount?: number;
}

export function getStructuredInboxData(obj?: IFetchInboxDataAPIResponse) {
	if (!obj) return null;

	const structuredObj: InboxData = {
		pageDetails: getStructuredPageDetails(obj.page_details),
		filters: getFiltersArrayMap(obj.filter_labels),
		chats: obj.chats?.map((chat) => getStructuredSenderChatData(chat)) ?? [],
		searchQuery: obj.search_query ?? null,
		totalCount: obj.total_count,
		archivedCount: obj.archived_count,
	};

	return structuredObj;
}

export interface TabOverview {
	overview: Overview | null;
}

export function getStructuredTabOverview(obj: IGetTabOverviewApiResponse) {
	if (!obj) return null;

	const structuredObj: TabOverview = {
		overview: getStructuredOverview(obj.overview),
	};

	return structuredObj;
}