import React, { useEffect, useState } from 'react';
import classes from './GoogleCallback.styles.module.scss';
import mixpanelActions from '@utils/mixpanel';
import {
	GOOGLE_OAUTH_ERROR_PARAM,
	GOOGLE_OAUTH_STATE_PARAM,
	GOOGLE_OAUTH_STATE_PARAM_VALUE,
	GOOGLE_OAUTH_STATE_PARAM_VALUE_OLD,
	GOOGLE_OAUTH_TOKEN_PARAM,
	MAIN_PATH,
	mixPanelEvents,
	oauthRedirectPath,
} from '@utils/constants';
import DotsLoader from '@components/ui/DotsLoader';
import { useNavigate } from 'react-router-dom';
import Text from '@components/ui/Text';
import { getSessionStorage, removeSessionStorage } from '@utils/sessionStorage';
import { ErrorIconV2 } from '@src/hoc/withIconStyles';

function GoogleCallback() {
	const navigate = useNavigate();

	const [countdown, setCountdown] = useState<number>(2);

	const [googleAuthState, setGoogleAuthState] = useState({
		success: '',
		error: '',
	});
	const [redirectURL, setRedirectURL] = useState<string | null>(null);

	useEffect(() => {
		mixpanelActions.trackGoogleOAuth(mixPanelEvents.GOOGLE_OAUTH_LOADING_SCREEN_SHOWN);

		const urlHashData = window.location.href.split('#')[1];
		const searchParams = new URLSearchParams(urlHashData);

		const state = searchParams.get(GOOGLE_OAUTH_STATE_PARAM);
		const error = searchParams.get(GOOGLE_OAUTH_ERROR_PARAM);
		const token = searchParams.get(GOOGLE_OAUTH_TOKEN_PARAM);

		const urlPathFromSessionStorage = getSessionStorage(oauthRedirectPath) ?? '';

		const redirectURLToSet = urlPathFromSessionStorage ? urlPathFromSessionStorage : MAIN_PATH;

		// handling error case
		if (
			(state !== GOOGLE_OAUTH_STATE_PARAM_VALUE && state !== GOOGLE_OAUTH_STATE_PARAM_VALUE_OLD) ||
			!!error
		) {
			mixpanelActions.trackGoogleOAuth(
				mixPanelEvents.GOOGLE_OAUTH_ERROR_SHOWN,
				error ?? 'state mismatch'
			);
			setGoogleAuthState({ success: '', error: 'Something went wrong' });
			setRedirectURL(redirectURLToSet);
			removeSessionStorage(oauthRedirectPath);
			return;
		}

		const searchParamsToSet = new URLSearchParams(redirectURLToSet.split('?')[1]);
		searchParamsToSet.set(GOOGLE_OAUTH_TOKEN_PARAM, encodeURIComponent(token ?? ''));
		removeSessionStorage(oauthRedirectPath);

		// handling success case
		navigate(`${redirectURLToSet.split('?')[0]}?${searchParamsToSet.toString()}`, {
			replace: true,
		});
	}, []);

	useEffect(() => {
		if (!redirectURL) return;

		if (countdown === 0) {
			navigate(redirectURL, { replace: true });
			return;
		}

		const timer = setInterval(() => {
			setCountdown((prev) => {
				return prev - 1;
			});
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [redirectURL, countdown]);

	return (
		<main className={classes.callbackContainer}>
			{!googleAuthState.success && !googleAuthState.error && <DotsLoader />}
			{googleAuthState.error && (
				<div className={classes.feedbackContainer}>
					<div className={classes.iconContainer}>
						<ErrorIconV2 size={9.6} />
					</div>

					<Text variant="h1" medium>
						{googleAuthState.error}
					</Text>

					<Text variant="p" small light secondary>
						Redirecting in {countdown} seconds...
					</Text>
				</div>
			)}
		</main>
	);
}

export default GoogleCallback;
