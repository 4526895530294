import React, { useEffect, useRef, useState } from 'react';
import withAuth from '@src/hoc/withAuth';
import classes from './Profile.styles.module.scss';
import ScrollableLayout from '@components/ScrollableLayout';
import useFetch from '@hooks/useFetch';
import { getUserProfileData, syncProfile } from '@api/user';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import Text from '@components/ui/Text';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';
import ExperienceFallbackIcon from '@public/icons/experienceFallbackIcon.svg';
import EducationFallbackIcon from '@public/icons/educationFallbackIcon.svg';
import Avatar from '@components/ui/Avatar';
import clsx from 'clsx';
import Button from '@components/ui/Button';
import { CloseIcon, OpenNewTabIcon, ResyncIcon, UnverifiedIcon } from '@src/hoc/withIconStyles';
import useWindowSize from '@hooks/useWindow';
import {
	getMonthsDifference,
	getTotalYearsMonthText,
	parseSyncTimestamp,
	getMonthsSinceLastSync,
} from '@utils/date';
import mixpanelActions from '@utils/mixpanel';
import { PAGE_NOT_FOUND_PATH, mixPanelEvents } from '@utils/constants';
import Tooltip from '@components/ui/Tooltip';
import {
	getFullNameSenderProfileFunc,
	getTimePeriodTextEdFunc,
	getTimePeriodTextExpFunc,
	getTotalExpYearsMonthTextFunc,
	isLinkedInVerificationPendingSenderProfileFunc,
} from '@src/models/inbox';
import DotsLoader from '@components/ui/DotsLoader';
import Modal from '@components/ui/Modal';
import Toast from '@components/ui/Toast';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import { AlertIcon, CheckCircleIcon } from '@src/hoc/withIconStyles';
import useVerifyLinkedInContext from '@hooks/useVerifyLinkedInContext';
import Loader from '@components/ui/Loader';

function Profile() {
	const { isMobile } = useWindowSize();
	const { selectedProfileUsername } = useParams();
	const navigate = useNavigate();

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const userId = userDetails?.userId;

	const {
		callApi: fetchUserProfileData,
		status: fetchUserProfileDataStatus,
		response: userProfileData,
	} = useFetch(getUserProfileData);
	const {
		callApi: callSyncProfile,
		status: callSyncProfileStatus,
		errorStatus: callSyncProfileErrorStatus,
		error: callSyncProfileError,
	} = useFetch(syncProfile);

	const [showLimitReachedModal, setShowLimitReachedModal] = useState(false);
	const [showResyncModal, setShowResyncModal] = useState(false);
	const [showLinkedInNotFoundModal, setShowLinkedInNotFoundModal] = useState(false);

	const [toastMessage, setToastMessage] = useState('');
	const [toastType, setToastType] = useState<'INFO' | 'ERROR' | 'SUCCESS'>('ERROR');
	const toastRef = useRef<IRefProps>(null);

	const { setModalState: setVerifyLinkedInModalState } = useVerifyLinkedInContext();

	const isResyncing = callSyncProfileStatus === 'loading';

	useEffect(() => {
		if (!selectedProfileUsername) return;

		(async function () {
			try {
				await fetchUserProfileData(selectedProfileUsername);
			} catch (error) {
				navigate(PAGE_NOT_FOUND_PATH);
			}
		})();
	}, [selectedProfileUsername]);

	const handleResync = async () => {
		if (!userId) return;

		if (isUserLinkedInVerificationPending) {
			setVerifyLinkedInModalState({
				isOpen: true,
				customHeader: 'Verify your account to enable re-sync',
			});
			return;
		}

		const lastSyncTimestamp = userProfileData?.lastSyncTimestamp;

		if (!lastSyncTimestamp) {
			setShowResyncModal(true);
		} else {
			const parsedLastSyncDate = parseSyncTimestamp(lastSyncTimestamp);

			if (!parsedLastSyncDate) return;

			const monthsSinceLastSync = getMonthsSinceLastSync(parsedLastSyncDate) - 1;

			monthsSinceLastSync <= 1 ? setShowLimitReachedModal(true) : setShowResyncModal(true);
		}
	};

	const confirmResync = async () => {
		if (!userId) return;

		try {
			await callSyncProfile(userId);
			setShowResyncModal(false);
			setToastMessage('Profile synced successfully');
			setToastType('SUCCESS');
			toastRef.current?.publish();

			// Reload the page after a short delay
			setTimeout(() => {
				window.location.reload();
			}, 500);
		} catch (error) {
			// handled error in useEffect
		}
	};

	const isLoading =
		fetchUserProfileDataStatus === 'idle' || fetchUserProfileDataStatus === 'loading';

	const experienceMap = userProfileData?.experience ?? {};
	const experienceArrCompanyOrder = userProfileData?.experienceCompanyOrder ?? [];
	const educationArr = userProfileData?.education ?? [];
	const isUserLinkedInVerificationPending = userProfileData
		? isLinkedInVerificationPendingSenderProfileFunc(userProfileData)
		: false;

	const handleClickOpenLinkedInAccount = () => {
		mixpanelActions.trackLinkedInProfile(mixPanelEvents.VIEW_LINKEDIN_PROFILE_CLICKED);
		window.open(userProfileData?.linkedInURL, '__blank');
	};

	const showViewLinkedInBtn = !userProfileData?.isCompanyProfile;
	const showResyncButton = !userProfileData?.isCompanyProfile;

	const renderModalContent = (
		title: string,
		content: string,
		buttonText: string,
		onButtonClick: () => void,
		showLoader = false,
		onCancelButtonClick?: () => void
	) => (
		<div
			className={clsx(
				classes.modalContent,
				(!!onCancelButtonClick || showLoader) && classes.modalContentWithCancel
			)}
		>
			<div className={classes.modalTextContent}>
				{showLoader ? (
					<>
						<Loader size={4} />

						<Text
							variant="h2"
							semiBold
							customClass={clsx(classes.modalHeader, classes.centeredText)}
						>
							Fetching your public LinkedIn Info...
						</Text>
					</>
				) : (
					<>
						<Text variant="h2" semiBold customClass={classes.modalHeader}>
							{title}
						</Text>
						<Text
							variant="p"
							light
							customClass={clsx(
								classes.modalText,
								!!onCancelButtonClick && classes.modalTextMargin
							)}
						>
							{content}
						</Text>
					</>
				)}
				{!!onCancelButtonClick && (
					<Button
						btnText={<CloseIcon size={2.2} />}
						onClick={onCancelButtonClick}
						customClass={classes.modalCancelBtn}
					/>
				)}
			</div>
			{!showLoader && (
				<Button
					btnText={
						<Text variant="span" white lineHeight={2.2} semiBold>
							{buttonText}
						</Text>
					}
					onClick={onButtonClick}
					primary
					customClass={classes.modalButton}
					disabled={isResyncing}
				/>
			)}
		</div>
	);

	useEffect(() => {
		if (!callSyncProfileError) return;

		setShowResyncModal(false);

		if (callSyncProfileErrorStatus === 400) {
			setShowLinkedInNotFoundModal(true);
		} else {
			setToastMessage(callSyncProfileError);
			setToastType('ERROR');
			toastRef.current?.publish();
		}
	}, [callSyncProfileErrorStatus, callSyncProfileError]);

	return (
		<ScrollableLayout>
			{isLoading && (
				<div className={classes.loaderContainer}>
					<DotsLoader />
				</div>
			)}
			{!isLoading && (
				<article className={classes.profileContainer}>
					<section className={classes.Intro}>
						<Avatar
							size={7.4}
							profilePicURL={userProfileData?.profilePicture}
							fallbackIcon={FallbackPicIcon}
						/>
						<div className={classes.introContent}>
							<div className={classes.textWithIcon}>
								<Text variant="h2" medium2M={!isMobile} medium={isMobile} semiBold>
									{userProfileData ? getFullNameSenderProfileFunc(userProfileData) : ''}
								</Text>
								{isUserLinkedInVerificationPending && (
									<Tooltip
										content={
											<Text variant="span" small light>
												{`This user's profile was created via their LinkedIn URL. They are yet to verify that the LinkedIn account for that URL belongs to them. `}
											</Text>
										}
									>
										<UnverifiedIcon
											size={!isMobile ? 2 : 1.6}
											className={classes.pendingIconBlack}
										/>
									</Tooltip>
								)}
							</div>
							<Text variant="p" small light>
								{userProfileData?.headline ?? ''}
							</Text>
							<Text variant="p" small light tertiary>
								{userProfileData?.location?.default ?? ''}
							</Text>

							{showViewLinkedInBtn && (
								<div className={classes.buttonContainer}>
									<Button
										btnText={
											<Text variant="span" brandPrimaryColor small semiBold>
												{'LinkedIn'}
											</Text>
										}
										onClick={handleClickOpenLinkedInAccount}
										suffixIcon={<OpenNewTabIcon size={1.2} className={classes.openAccountIcon} />}
										customClass={classes.openAccountBtn}
									/>
									{showResyncButton && (
										<Button
											btnText={
												<Text variant="span" brandPrimaryColor small semiBold>
													{'Re-sync'}
												</Text>
											}
											onClick={handleResync}
											suffixIcon={<ResyncIcon size={1.2} className={classes.resyncIcon} />}
											customClass={classes.resyncBtn}
										/>
									)}
								</div>
							)}
						</div>
					</section>
					{!!experienceArrCompanyOrder.length && (
						<section className={classes.experienceSection}>
							<div className={classes.header}>
								<Text variant="h2" small semiBold tertiary>
									{'EXPERIENCE'}
								</Text>
								<span aria-hidden />
							</div>
							{experienceArrCompanyOrder.map((companyNameInOrder) => {
								const experience = experienceMap[companyNameInOrder];
								if (!experience) return null;
								const companyExperienceArr = experience.allExperiences;
								const company = companyExperienceArr[0];
								const hasMoreExperiencesInSameCompany = companyExperienceArr.length > 1;

								const totalCompanyExpMonths = companyExperienceArr.reduce(
									(totalMonths, eachExp) => {
										return (
											totalMonths +
											getMonthsDifference(eachExp.startDate ?? null, eachExp.endDate ?? null)
										);
									},
									0
								);

								const totalYearsMonthText = getTotalYearsMonthText(totalCompanyExpMonths);

								return (
									<div className={classes.experienceInfo} key={companyNameInOrder}>
										<Avatar
											size={4.8}
											profilePicURL={company.companyImageURL}
											customRootClass={classes.experienceLogo}
											fallbackIcon={ExperienceFallbackIcon}
										/>
										{hasMoreExperiencesInSameCompany && (
											<div className={classes.moreExperienceContent}>
												<div className={classes.experienceContent}>
													<Text variant="h3" semiBold>
														{company.company}
													</Text>
													{experience.showTimePeriod && (
														<Text variant="p" small light tertiary>
															{totalYearsMonthText}
														</Text>
													)}
												</div>
												{companyExperienceArr.map((companyExperience) => {
													const yearsMonthsText = getTotalExpYearsMonthTextFunc(companyExperience);
													return (
														<div
															className={classes.experienceContent}
															key={companyExperience.title}
														>
															<Text variant="h3" semiBold>
																{companyExperience.title}
															</Text>
															{companyExperience.employmentType && (
																<Text variant="p" small light>
																	{companyExperience.employmentType}
																</Text>
															)}
															{yearsMonthsText && (
																<Text
																	variant="p"
																	small
																	light
																	tertiary
																	customClass={clsx(yearsMonthsText && classes.yearsMonthText)}
																>
																	{getTimePeriodTextExpFunc(companyExperience)}
																	<Text variant="span" small light tertiary>
																		{yearsMonthsText}
																	</Text>
																</Text>
															)}
														</div>
													);
												})}
											</div>
										)}
										{!hasMoreExperiencesInSameCompany && (
											<div className={classes.experienceContent}>
												<Text variant="h3" semiBold>
													{company.title}
												</Text>
												<Text variant="p" small light customClass={classes.companyName}>
													{company.company}
													{company.employmentType && (
														<Text variant="span" small light>
															{company.employmentType}
														</Text>
													)}
												</Text>
												{getTotalYearsMonthText(company.months) && (
													<Text
														variant="p"
														small
														light
														tertiary
														customClass={clsx(
															getTotalYearsMonthText(company.months) && classes.yearsMonthText
														)}
													>
														{getTimePeriodTextExpFunc(company)}
														<Text variant="span" small light tertiary>
															{getTotalYearsMonthText(company.months)}
														</Text>
													</Text>
												)}
											</div>
										)}
									</div>
								);
							})}
						</section>
					)}
					{educationArr?.length > 0 && (
						<section className={classes.educationSection}>
							<div className={classes.header}>
								<Text variant="h2" small semiBold tertiary>
									{'EDUCATION'}
								</Text>
								<span aria-hidden />
							</div>
							{educationArr.map((education, index) => {
								return (
									<div className={classes.educationInfo} key={index}>
										<Avatar
											size={4.8}
											profilePicURL={education.logo}
											customRootClass={classes.educationLogo}
											fallbackIcon={EducationFallbackIcon}
										/>
										<div className={classes.educationContent}>
											<Text variant="h3" semiBold>
												{education.schoolName}
											</Text>
											<Text variant="p" small light>
												{education.degreeName}
											</Text>
											<Text variant="p" tertiary small light>
												{getTimePeriodTextEdFunc(education)}
											</Text>
										</div>
									</div>
								);
							})}
						</section>
					)}
				</article>
			)}
			<Modal
				onCloseModal={() => {
					if (!isResyncing) {
						setShowResyncModal(false);
					}
				}}
				showModal={showResyncModal}
				bottomInMobile
				noPadding
			>
				{renderModalContent(
					'Confirm re-sync',
					isResyncing
						? 'Fetching your public LinkedIn Info ...'
						: 'You can only re-sync once a month. Make sure your LinkedIn is updated before you continue.',
					'Continue to re-sync',
					confirmResync,
					isResyncing,
					!isResyncing && !isMobile ? () => setShowResyncModal(false) : undefined
				)}
			</Modal>

			<Modal
				onCloseModal={() => setShowLimitReachedModal(false)}
				showModal={showLimitReachedModal}
				bottomInMobile
				noPadding
			>
				{renderModalContent(
					'Limit reached',
					"You can only re-sync once a month. If you'd still like to re-sync or facing syncing issues, please email us at support@superdm.com.",
					'Got it',
					() => setShowLimitReachedModal(false)
				)}
			</Modal>

			<Modal
				onCloseModal={() => setShowLinkedInNotFoundModal(false)}
				showModal={showLinkedInNotFoundModal}
				bottomInMobile
				noPadding
			>
				{renderModalContent(
					'Unable to sync',
					'This generally happens if your LinkedIn URL has changed. Please write to us at support@superdm.com.',
					'Got it',
					() => setShowLinkedInNotFoundModal(false)
				)}
			</Modal>

			<Toast
				ref={toastRef}
				toastType={toastType}
				header={toastMessage}
				icon={
					toastType === 'SUCCESS' ? (
						<CheckCircleIcon size={1.8} />
					) : (
						<AlertIcon size={1.8} className={classes.toastIcon} />
					)
				}
			/>
		</ScrollableLayout>
	);
}

export default withAuth(Profile);
