import React, { useRef } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { IEnableMinMaxCharCountPayload } from '@api/user/user.types';
import { enableMinMaxCharCount } from '@api/user';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import Text from '@components/ui/Text';
import Toast from '@components/ui/Toast';
import useFetch from '@hooks/useFetch';
import { AppDispatch } from '@store/index';
import {
	getUserDetailsFromGlobalState,
	setEnableMinMaxCharCount,
} from '@store/userDetailsSlice/userDetailsSlice';
import { AlertIcon, CheckCircleIcon, TickIconColored } from '@src/hoc/withIconStyles';
import { FIRST_MESSAGE_CHARACTER_LIMIT, FIRST_MESSAGE_MIN_CHARACTER_LIMIT } from '@utils/constants';
import classes from './Preferences.styles.module.scss';
import { handleMinMaxCharFunction } from './Preferences.types';

function Preferences() {
	const dispatch = useDispatch<AppDispatch>();
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const toastRef = useRef<IRefProps>(null);

	const {
		callApi: callEnableMinMaxCharCount,
		error: callEnableMinMaxCharCountError,
		status: callEnableMinMaxCharCountStatus,
	} = useFetch(enableMinMaxCharCount);

	const userId = userDetails?.userId;
	const minCharCountEnabled = userDetails?.minCharCountEnabled ?? false;
	const maxCharCountEnabled = userDetails?.maxCharCountEnabled ?? false;

	const isSuccess = callEnableMinMaxCharCountStatus === 'success';

	const handleMinMaxCharChange: handleMinMaxCharFunction = async ({
		enableMaxCharCount,
		enableMinCharCount,
	}) => {
		toastRef.current?.unPublish();
		if (!userId) return;

		const payload: IEnableMinMaxCharCountPayload = {
			user_id: userId,
			max_char_count_enabled: enableMaxCharCount,
			min_char_count_enabled: enableMinCharCount,
		};

		try {
			await callEnableMinMaxCharCount(payload);
			dispatch(
				setEnableMinMaxCharCount({
					maxCharCountEnabled: payload.max_char_count_enabled,
					minCharCountEnabled: payload.min_char_count_enabled,
				})
			);
			toastRef.current?.publish();
		} catch (error) {
			toastRef.current?.publish();
		}
	};

	return (
		<section className={classes.preferencesSection}>
			<Text variant="h2" semiBold>
				{'Preferences'}
			</Text>
			<Text variant="p" tiny secondary light lineHeight={1.6}>
				{'People will need to stick to these character limits until they get a reply from you.'}
			</Text>
			<div
				tabIndex={0}
				className={classes.checkActionContainer}
				onClick={(e) => {
					e.stopPropagation();
					handleMinMaxCharChange({
						enableMaxCharCount: !maxCharCountEnabled,
						enableMinCharCount: minCharCountEnabled,
					});
				}}
			>
				<div className={clsx(classes.checkbox, maxCharCountEnabled && classes.checkboxActive)}>
					{maxCharCountEnabled && <TickIconColored size={1.6} />}
				</div>

				<Text variant="p" small light lineHeight={1.6}>
					{`Allow only short messages (max ${FIRST_MESSAGE_CHARACTER_LIMIT} characters)`}
				</Text>
			</div>
			<div
				tabIndex={0}
				className={classes.checkActionContainer}
				onClick={(e) => {
					e.stopPropagation();
					handleMinMaxCharChange({
						enableMaxCharCount: maxCharCountEnabled,
						enableMinCharCount: !minCharCountEnabled,
					});
				}}
			>
				<div className={clsx(classes.checkbox, minCharCountEnabled && classes.checkboxActive)}>
					{minCharCountEnabled && <TickIconColored size={1.6} />}
				</div>

				<Text variant="p" small light lineHeight={1.6}>
					{`First message cannot be fewer than ${FIRST_MESSAGE_MIN_CHARACTER_LIMIT} characters`}
				</Text>
			</div>

			<Toast
				ref={toastRef}
				toastType={isSuccess ? 'INFO' : 'ERROR'}
				icon={
					isSuccess ? (
						<CheckCircleIcon size={1.8} />
					) : (
						<AlertIcon size={1.8} className={classes.toastIcon} />
					)
				}
				header={
					isSuccess
						? 'Preferences updated'
						: callEnableMinMaxCharCountError ?? 'Something  went wrong, please try again'
				}
			/>
		</section>
	);
}

export default Preferences;
