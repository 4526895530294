import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import {
	IFetchInboxDataParams,
	IFetchInboxSearchDataParams,
	IFetchPaginatedInboxDataParams,
	IGetInboxPayloadParamObj,
	IInboxState,
	ILatestChatAction,
	ISelectedFiltersMap,
	ISelectedSingleFiltersMap,
	TInboxChatDataType,
} from './inboxSlice.types';
import {
	CITY,
	COMPANY,
	COUNTRY,
	EDUCATION,
	EXPERIENCE_MAX,
	EXPERIENCE_MIN,
	FUNCTION,
	LABEL,
	SORT_OPTIONS_VALUE_MAPPINGS,
	SORT,
	TITLE,
	experience_max_options,
	experience_min_options,
	filtersHeadingMappings,
	sortOptions,
	advancedOptions,
	ADVANCED,
	ADVANCED_FILTER_OPTIONS,
	ADVANCED_FILTER_OPTIONS_MAPPINGS,
	MIN_FOLLOWERS,
	MIN_SCORE,
	ADVANCED_FILTER_OPTIONS_DEFAULT_VALUES_MAP,
	MIN_MESSAGE_SCORE,
	BOOKMARK,
	UN_BOOKMARK,
	TIME_PERIOD,
	timePeriodOptions,
	TIME_PERIOD_OPTIONS_VALUE_MAPPINGS,
	READ,
	REPLIED,
	NOT_READ,
	NOT_REPLIED,
	ARCHIVED,
	UN_ARCHIVED,
	UNREAD,
	NOT_UNREAD,
	UnreadOptions,
	SORT_OPTIONS,
	SORT_RELEVANCE_OPTION_VALUES_TAB_MAPPINGS,
	SORT_RELEVANCE_OPTION_VALUES_ARR,
	FETCH_CHATS_DATA_PAGE_SIZE,
	TAG,
	MAX_CREATION_TIMESTAMP,
} from '@utils/constants';
import { getInboxData, getInboxSearchData } from '@api/chats';
import {
	IAppliedFiltersPayload,
	IFetchInboxAPIPayload,
	IFetchInboxSearchAPIPayload,
} from '@api/chats/chats.types';
import { INewFilterOptionMap, INewFilters, SenderChatData } from '@src/models/inbox';
import { Tabs } from '@src/models/user';

const getCommonFiltersState = () => {
	const commonInitialFiltersState: INewFilters[] = [
		{
			filterType: 'singleClick',
			options: UnreadOptions,
			filterName: UNREAD,
			filterHeader: filtersHeadingMappings[UNREAD] ?? UNREAD,
		},
		{
			filterType: 'single',
			options: timePeriodOptions,
			filterName: TIME_PERIOD,
			filterHeader: filtersHeadingMappings[TIME_PERIOD] ?? TIME_PERIOD,
		},
		{
			filterType: 'single',
			options: sortOptions,
			filterName: SORT,
			filterHeader: filtersHeadingMappings[SORT],
		},
		{
			filterType: 'multi',
			options: null,
			filterName: LABEL,
			filterHeader: filtersHeadingMappings[LABEL] ?? LABEL,
		},
		{
			filterType: 'multi',
			options: null,
			filterName: TAG,
			filterHeader: filtersHeadingMappings[TAG] ?? TAG,
		},
		{
			filterType: 'multi',
			options: null,
			filterName: FUNCTION,
			filterHeader: filtersHeadingMappings[FUNCTION] ?? FUNCTION,
		},
		{
			filterType: 'multi',
			options: null,
			filterName: COMPANY,
			filterHeader: filtersHeadingMappings[COMPANY] ?? COMPANY,
		},
		{
			filterType: 'multi',
			options: null,
			filterName: EDUCATION,
			filterHeader: filtersHeadingMappings[EDUCATION] ?? EDUCATION,
		},
		{
			filterType: 'single',
			options: experience_min_options,
			filterName: EXPERIENCE_MIN,
			filterHeader: filtersHeadingMappings[EXPERIENCE_MIN] ?? EXPERIENCE_MIN,
		},
		{
			filterType: 'multi',
			options: null,
			filterName: CITY,
			filterHeader: filtersHeadingMappings[CITY] ?? CITY,
		},
		{
			filterType: 'multi',
			options: null,
			filterName: TITLE,
			filterHeader: filtersHeadingMappings[TITLE] ?? TITLE,
		},
		{
			filterType: 'single',
			options: experience_max_options,
			filterName: EXPERIENCE_MAX,
			filterHeader: filtersHeadingMappings[EXPERIENCE_MAX] ?? EXPERIENCE_MAX,
		},
		{
			filterType: 'multi',
			options: null,
			filterName: COUNTRY,
			filterHeader: filtersHeadingMappings[COUNTRY] ?? COUNTRY,
		},
		{
			filterType: 'single',
			options: advancedOptions,
			filterName: ADVANCED,
			filterHeader: filtersHeadingMappings[ADVANCED] ?? ADVANCED,
		},
	];

	return commonInitialFiltersState;
};

export const initialState: IInboxState = {
	chatsData: [],
	mainFilters: [],
	selectedMainFilter: null,
	filters: getCommonFiltersState(),
	selectedSingleFilters: {
		[SORT]: null,
		[EXPERIENCE_MAX]: null,
		[EXPERIENCE_MIN]: null,
		[ADVANCED]: null,
		[BOOKMARK]: null,
		[READ]: null,
		[REPLIED]: null,
		[TIME_PERIOD]: null,
		[ARCHIVED]: null,
		[UNREAD]: null,
	},
	selectedMultiFilters: {
		[TITLE]: [],
		[COUNTRY]: [],
		[EDUCATION]: [],
		[CITY]: [],
		[FUNCTION]: [],
		[LABEL]: [],
		[COMPANY]: [],
		[TAG]: [],
	},
	paginationDetails: null,
	fetchStatus: 'idle',
	fetchError: null,
	paginatedFetchError: null,
	paginatedFetchStatus: 'idle',
	searchTermsQueryMap: {},
	searchTerms: [],
	searchChatsData: [],
	searchFetchError: null,
	searchFetchStatus: 'idle',
	searchPaginationDetails: null,
	searchPaginatedFetchError: null,
	searchPaginatedFetchStatus: 'idle',
	tabOverviewChatsData: [],
	tabOverviewFilters: getCommonFiltersState(),
	tabOverviewPaginationDetails: null,
	tabOverviewPaginatedFetchStatus: 'idle',
	tabOverviewPaginatedFetchError: null,
	tabOverviewFetchStatus: 'idle',
	tabOverviewFetchError: null,
	tabOverviewDetails: null,
	chatsDataTotalCount: 0,
	archivedChatsDataTotalCount: 0,
	tabOverviewChatsDataTotalCount: 0,
	bulkReplyChatsData: [],
	bulkReplyChatsDataTotalCount: 0,
	bulkReplyPaginationDetails: null,
	bulkReplyPaginatedFetchStatus: 'idle',
	bulkReplyPaginatedFetchError: null,
	bulkReplyFetchStatus: 'idle',
	bulkReplyFetchError: null,
	bulkReplyCurrFilteredOutChatsData: [],
	chatActionCount: 0,
	currChatsDataSelectedChatIndex: null,
	currChatsDataSelectedChatId: null,
	currChatsDataSelectedChatInboxType: null,
	currChatDataSelectedChatVisited: false,
	newMainFilterOverviewDetails: null,
	tabOverviewArchiveDetails: null,
	currBookmarkChatsMap: {},
	currReadChatsMap: {},
	currReportChatsMap: {},
	currBlockChatsMap: {},
	currAssignedLabelsChatsMap: {},
	chatCardActionsMap: {},
	chatCardSentMessageMap: {},
	currFilteredOutChatsData: [],
	tapToRefresh: false,
	currFetchRequestId: null,
	currFetchRequestTimestamp: null,
	currChatsListView: 'SCROLL_CARD',
};

export const getAllFiltersFromPreAppliedFilters = (appliedFilters: IAppliedFiltersPayload) => {
	const selectedMultiFilters: ISelectedFiltersMap = { ...initialState.selectedMultiFilters };
	const selectedSingleFilters: ISelectedSingleFiltersMap = {
		...initialState.selectedSingleFilters,
	};

	Object.entries(appliedFilters).forEach(([filterName, selectedValue]) => {
		if (selectedMultiFilters[filterName] && selectedValue instanceof Array) {
			selectedMultiFilters[filterName] = selectedValue;
		}

		if (
			selectedSingleFilters[filterName] === null &&
			(typeof selectedValue === 'string' || typeof selectedValue === 'number') &&
			filterName !== MIN_FOLLOWERS &&
			filterName !== MIN_SCORE &&
			filterName !== MIN_MESSAGE_SCORE
		) {
			selectedSingleFilters[filterName] = `${selectedValue}`;
		}

		if (typeof selectedValue === 'boolean') {
			// for bookmark filter
			if (filterName === BOOKMARK) {
				selectedSingleFilters[BOOKMARK] = selectedValue ? BOOKMARK : UN_BOOKMARK;
			}

			// for unread filter
			if (filterName === UNREAD) {
				selectedSingleFilters[UNREAD] = selectedValue ? UNREAD : NOT_UNREAD;
			}

			// for archived filter
			if (filterName === ARCHIVED) {
				selectedSingleFilters[ARCHIVED] = selectedValue ? ARCHIVED : UN_ARCHIVED;
			}

			// for read filter
			if (filterName === READ) {
				selectedSingleFilters[READ] = selectedValue ? READ : NOT_READ;
			}

			// for replied filter
			if (filterName === REPLIED) {
				selectedSingleFilters[REPLIED] = selectedValue ? REPLIED : NOT_REPLIED;
			}
		}

		// for sort filter
		if (filterName === SORT) {
			Object.entries(SORT_OPTIONS_VALUE_MAPPINGS).forEach(
				([optionsDisplayName, optionDisplayValue]) => {
					if (
						typeof selectedValue === 'string' &&
						SORT_RELEVANCE_OPTION_VALUES_ARR.includes(selectedValue)
					) {
						selectedSingleFilters[SORT] = SORT_OPTIONS.SCORE;
					} else if (optionDisplayValue === selectedValue) {
						selectedSingleFilters[SORT] = optionsDisplayName;
					}
				}
			);
		}

		// for time period filter
		if (filterName === TIME_PERIOD) {
			Object.entries(TIME_PERIOD_OPTIONS_VALUE_MAPPINGS).forEach(
				([optionsDisplayName, optionDisplayValue]) => {
					if (optionDisplayValue === selectedValue) {
						selectedSingleFilters[TIME_PERIOD] = optionsDisplayName;
					}
				}
			);
		}

		// for advanced filter
		if (
			typeof selectedValue === 'number' &&
			(filterName === MIN_FOLLOWERS || filterName === MIN_SCORE || filterName === MIN_MESSAGE_SCORE)
		) {
			selectedSingleFilters[ADVANCED] =
				filterName === MIN_FOLLOWERS
					? ADVANCED_FILTER_OPTIONS['HIGHLY_FOLLOWED']
					: filterName === MIN_SCORE
					? ADVANCED_FILTER_OPTIONS['RECOMMENDED']
					: ADVANCED_FILTER_OPTIONS['WELL_WRITTEN'];
		}
	});

	return {
		selectedMultiFilters,
		selectedSingleFilters,
	};
};

export const getFiltersObj = (
	selectedSingleFilters: ISelectedSingleFiltersMap,
	selectedMultiFilters: ISelectedFiltersMap,
	currStateFilters: INewFilters[]
) => {
	// handling creating filters object value
	const filtersObj: IAppliedFiltersPayload = {};
	Object.entries(selectedMultiFilters).forEach(([filterName, selectedMultiFilterOptions]) => {
		if (!(selectedMultiFilterOptions.length > 0)) return;
		filtersObj[filterName] = [...selectedMultiFilterOptions];
	});

	Object.entries(selectedSingleFilters).forEach(([filterName, selectedSingleFilterOption]) => {
		if (
			!selectedSingleFilterOption ||
			filterName === SORT ||
			filterName === ADVANCED ||
			filterName === BOOKMARK ||
			filterName === READ ||
			filterName === REPLIED ||
			filterName === TIME_PERIOD ||
			filterName === ARCHIVED ||
			filterName === UNREAD
		)
			return;
		filtersObj[filterName] = Number(selectedSingleFilterOption);
	});

	// handling bookmark filter
	if (selectedSingleFilters[BOOKMARK]) {
		const isBookmarked = selectedSingleFilters[BOOKMARK] === BOOKMARK;

		filtersObj[BOOKMARK] = isBookmarked;
	}

	// handling unread filter
	if (selectedSingleFilters[UNREAD]) {
		const isUnread = selectedSingleFilters[UNREAD] === UNREAD;

		filtersObj[UNREAD] = isUnread;
	}

	// handling archived filter
	if (selectedSingleFilters[ARCHIVED]) {
		const isArchived = selectedSingleFilters[ARCHIVED] === ARCHIVED;

		filtersObj[ARCHIVED] = isArchived;
	}

	// handling read filter
	if (selectedSingleFilters[READ]) {
		const isRead = selectedSingleFilters[READ] === READ;

		filtersObj[READ] = isRead;
	}

	// handling replied filter
	if (selectedSingleFilters[REPLIED]) {
		const isReplied = selectedSingleFilters[REPLIED] === REPLIED;

		filtersObj[REPLIED] = isReplied;
	}

	// handling sort filter
	if (selectedSingleFilters[SORT]) {
		if (
			selectedSingleFilters[SORT] === SORT_OPTIONS.SCORE &&
			selectedSingleFilters[UNREAD] === UNREAD
		) {
			filtersObj[SORT] = SORT_RELEVANCE_OPTION_VALUES_TAB_MAPPINGS[UNREAD];
		} else {
			filtersObj[SORT] = SORT_OPTIONS_VALUE_MAPPINGS[selectedSingleFilters[SORT]];
		}
	}

	// handling time period filter
	if (selectedSingleFilters[TIME_PERIOD]) {
		filtersObj[TIME_PERIOD] =
			TIME_PERIOD_OPTIONS_VALUE_MAPPINGS[selectedSingleFilters[TIME_PERIOD]];
	}

	// handling advanced filter
	if (selectedSingleFilters[ADVANCED]) {
		const currStateAdvancedFilter = currStateFilters.find(
			(stateFilter) => stateFilter.filterName === ADVANCED
		);

		if (currStateAdvancedFilter && currStateAdvancedFilter.options) {
			filtersObj[ADVANCED_FILTER_OPTIONS_MAPPINGS[selectedSingleFilters[ADVANCED]]] = Number(
				currStateAdvancedFilter.options[selectedSingleFilters[ADVANCED]].valueMap
			);
		}
	}

	// handling exceptional max_creation_timestamp filter for bulk reply
	if (selectedSingleFilters[MAX_CREATION_TIMESTAMP]) {
		filtersObj[MAX_CREATION_TIMESTAMP] = selectedSingleFilters[MAX_CREATION_TIMESTAMP];
	}

	return filtersObj;
};

const getInboxPayload = ({
	selectedMultiFilters,
	selectedSingleFilters,
	userId,
	filtersReloadRequired,
	currStateFilters,
	lastElementPosition,
	archivedCountRequired,
	lastFiveMessagesRequired,
	topPicksThreshold,
}: IGetInboxPayloadParamObj) => {
	// handling creating filters value

	const filtersObj: IAppliedFiltersPayload = getFiltersObj(
		selectedSingleFilters,
		selectedMultiFilters,
		currStateFilters
	);

	// handling creating sortBy value
	let sortByValue = null;

	const isUnreadFilterSelected = selectedSingleFilters[UNREAD] === UNREAD;

	if (selectedSingleFilters[SORT] === SORT_OPTIONS.SCORE && isUnreadFilterSelected) {
		sortByValue = SORT_RELEVANCE_OPTION_VALUES_TAB_MAPPINGS[UNREAD];
	} else if (selectedSingleFilters[SORT]) {
		sortByValue = SORT_OPTIONS_VALUE_MAPPINGS[selectedSingleFilters[SORT]];
	}

	// handling overall payload
	const payload: IFetchInboxAPIPayload = {
		filter_reload_required: filtersReloadRequired,
		archived_count_required: archivedCountRequired && !isUnreadFilterSelected,
		last_5_messages_required: lastFiveMessagesRequired,
		last_message_required: true,
		applied_filters: filtersObj,
		user_id: userId,
		page_details: {
			page_size: FETCH_CHATS_DATA_PAGE_SIZE,
			sort_order: 'Desc',
			sort_by: sortByValue,
			last_element_position: lastElementPosition,
		},
	};

	if (topPicksThreshold) {
		payload.top_picks_threshold = topPicksThreshold;
	}

	return payload;
};

const getRearrangedFilters = (
	selectedMultiFiltersFromState: ISelectedFiltersMap,
	selectedSingleFiltersFromState: ISelectedSingleFiltersMap,
	filtersFromState: INewFilters[]
) => {
	const selectedFilters: INewFilters[] = [];
	const unSelectedFilters: INewFilters[] = [];
	const finalFilters: INewFilters[] = [];

	const initialStateFilters = initialState.filters;

	initialStateFilters.forEach((filter) => {
		const filterName = filter.filterName;
		const isFilterSelected =
			selectedMultiFiltersFromState[filterName]?.length > 0 ||
			selectedSingleFiltersFromState[filterName];
		const isSortFilter = filterName === SORT;
		const isUnreadFilter = filterName === UNREAD;
		const isTimePeriodFilter = filterName === TIME_PERIOD;

		const filterFromCurrState = filtersFromState.find((filter) => filter.filterName === filterName);

		if (!filterFromCurrState) return;

		if (isSortFilter || isUnreadFilter || isTimePeriodFilter) {
			finalFilters.push(filterFromCurrState);
		} else if (isFilterSelected) {
			selectedFilters.push(filterFromCurrState);
		} else {
			unSelectedFilters.push(filterFromCurrState);
		}
	});

	finalFilters.push(...selectedFilters, ...unSelectedFilters);

	return finalFilters;
};

export const fetchPaginatedInboxData = createAsyncThunk(
	'inbox/fetchPaginatedInboxData',
	async (
		{ lastFiveMessagesRequired, topPicksThreshold }: IFetchPaginatedInboxDataParams,
		{ getState }
	) => {
		const state = getState() as RootState;
		const userDetails = state.userDetails.data;
		const selectedMultiFilters = state.inbox.selectedMultiFilters;
		const selectedSingleFilters = state.inbox.selectedSingleFilters;

		const currStateFilters = state.inbox.filters;

		const lastElementPosition =
			state.inbox.chatsData.length +
			state.inbox.currFilteredOutChatsData.length -
			state.inbox.chatActionCount;

		const payload = getInboxPayload({
			selectedMultiFilters: selectedMultiFilters,
			selectedSingleFilters: selectedSingleFilters,
			userId: userDetails?.userId ?? -1,
			filtersReloadRequired: false,
			currStateFilters: currStateFilters,
			lastElementPosition: lastElementPosition > 0 ? lastElementPosition : 0,
			archivedCountRequired: false,
			lastFiveMessagesRequired: !!lastFiveMessagesRequired,
			topPicksThreshold: topPicksThreshold,
		});

		const data = await getInboxData(payload);
		return data;
	},
	{
		condition: (_, { getState }) => {
			const state = getState() as RootState;
			const { paginationDetails, paginatedFetchStatus } = getInboxDataFromGlobalState(state);

			let hasMoreChats = false;

			hasMoreChats = paginationDetails?.hasMoreChats ?? false;

			if (!hasMoreChats || paginatedFetchStatus !== 'idle') return false;
			return true;
		},
		dispatchConditionRejection: true,
	}
);

export const fetchInboxData = createAsyncThunk(
	'inbox/fetchInboxData',
	async (
		{
			filterReloadRequired,
			archivedCountRequired,
			lastFiveMessagesRequired,
			topPicksThreshold,
		}: IFetchInboxDataParams,
		{ getState }
	) => {
		const state = getState() as RootState;

		const userDetails = state.userDetails.data;
		const selectedMultiFilters = state.inbox.selectedMultiFilters;
		const selectedSingleFilters = state.inbox.selectedSingleFilters;
		const currStateFilters = state.inbox.filters;

		const payload = getInboxPayload({
			selectedMultiFilters: selectedMultiFilters,
			selectedSingleFilters: selectedSingleFilters,
			userId: userDetails?.userId ?? -1,
			filtersReloadRequired: !!filterReloadRequired,
			currStateFilters: currStateFilters,
			lastElementPosition: 0,
			archivedCountRequired: !!archivedCountRequired,
			lastFiveMessagesRequired: !!lastFiveMessagesRequired,
			topPicksThreshold: topPicksThreshold,
		});

		const data = await getInboxData(payload);
		return data;
	}
);

export const fetchPaginatedInboxSearchData = createAsyncThunk(
	'inbox/fetchPaginatedInboxSearchData',
	async ({ searchStr, searchQuery }: IFetchInboxSearchDataParams, { getState }) => {
		const state = getState() as RootState;
		const userDetails = state.userDetails.data;
		const paginationDetails = state.inbox.searchPaginationDetails;

		const statePageNumber = paginationDetails?.pageNumber ?? 0;

		const payload: IFetchInboxSearchAPIPayload = {
			search_string: searchStr,
			user_id: userDetails?.userId ?? -1,
			page_details: {
				page_number: statePageNumber + 1,
				page_size: FETCH_CHATS_DATA_PAGE_SIZE,
				sort_by: null,
				sort_order: 'Desc',
			},
			search_query: searchQuery,
		};

		const data = await getInboxSearchData(payload);
		return {
			searchStr: searchStr,
			searchData: data,
		};
	},
	{
		condition: (_, { getState }) => {
			const state = getState() as RootState;
			const { searchPaginationDetails, searchPaginatedFetchStatus } =
				getInboxDataFromGlobalState(state);

			let hasMoreChats = false;

			hasMoreChats =
				(searchPaginationDetails?.pageNumber ?? 0) < (searchPaginationDetails?.totalPages ?? 1) - 1;

			if (!hasMoreChats || searchPaginatedFetchStatus !== 'idle') return false;
			return true;
		},
		dispatchConditionRejection: true,
	}
);

export const fetchInboxSearchData = createAsyncThunk(
	'inbox/fetchInboxSearchData',
	async ({ searchStr, searchQuery }: IFetchInboxSearchDataParams, { getState }) => {
		const state = getState() as RootState;
		const userDetails = state.userDetails.data;

		const payload: IFetchInboxSearchAPIPayload = {
			search_string: searchStr,
			user_id: userDetails?.userId ?? -1,
			page_details: {
				page_number: 0,
				page_size: FETCH_CHATS_DATA_PAGE_SIZE,
				sort_by: null,
				sort_order: 'Desc',
			},
			search_query: searchQuery,
		};

		const data = await getInboxSearchData(payload);
		return {
			searchStr: searchStr,
			searchData: data,
		};
	}
);

export const fetchPaginatedTabOverviewInboxData = createAsyncThunk(
	'inbox/fetchPaginatedTabOverviewInboxData',
	async ({ lastFiveMessagesRequired }: IFetchPaginatedInboxDataParams, { getState }) => {
		const state = getState() as RootState;
		const userDetails = state.userDetails.data;
		const selectedMultiFilters = state.inbox.selectedMultiFilters;
		const selectedSingleFilters = state.inbox.selectedSingleFilters;

		const currStateFilters = state.inbox.tabOverviewFilters;

		const lastElementPosition =
			state.inbox.tabOverviewChatsData.length +
			state.inbox.currFilteredOutChatsData.length -
			state.inbox.chatActionCount;

		const payload = getInboxPayload({
			selectedMultiFilters: selectedMultiFilters,
			selectedSingleFilters: selectedSingleFilters,
			userId: userDetails?.userId ?? -1,
			filtersReloadRequired: false,
			currStateFilters: currStateFilters,
			lastElementPosition: lastElementPosition > 0 ? lastElementPosition : 0,
			archivedCountRequired: false,
			lastFiveMessagesRequired: !!lastFiveMessagesRequired,
		});

		const data = await getInboxData(payload);
		return data;
	},
	{
		condition: (_, { getState }) => {
			const state = getState() as RootState;
			const { tabOverviewPaginationDetails, tabOverviewPaginatedFetchStatus } =
				getInboxDataFromGlobalState(state);

			let hasMoreChats = false;

			hasMoreChats = tabOverviewPaginationDetails?.hasMoreChats ?? false;

			if (!hasMoreChats || tabOverviewPaginatedFetchStatus !== 'idle') return false;
			return true;
		},
		dispatchConditionRejection: true,
	}
);

export const fetchTabOverviewInboxData = createAsyncThunk(
	'inbox/fetchTabOverviewInboxData',
	async (
		{
			filterReloadRequired,
			archivedCountRequired,
			lastFiveMessagesRequired,
		}: IFetchInboxDataParams,
		{ getState }
	) => {
		const state = getState() as RootState;

		const userDetails = state.userDetails.data;
		const selectedMultiFilters = state.inbox.selectedMultiFilters;
		const selectedSingleFilters = state.inbox.selectedSingleFilters;
		const currStateFilters = state.inbox.tabOverviewFilters;

		const payload = getInboxPayload({
			selectedMultiFilters: selectedMultiFilters,
			selectedSingleFilters: selectedSingleFilters,
			userId: userDetails?.userId ?? -1,
			filtersReloadRequired: !!filterReloadRequired,
			currStateFilters: currStateFilters,
			lastElementPosition: 0,
			archivedCountRequired: !!archivedCountRequired,
			lastFiveMessagesRequired: !!lastFiveMessagesRequired,
		});

		const data = await getInboxData(payload);
		return data;
	}
);

export const fetchPaginatedBulkReplyInboxData = createAsyncThunk(
	'inbox/fetchPaginatedBulkReplyInboxData',
	async (
		{ lastFiveMessagesRequired, topPicksThreshold }: IFetchPaginatedInboxDataParams,
		{ getState }
	) => {
		const state = getState() as RootState;
		const userDetails = state.userDetails.data;
		const selectedMultiFilters = state.inbox.selectedMultiFilters;
		const selectedSingleFilters = { ...state.inbox.selectedSingleFilters };

		// handling replied filter for bulk reply
		selectedSingleFilters[REPLIED] = NOT_REPLIED;

		// handling and exceptional max_creation_timestamp filter for bulk reply to fetch chats from the last fetch inbox chats
		selectedSingleFilters[MAX_CREATION_TIMESTAMP] = state.inbox.currFetchRequestTimestamp;

		const currStateFilters = state.inbox.filters;

		const lastElementPosition =
			state.inbox.bulkReplyChatsData.length + state.inbox.bulkReplyCurrFilteredOutChatsData.length;

		const payload = getInboxPayload({
			selectedMultiFilters: selectedMultiFilters,
			selectedSingleFilters: selectedSingleFilters,
			userId: userDetails?.userId ?? -1,
			filtersReloadRequired: false,
			currStateFilters: currStateFilters,
			lastElementPosition: lastElementPosition > 0 ? lastElementPosition : 0,
			archivedCountRequired: false,
			lastFiveMessagesRequired: !!lastFiveMessagesRequired,
			topPicksThreshold: topPicksThreshold,
		});

		const data = await getInboxData(payload);
		return data;
	},
	{
		condition: (_, { getState }) => {
			const state = getState() as RootState;
			const { bulkReplyPaginationDetails, bulkReplyPaginatedFetchStatus } =
				getInboxDataFromGlobalState(state);

			let hasMoreChats = false;

			hasMoreChats = bulkReplyPaginationDetails?.hasMoreChats ?? false;

			if (!hasMoreChats || bulkReplyPaginatedFetchStatus !== 'idle') return false;
			return true;
		},
		dispatchConditionRejection: true,
	}
);

export const fetchBulkReplyInboxData = createAsyncThunk(
	'inbox/fetchBulkReplyInboxData',
	async (
		{
			filterReloadRequired,
			archivedCountRequired,
			lastFiveMessagesRequired,
			topPicksThreshold,
		}: IFetchInboxDataParams,
		{ getState }
	) => {
		const state = getState() as RootState;

		const userDetails = state.userDetails.data;
		const selectedMultiFilters = state.inbox.selectedMultiFilters;
		const selectedSingleFilters = { ...state.inbox.selectedSingleFilters };
		const currStateFilters = state.inbox.filters;

		// handling replied filter for bulk reply
		selectedSingleFilters[REPLIED] = NOT_REPLIED;

		// handling and exceptional max_creation_timestamp filter for bulk reply to fetch chats from the last fetch inbox chats
		selectedSingleFilters[MAX_CREATION_TIMESTAMP] = state.inbox.currFetchRequestTimestamp;

		const payload = getInboxPayload({
			selectedMultiFilters: selectedMultiFilters,
			selectedSingleFilters: selectedSingleFilters,
			userId: userDetails?.userId ?? -1,
			filtersReloadRequired: !!filterReloadRequired,
			currStateFilters: currStateFilters,
			lastElementPosition: 0,
			archivedCountRequired: !!archivedCountRequired,
			lastFiveMessagesRequired: !!lastFiveMessagesRequired,
			topPicksThreshold: topPicksThreshold,
		});

		const data = await getInboxData(payload);
		return data;
	}
);

const inboxSlice = createSlice({
	name: 'inbox',
	initialState: initialState,
	reducers: {
		setMainFilters(state, action) {
			const mainFiltersData = action.payload.filtersToPrepend as Tabs[];
			state.mainFilters = [...initialState.mainFilters, ...mainFiltersData];
		},
		setSelectedMainFilter(state, action) {
			state.selectedMainFilter = action.payload.selectedMainFilter ?? null;
		},
		setSelectedFilters(state, action) {
			const singleSelectedFilters = action.payload.singleSelectedFilters;
			const multiSelectedFilters = action.payload.multiSelectedFilters;
			const inboxType = action.payload.inboxType as TInboxChatDataType;
			const reArrangeFilters = action.payload.reArrangeFilters;

			state.selectedSingleFilters = { ...singleSelectedFilters };
			state.selectedMultiFilters = { ...multiSelectedFilters };

			if (inboxType === 'INBOX_VIEW' && reArrangeFilters) {
				state.filters = getRearrangedFilters(
					multiSelectedFilters,
					singleSelectedFilters,
					state.filters
				);
			}

			if (inboxType === 'TAB_OVERVIEW_VIEW' && reArrangeFilters) {
				state.tabOverviewFilters = getRearrangedFilters(
					multiSelectedFilters,
					singleSelectedFilters,
					state.tabOverviewFilters
				);
			}
		},
		setAdvancedFilterOptions(state, action) {
			const preAppliedFilters = action.payload.preAppliedFilters as IAppliedFiltersPayload;
			const inboxType = action.payload.inboxType as TInboxChatDataType;

			// handling advanced filter option values
			const minFollowersValue = preAppliedFilters[MIN_FOLLOWERS];
			const minScoreValue = preAppliedFilters[MIN_SCORE];
			const minMessageScoreValue = preAppliedFilters[MIN_MESSAGE_SCORE];

			const advancedDefaultOptions = initialState.filters.find(
				(filter) => filter.filterName === ADVANCED
			)?.options;

			let advancedOptionsToUpdate: INewFilterOptionMap = {};

			if (advancedDefaultOptions) {
				advancedOptionsToUpdate = { ...advancedDefaultOptions };
				advancedOptionsToUpdate[ADVANCED_FILTER_OPTIONS.HIGHLY_FOLLOWED] = {
					...advancedOptionsToUpdate[ADVANCED_FILTER_OPTIONS.HIGHLY_FOLLOWED],
					valueMap: Number(
						minFollowersValue ??
							ADVANCED_FILTER_OPTIONS_DEFAULT_VALUES_MAP[ADVANCED_FILTER_OPTIONS.HIGHLY_FOLLOWED]
					),
				};
				advancedOptionsToUpdate[ADVANCED_FILTER_OPTIONS.RECOMMENDED] = {
					...advancedOptionsToUpdate[ADVANCED_FILTER_OPTIONS.RECOMMENDED],
					valueMap: Number(
						minScoreValue ??
							ADVANCED_FILTER_OPTIONS_DEFAULT_VALUES_MAP[ADVANCED_FILTER_OPTIONS.RECOMMENDED]
					),
				};
				advancedOptionsToUpdate[ADVANCED_FILTER_OPTIONS.WELL_WRITTEN] = {
					...advancedOptionsToUpdate[ADVANCED_FILTER_OPTIONS.WELL_WRITTEN],
					valueMap: Number(
						minMessageScoreValue ??
							ADVANCED_FILTER_OPTIONS_DEFAULT_VALUES_MAP[ADVANCED_FILTER_OPTIONS.WELL_WRITTEN]
					),
				};
			}

			if (inboxType === 'INBOX_VIEW') {
				state.filters = state.filters.map((filter) => {
					if (filter.filterName === ADVANCED) {
						return {
							...filter,
							options: advancedOptionsToUpdate,
						};
					}
					return filter;
				});
			}
			if (inboxType === 'TAB_OVERVIEW_VIEW') {
				state.tabOverviewFilters = state.tabOverviewFilters.map((filter) => {
					if (filter.filterName === ADVANCED) {
						return {
							...filter,
							options: advancedOptionsToUpdate,
						};
					}
					return filter;
				});
			}
		},
		setTabOverviewDetails(state, action) {
			const preAppliedFilters = action.payload.preAppliedFilters;
			const filterName = action.payload.filterName;

			state.tabOverviewDetails = {
				preAppliedFilters: preAppliedFilters,
				filterName: filterName,
			};
		},
		setCurrChatsDataSelectedChatId(state, action) {
			state.currChatsDataSelectedChatId = action.payload.chatId ?? null;
			state.currChatsDataSelectedChatIndex = action.payload.index ?? null;
			state.currChatsDataSelectedChatInboxType = action.payload.inboxType ?? null;
		},
		setCurrChatsDataSelectedChatVisited(state, action) {
			state.currChatDataSelectedChatVisited = action.payload.isVisited ?? false;
		},
		setNewMainFilterOverviewDetails(state, action) {
			state.newMainFilterOverviewDetails = action.payload.newMainFilterOverviewDetails ?? null;
		},
		setTabOverviewArchiveDetails(state, action) {
			state.tabOverviewArchiveDetails = action.payload.tabOverviewArchiveDetails ?? null;
		},
		setReadChatActionCount(state, action) {
			const chatId = action.payload.chatId;
			const isChatRead = action.payload.chatRead;

			const isUnreadFilterSelected = state.selectedSingleFilters[UNREAD] === UNREAD;
			const isBookmarkedFilterSelected = state.selectedSingleFilters[BOOKMARK] === BOOKMARK;

			const isChatBookmarked = !!state.currBookmarkChatsMap[chatId];
			const isChatReported = !!state.currReportChatsMap[chatId];
			const isChatBlocked = !!state.currBlockChatsMap[chatId];
			const isChatAlreadyRead = !!state.currReadChatsMap[chatId];

			// chatActionCount is changed for bookmark, report, block and read conditions
			// checking this condition to avoid double increment or decrement

			const shouldUpdateChatActionCount =
				(isBookmarkedFilterSelected ? isChatBookmarked : true) &&
				!isChatReported &&
				isUnreadFilterSelected &&
				!isChatBlocked;

			if (shouldUpdateChatActionCount) {
				if (isChatRead && !isChatAlreadyRead) {
					state.chatActionCount += 1;
				}

				if (!isChatRead && isChatAlreadyRead) {
					state.chatActionCount -= 1;
				}
			}
		},
		setBookmarkChatActionCount(state, action) {
			const chatId = action.payload.chatId;
			const isChatBookmarked = action.payload.chatBookmarked;

			const isUnreadFilterSelected = state.selectedSingleFilters[UNREAD] === UNREAD;
			const isBookmarkedFilterSelected = state.selectedSingleFilters[BOOKMARK] === BOOKMARK;

			const isChatRead = !!state.currReadChatsMap[chatId];
			const isChatReported = !!state.currReportChatsMap[chatId];
			const isChatBlocked = !!state.currBlockChatsMap[chatId];
			const isChatAlreadyBookmarked = !!state.currBookmarkChatsMap[chatId];

			// chatActionCount is changed for bookmark, report , block and read conditions
			// checking this condition to avoid double increment or decrement

			const shouldUpdateChatActionCount =
				(isUnreadFilterSelected ? !isChatRead : true) &&
				!isChatReported &&
				isBookmarkedFilterSelected &&
				!isChatBlocked;

			if (shouldUpdateChatActionCount) {
				if (!isChatBookmarked && isChatAlreadyBookmarked) {
					state.chatActionCount += 1;
				}

				if (isChatBookmarked && !isChatAlreadyBookmarked) {
					state.chatActionCount -= 1;
				}
			}
		},
		setReportChatActionCount(state, action) {
			const chatId = action.payload.chatId;
			const isChatReported = action.payload.chatReported;

			const isUnreadFilterSelected = state.selectedSingleFilters[UNREAD] === UNREAD;
			const isBookmarkedFilterSelected = state.selectedSingleFilters[BOOKMARK] === BOOKMARK;

			const isChatRead = !!state.currReadChatsMap[chatId];
			const isChatBookmarked = !!state.currBookmarkChatsMap[chatId];
			const isChatAlreadyReported = !!state.currReportChatsMap[chatId];
			const isChatAlreadyBlocked = !!state.currBlockChatsMap[chatId];

			// chatActionCount is changed for bookmark, report , block and read conditions
			// checking this condition to avoid double increment or decrement

			const shouldUpdateChatActionCount =
				(isUnreadFilterSelected ? !isChatRead : true) &&
				(isBookmarkedFilterSelected ? isChatBookmarked : true) &&
				!isChatAlreadyBlocked;

			if (shouldUpdateChatActionCount) {
				if (isChatReported && !isChatAlreadyReported) {
					state.chatActionCount += 1;
				}

				if (!isChatReported && isChatAlreadyReported) {
					state.chatActionCount -= 1;
				}
			}
		},

		setBlockChatActionCount(state, action) {
			const chatId = action.payload.chatId;
			const isChatBlocked = action.payload.chatBlocked;

			const isUnreadFilterSelected = state.selectedSingleFilters[UNREAD] === UNREAD;
			const isBookmarkedFilterSelected = state.selectedSingleFilters[BOOKMARK] === BOOKMARK;

			const isChatRead = !!state.currReadChatsMap[chatId];
			const isChatBookmarked = !!state.currBookmarkChatsMap[chatId];
			const isChatAlreadyReported = !!state.currReportChatsMap[chatId];
			const isChatAlreadyBlocked = !!state.currBlockChatsMap[chatId];

			// chatActionCount is changed for bookmark, report, block and read conditions
			// checking this condition to avoid double increment or decrement

			const shouldUpdateChatActionCount =
				(isUnreadFilterSelected ? !isChatRead : true) &&
				(isBookmarkedFilterSelected ? isChatBookmarked : true) &&
				!isChatAlreadyReported;

			if (shouldUpdateChatActionCount) {
				if (isChatBlocked && !isChatAlreadyBlocked) {
					state.chatActionCount += 1;
				}

				if (!isChatBlocked && isChatAlreadyBlocked) {
					state.chatActionCount -= 1;
				}
			}
		},
		setCurrReadChatsMap(state, action) {
			const chatRead = action.payload.chatRead;
			const chatId = action.payload.chatId;

			state.currReadChatsMap[chatId] = chatRead;
		},
		setCurrBookmarkChatsMap(state, action) {
			const chatBookmarked = action.payload.chatBookmarked;
			const chatId = action.payload.chatId;

			state.currBookmarkChatsMap[chatId] = chatBookmarked;
		},
		setCurrReportChatsMap(state, action) {
			const chatReported = action.payload.chatReported;
			const chatId = action.payload.chatId;
			const inboxChatsDataType = action.payload.inboxChatsDataType as TInboxChatDataType;

			const decrementChatsCount = !state.chatCardActionsMap[chatId];

			const chatsDismissedCount =
				Object.keys(state.chatCardActionsMap).length + Object.keys(state.currReportChatsMap).length;

			if (decrementChatsCount) {
				state.currReportChatsMap[chatId] = chatReported;

				const reportedChatsMapLength = Object.keys(state.currReportChatsMap).length;

				if (inboxChatsDataType === 'INBOX_VIEW') {
					state.chatsDataTotalCount -= 1;

					const chatsData = state.chatsData;
					const paginationDetails = state.paginationDetails;
					const chatsDataShownCount = chatsData.length - chatsDismissedCount;

					const shouldClearChatsData =
						state.chatsDataTotalCount === 0 ||
						(chatsData[chatsData.length - 1]?.chatId === chatId &&
							!paginationDetails?.hasMoreChats &&
							chatsDataShownCount === 1);

					if (shouldClearChatsData) state.chatsData = [];
				}

				if (inboxChatsDataType === 'TAB_OVERVIEW_VIEW') {
					state.tabOverviewChatsDataTotalCount -= 1;

					const tabOverviewChatsData = state.tabOverviewChatsData;
					const tabOverviewPaginationDetails = state.tabOverviewPaginationDetails;
					const tabOverviewChatsDataShownCount = tabOverviewChatsData.length - chatsDismissedCount;

					const shouldClearTabOverviewChatsData =
						state.tabOverviewChatsDataTotalCount === 0 ||
						(tabOverviewChatsData[tabOverviewChatsData.length - 1]?.chatId === chatId &&
							!tabOverviewPaginationDetails?.hasMoreChats &&
							tabOverviewChatsDataShownCount === 1);

					if (shouldClearTabOverviewChatsData) state.tabOverviewChatsData = [];
				}

				if (inboxChatsDataType === 'SEARCH_VIEW') {
					const searchChatsDataLength = state.searchChatsData.length;

					// checking with searchChatsDataLength because searchChatsData not getting totalCount from api
					const shouldClearSearchChatsData = reportedChatsMapLength === searchChatsDataLength;

					if (shouldClearSearchChatsData) state.searchChatsData = [];
				}
			}
		},

		setCurrBlockChatsMap(state, action) {
			const chatBlocked = action.payload.chatBlocked;
			const chatId = action.payload.chatId;
			const inboxChatsDataType = action.payload.inboxChatsDataType as TInboxChatDataType;

			const decrementChatsCount = !state.chatCardActionsMap[chatId];

			const chatsDismissedCount =
				Object.keys(state.chatCardActionsMap).length +
				Object.keys(state.currReportChatsMap).length +
				Object.keys(state.currBlockChatsMap).length;

			if (decrementChatsCount) {
				state.currBlockChatsMap[chatId] = chatBlocked;

				const blockedChatsMapLength = Object.keys(state.currBlockChatsMap).length;

				if (inboxChatsDataType === 'INBOX_VIEW') {
					state.chatsDataTotalCount -= 1;

					const chatsData = state.chatsData;
					const paginationDetails = state.paginationDetails;
					const chatsDataShownCount = chatsData.length - chatsDismissedCount;

					const shouldClearChatsData =
						state.chatsDataTotalCount === 0 ||
						(chatsData[chatsData.length - 1]?.chatId === chatId &&
							!paginationDetails?.hasMoreChats &&
							chatsDataShownCount === 1);

					if (shouldClearChatsData) state.chatsData = [];
				}

				if (inboxChatsDataType === 'TAB_OVERVIEW_VIEW') {
					state.tabOverviewChatsDataTotalCount -= 1;

					const tabOverviewChatsData = state.tabOverviewChatsData;
					const tabOverviewPaginationDetails = state.tabOverviewPaginationDetails;
					const tabOverviewChatsDataShownCount = tabOverviewChatsData.length - chatsDismissedCount;

					const shouldClearTabOverviewChatsData =
						state.tabOverviewChatsDataTotalCount === 0 ||
						(tabOverviewChatsData[tabOverviewChatsData.length - 1]?.chatId === chatId &&
							!tabOverviewPaginationDetails?.hasMoreChats &&
							tabOverviewChatsDataShownCount === 1);

					if (shouldClearTabOverviewChatsData) state.tabOverviewChatsData = [];
				}

				if (inboxChatsDataType === 'SEARCH_VIEW') {
					const searchChatsDataLength = state.searchChatsData.length;

					// checking with searchChatsDataLength because searchChatsData not getting totalCount from api
					const shouldClearSearchChatsData = blockedChatsMapLength === searchChatsDataLength;

					if (shouldClearSearchChatsData) state.searchChatsData = [];
				}
			}
		},
		setCurrAssignedLabelsChatsMap(state, action) {
			const chatId = action.payload.chatId;
			const assignedLabels = action.payload.assignedLabels;

			state.currAssignedLabelsChatsMap[chatId] = assignedLabels;
		},
		addToChatCardActionsMap(state, action) {
			const chatIdToAdd = action.payload.chatId;
			const inboxChatsDataType = action.payload.inboxChatsDataType as TInboxChatDataType;
			const swipeActionDirection = action.payload
				.swipeActionDirection as ILatestChatAction['swipeActionDirection'];

			const decrementChatsCount =
				!state.currReportChatsMap[chatIdToAdd] && !state.currBlockChatsMap[chatIdToAdd];

			const chatsDismissedCount =
				Object.keys(state.chatCardActionsMap).length +
				Object.keys(state.currReportChatsMap).length +
				Object.keys(state.currBlockChatsMap).length;

			if (decrementChatsCount) {
				state.chatCardActionsMap[chatIdToAdd] = {
					chatId: chatIdToAdd,
					swipeActionDirection: swipeActionDirection,
				};

				if (inboxChatsDataType === 'TAB_OVERVIEW_VIEW') {
					state.tabOverviewChatsDataTotalCount -= 1;

					const tabOverviewChatsData = state.tabOverviewChatsData;
					const tabOverviewPaginationDetails = state.tabOverviewPaginationDetails;
					const tabOverviewChatsDataShownCount = tabOverviewChatsData.length - chatsDismissedCount;

					const shouldClearTabOverviewChatsData =
						state.tabOverviewChatsDataTotalCount === 0 ||
						(tabOverviewChatsData[tabOverviewChatsData.length - 1]?.chatId === chatIdToAdd &&
							!tabOverviewPaginationDetails?.hasMoreChats &&
							tabOverviewChatsDataShownCount === 1);

					if (shouldClearTabOverviewChatsData) state.tabOverviewChatsData = [];
				}

				if (inboxChatsDataType === 'INBOX_VIEW') {
					state.chatsDataTotalCount -= 1;

					const chatsData = state.chatsData;
					const paginationDetails = state.paginationDetails;
					const chatsDataShownCount = chatsData.length - chatsDismissedCount;

					const shouldClearChatsData =
						state.chatsDataTotalCount === 0 ||
						(chatsData[chatsData.length - 1]?.chatId === chatIdToAdd &&
							!paginationDetails?.hasMoreChats &&
							chatsDataShownCount === 1);

					if (shouldClearChatsData) state.chatsData = [];
				}
			}
		},
		removeFromChatCardActionsMap(state, action) {
			const chatIdToRemove = action.payload.chatId;
			const inboxChatsDataType = action.payload.inboxChatsDataType as TInboxChatDataType;

			const lastChatActionsCard = action.payload.lastChatActionsCard as SenderChatData;

			const incrementCount =
				!state.currReportChatsMap[chatIdToRemove] && !state.currBlockChatsMap[chatIdToRemove];

			if (incrementCount) {
				delete state.chatCardActionsMap[chatIdToRemove];

				if (inboxChatsDataType === 'TAB_OVERVIEW_VIEW') {
					state.tabOverviewChatsDataTotalCount += 1;

					const tabOverviewChatsData = state.tabOverviewChatsData;

					if (tabOverviewChatsData.length === 0 && lastChatActionsCard)
						state.tabOverviewChatsData.push(lastChatActionsCard);
				}

				if (inboxChatsDataType === 'INBOX_VIEW') {
					state.chatsDataTotalCount += 1;

					const chatsData = state.chatsData;

					if (chatsData.length === 0 && lastChatActionsCard)
						state.chatsData.push(lastChatActionsCard);
				}
			}
		},
		setChatCardSentMessageMap(state, action) {
			const chatId = action.payload.chatId;

			state.chatCardSentMessageMap[chatId] = true;
		},
		setCurrChatsListView(state, action) {
			const listViewToUpdate = action.payload.chatsListView;

			state.currChatsListView = listViewToUpdate;
		},
		clearChatsData(state) {
			state.chatsData = [];
			state.chatsDataTotalCount = 0;
		},
		clearBulkReplyChatsData(state) {
			state.bulkReplyChatsData = initialState.bulkReplyChatsData;
			state.bulkReplyPaginationDetails = initialState.bulkReplyPaginationDetails;
			state.bulkReplyPaginatedFetchStatus = initialState.bulkReplyPaginatedFetchStatus;
			state.bulkReplyPaginatedFetchError = initialState.bulkReplyPaginatedFetchError;
			state.bulkReplyFetchStatus = initialState.bulkReplyFetchStatus;
			state.bulkReplyFetchError = initialState.bulkReplyFetchError;
			state.bulkReplyChatsDataTotalCount = initialState.bulkReplyChatsDataTotalCount;
			state.bulkReplyCurrFilteredOutChatsData = initialState.bulkReplyCurrFilteredOutChatsData;
		},
		clearSearchChatsData(state) {
			state.searchChatsData = initialState.searchChatsData;
			state.searchFetchError = initialState.searchFetchError;
			state.searchFetchStatus = initialState.searchFetchStatus;
			state.searchPaginationDetails = initialState.searchPaginationDetails;
			state.searchPaginatedFetchError = initialState.searchPaginatedFetchError;
			state.searchPaginatedFetchStatus = initialState.searchPaginatedFetchStatus;
		},
		clearTabOverviewChatsData(state) {
			state.tabOverviewChatsData = initialState.tabOverviewChatsData;
			state.tabOverviewPaginationDetails = initialState.tabOverviewPaginationDetails;
			state.tabOverviewPaginatedFetchStatus = initialState.tabOverviewPaginatedFetchStatus;
			state.tabOverviewPaginatedFetchError = initialState.tabOverviewPaginatedFetchError;
			state.tabOverviewFetchStatus = initialState.tabOverviewFetchStatus;
			state.tabOverviewFetchError = initialState.tabOverviewFetchError;
		},
		clearInboxState() {
			return initialState;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchInboxData.pending, (state, action) => {
				state.fetchStatus = 'loading';
				state.currChatsDataSelectedChatId = null;
				state.currChatsDataSelectedChatIndex = null;
				state.currChatsDataSelectedChatInboxType = null;
				state.chatActionCount = 0;
				state.currReadChatsMap = {};
				state.currBookmarkChatsMap = {};
				state.chatCardActionsMap = {};
				state.chatCardSentMessageMap = {};
				state.currReportChatsMap = {};
				state.currBlockChatsMap = {};
				state.currAssignedLabelsChatsMap = {};
				state.currFilteredOutChatsData = [];
				state.chatsData = [];
				state.chatsDataTotalCount = 0;
				state.archivedChatsDataTotalCount = 0;
				state.paginationDetails = null;
				state.tapToRefresh = false;
				state.currFetchRequestId = action.meta.requestId;
				state.currFetchRequestTimestamp = new Date().toISOString();
			})
			.addCase(fetchInboxData.fulfilled, (state, action) => {
				const requestId = action.meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					const newFilters = action.payload?.filters ?? [];
					const chatsData = action.payload?.chats ?? [];
					const paginationDetails = action.payload?.pageDetails ?? null;
					const totalCount = action.payload?.totalCount ?? 0;
					const archivedChatsDataTotalCount = action.payload?.archivedCount ?? 0;

					state.fetchStatus = 'success';
					state.chatsData = chatsData;
					state.chatsDataTotalCount = totalCount;
					state.archivedChatsDataTotalCount = archivedChatsDataTotalCount;
					state.tapToRefresh = chatsData.length > 0;
					newFilters.forEach((newFilter) => {
						const filterIndex = state.filters.findIndex((filter) => {
							return filter.filterName === newFilter.filterName;
						});
						if (filterIndex === -1) {
							state.filters.push(newFilter);
						} else {
							state.filters[filterIndex] = newFilter;
						}
					});
					state.paginationDetails = paginationDetails ?? null;
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchInboxData.rejected, (state, { error, meta }) => {
				const requestId = meta.requestId;
				const currRequestId = state.currFetchRequestId;
				if (requestId === currRequestId) {
					state.fetchStatus = 'error';
					state.fetchError = error.message ?? 'There was some problem while fetching inbox data...';
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchPaginatedInboxData.pending, (state, action) => {
				state.paginatedFetchStatus = 'loading';
				state.currFetchRequestId = action.meta.requestId;
			})
			.addCase(fetchPaginatedInboxData.fulfilled, (state, action) => {
				const requestId = action.meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					const chatsData = action.payload?.chats ?? [];
					const paginationDetails = action.payload?.pageDetails ?? null;

					const chatsDataToInclude: SenderChatData[] = [];
					const chatsDataToExclude: SenderChatData[] = [];

					chatsData.forEach((chatData) => {
						const shouldIncludeChatData =
							chatData.chatId &&
							!(chatData.chatId in state.chatCardActionsMap) &&
							!(chatData.chatId in state.currBookmarkChatsMap) &&
							!(chatData.chatId in state.currReadChatsMap) &&
							!(chatData.chatId in state.currReportChatsMap) &&
							!(chatData.chatId in state.currBlockChatsMap);

						if (shouldIncludeChatData) {
							chatsDataToInclude.push(chatData);
						} else {
							chatsDataToExclude.push(chatData);
						}
					});

					state.paginatedFetchStatus = 'idle';
					state.chatsData = [...state.chatsData, ...chatsDataToInclude];
					state.currFilteredOutChatsData = [
						...state.currFilteredOutChatsData,
						...chatsDataToExclude,
					];
					state.paginationDetails = paginationDetails;
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchPaginatedInboxData.rejected, (state, { error, meta }) => {
				const requestId = meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					state.paginatedFetchStatus = 'idle';
					state.paginatedFetchError =
						error.message ?? 'There was some problem while fetching inbox data...';
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchBulkReplyInboxData.pending, (state, action) => {
				state.bulkReplyFetchStatus = 'loading';
				state.bulkReplyChatsData = [];
				state.bulkReplyChatsDataTotalCount = 0;
				state.bulkReplyPaginationDetails = null;
				state.bulkReplyCurrFilteredOutChatsData = [];
				state.currFetchRequestId = action.meta.requestId;
			})
			.addCase(fetchBulkReplyInboxData.fulfilled, (state, action) => {
				const requestId = action.meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					const chatsData = action.payload?.chats ?? [];
					const paginationDetails = action.payload?.pageDetails ?? null;
					const totalCount = action.payload?.totalCount ?? 0;

					state.bulkReplyFetchStatus = 'success';
					state.bulkReplyChatsData = chatsData;
					state.bulkReplyChatsDataTotalCount = totalCount;
					state.bulkReplyPaginationDetails = paginationDetails ?? null;
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchBulkReplyInboxData.rejected, (state, { error, meta }) => {
				const requestId = meta.requestId;
				const currRequestId = state.currFetchRequestId;
				if (requestId === currRequestId) {
					state.bulkReplyFetchStatus = 'error';
					state.bulkReplyFetchError =
						error.message ?? 'There was some problem while fetching inbox data...';
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchPaginatedBulkReplyInboxData.pending, (state, action) => {
				state.bulkReplyPaginatedFetchStatus = 'loading';
				state.currFetchRequestId = action.meta.requestId;
			})
			.addCase(fetchPaginatedBulkReplyInboxData.fulfilled, (state, action) => {
				const requestId = action.meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					const chatsData = action.payload?.chats ?? [];
					const paginationDetails = action.payload?.pageDetails ?? null;

					const chatsDataToInclude: SenderChatData[] = [];
					const chatsDataToExclude: SenderChatData[] = [];

					chatsData.forEach((chatData) => {
						const shouldIncludeChatData =
							chatData.chatId &&
							!(chatData.chatId in state.chatCardActionsMap) &&
							!(chatData.chatId in state.currBookmarkChatsMap) &&
							!(chatData.chatId in state.currReadChatsMap) &&
							!(chatData.chatId in state.currReportChatsMap) &&
							!(chatData.chatId in state.currBlockChatsMap);

						if (shouldIncludeChatData) {
							chatsDataToInclude.push(chatData);
						} else {
							chatsDataToExclude.push(chatData);
						}
					});

					state.bulkReplyPaginatedFetchStatus = 'idle';
					state.bulkReplyChatsData = [...state.bulkReplyChatsData, ...chatsDataToInclude];
					state.bulkReplyCurrFilteredOutChatsData = [
						...state.bulkReplyCurrFilteredOutChatsData,
						...chatsDataToExclude,
					];
					state.bulkReplyPaginationDetails = paginationDetails;
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchPaginatedBulkReplyInboxData.rejected, (state, { error, meta }) => {
				const requestId = meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					state.bulkReplyPaginatedFetchStatus = 'idle';
					state.bulkReplyPaginatedFetchError =
						error.message ?? 'There was some problem while fetching inbox data...';
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchInboxSearchData.pending, (state, action) => {
				state.searchFetchStatus = 'loading';
				state.currChatsDataSelectedChatId = null;
				state.currChatsDataSelectedChatIndex = null;
				state.currChatsDataSelectedChatInboxType = null;
				state.chatActionCount = 0;
				state.currReadChatsMap = {};
				state.currBookmarkChatsMap = {};
				state.chatCardActionsMap = {};
				state.chatCardSentMessageMap = {};
				state.currReportChatsMap = {};
				state.currBlockChatsMap = {};
				state.currAssignedLabelsChatsMap = {};
				state.currFilteredOutChatsData = [];
				state.searchChatsData = [];
				state.searchPaginationDetails = null;
				state.currFetchRequestId = action.meta.requestId;
				state.currFetchRequestTimestamp = new Date().toISOString();
			})
			.addCase(fetchInboxSearchData.fulfilled, (state, action) => {
				const requestId = action.meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					const searchData = action.payload.searchData;
					const searchStr = action.payload.searchStr;
					const chatsData = searchData?.chats ?? [];
					const paginationDetails = searchData?.pageDetails ?? null;
					const searchQueryDetails = searchData?.searchQuery ?? null;

					const searchStrExist = state.searchTerms.find((searchTerm) => searchTerm === searchStr);

					if (searchStrExist) {
						state.searchTermsQueryMap[searchStr] = searchQueryDetails;
					} else {
						if (state.searchTerms.length >= 5) {
							// remove first search term
							const removedSearchTerm = state.searchTerms.shift();
							removedSearchTerm && delete state.searchTermsQueryMap[removedSearchTerm];
						}

						//add new search term
						state.searchTerms.push(searchStr);
						state.searchTermsQueryMap[searchStr] = searchQueryDetails;
					}

					state.searchFetchStatus = 'success';
					state.searchChatsData = chatsData;
					state.searchPaginationDetails = paginationDetails ?? null;
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchInboxSearchData.rejected, (state, { error, meta }) => {
				const requestId = meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					state.searchFetchStatus = 'error';
					state.searchFetchError =
						error.message ?? 'There was some problem while fetching inbox search data...';
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchPaginatedInboxSearchData.pending, (state, action) => {
				state.searchPaginatedFetchStatus = 'loading';
				state.currFetchRequestId = action.meta.requestId;
			})
			.addCase(fetchPaginatedInboxSearchData.fulfilled, (state, action) => {
				const requestId = action.meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					const searchData = action.payload.searchData;
					const searchStr = action.payload.searchStr;
					const chatsData = searchData?.chats ?? [];
					const paginationDetails = searchData?.pageDetails ?? null;
					const searchQueryDetails = searchData?.searchQuery ?? null;

					const chatsDataToInclude: SenderChatData[] = [];
					const chatsDataToExclude: SenderChatData[] = [];

					chatsData.forEach((chatData) => {
						const shouldIncludeChatData =
							chatData.chatId &&
							!(chatData.chatId in state.chatCardActionsMap) &&
							!(chatData.chatId in state.currBookmarkChatsMap) &&
							!(chatData.chatId in state.currReadChatsMap) &&
							!(chatData.chatId in state.currReportChatsMap) &&
							!(chatData.chatId in state.currBlockChatsMap);

						if (shouldIncludeChatData) {
							chatsDataToInclude.push(chatData);
						} else {
							chatsDataToExclude.push(chatData);
						}
					});

					state.searchPaginatedFetchStatus = 'idle';
					state.searchChatsData = [...state.searchChatsData, ...chatsDataToInclude];
					state.currFilteredOutChatsData = [
						...state.currFilteredOutChatsData,
						...chatsDataToExclude,
					];
					state.searchPaginationDetails = paginationDetails;

					state.searchTermsQueryMap[searchStr] = searchQueryDetails;
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchPaginatedInboxSearchData.rejected, (state, { error, meta }) => {
				const requestId = meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					state.searchPaginatedFetchStatus = 'idle';
					state.searchPaginatedFetchError =
						error.message ?? 'There was some problem while fetching inbox search data...';
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchTabOverviewInboxData.pending, (state, action) => {
				state.tabOverviewFetchStatus = 'loading';
				state.currChatsDataSelectedChatId = null;
				state.currChatsDataSelectedChatIndex = null;
				state.currChatsDataSelectedChatInboxType = null;
				state.chatActionCount = 0;
				state.currReadChatsMap = {};
				state.currBookmarkChatsMap = {};
				state.chatCardActionsMap = {};
				state.chatCardSentMessageMap = {};
				state.currFilteredOutChatsData = [];
				state.currReportChatsMap = {};
				state.currBlockChatsMap = {};
				state.currAssignedLabelsChatsMap = {};
				state.tabOverviewChatsData = [];
				state.tabOverviewChatsDataTotalCount = 0;
				state.tabOverviewPaginationDetails = null;
				state.currFetchRequestId = action.meta.requestId;
				state.currFetchRequestTimestamp = new Date().toISOString();
			})
			.addCase(fetchTabOverviewInboxData.fulfilled, (state, action) => {
				const requestId = action.meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					const newFilters = action.payload?.filters ?? [];
					const chatsData = action.payload?.chats ?? [];
					const paginationDetails = action.payload?.pageDetails ?? null;
					const totalCount = action.payload?.totalCount ?? 0;

					state.tabOverviewFetchStatus = 'success';
					state.tabOverviewChatsData = chatsData;
					state.tabOverviewChatsDataTotalCount = totalCount;
					newFilters.forEach((newFilter) => {
						const filterIndex = state.tabOverviewFilters.findIndex((filter) => {
							return filter.filterName === newFilter.filterName;
						});
						if (filterIndex === -1) {
							state.tabOverviewFilters.push(newFilter);
						} else {
							state.tabOverviewFilters[filterIndex] = newFilter;
						}
					});
					state.tabOverviewPaginationDetails = paginationDetails ?? null;
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchTabOverviewInboxData.rejected, (state, { error, meta }) => {
				const requestId = meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					state.tabOverviewFetchStatus = 'error';
					state.tabOverviewFetchError =
						error.message ?? 'There was some problem while fetching tab inbox data...';
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchPaginatedTabOverviewInboxData.pending, (state, action) => {
				state.tabOverviewPaginatedFetchStatus = 'loading';
				state.currFetchRequestId = action.meta.requestId;
			})
			.addCase(fetchPaginatedTabOverviewInboxData.fulfilled, (state, action) => {
				const requestId = action.meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					const chatsData = action.payload?.chats ?? [];
					const paginationDetails = action.payload?.pageDetails ?? null;

					const chatsDataToInclude: SenderChatData[] = [];
					const chatsDataToExclude: SenderChatData[] = [];

					chatsData.forEach((chatData) => {
						const shouldIncludeChatData =
							chatData.chatId &&
							!(chatData.chatId in state.chatCardActionsMap) &&
							!(chatData.chatId in state.currBookmarkChatsMap) &&
							!(chatData.chatId in state.currReadChatsMap) &&
							!(chatData.chatId in state.currReportChatsMap) &&
							!(chatData.chatId in state.currBlockChatsMap);

						if (shouldIncludeChatData) {
							chatsDataToInclude.push(chatData);
						} else {
							chatsDataToExclude.push(chatData);
						}
					});

					state.tabOverviewPaginatedFetchStatus = 'idle';
					state.tabOverviewChatsData = [...state.tabOverviewChatsData, ...chatsDataToInclude];
					state.currFilteredOutChatsData = [
						...state.currFilteredOutChatsData,
						...chatsDataToExclude,
					];
					state.tabOverviewPaginationDetails = paginationDetails;
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchPaginatedTabOverviewInboxData.rejected, (state, { error, meta }) => {
				const requestId = meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					state.tabOverviewPaginatedFetchStatus = 'idle';
					state.tabOverviewPaginatedFetchError =
						error.message ?? 'There was some problem while fetching tab inbox data...';
					state.currFetchRequestId = null;
				}
			});
	},
});

export const {
	clearInboxState,
	setMainFilters,
	setSelectedMainFilter,
	setSelectedFilters,
	setAdvancedFilterOptions,
	setTabOverviewDetails,
	setBookmarkChatActionCount,
	setReadChatActionCount,
	setReportChatActionCount,
	setBlockChatActionCount,
	setCurrReportChatsMap,
	setCurrBlockChatsMap,
	setCurrReadChatsMap,
	setCurrBookmarkChatsMap,
	setCurrAssignedLabelsChatsMap,
	setCurrChatsDataSelectedChatId,
	setCurrChatsDataSelectedChatVisited,
	setCurrChatsListView,
	setNewMainFilterOverviewDetails,
	setTabOverviewArchiveDetails,
	addToChatCardActionsMap,
	removeFromChatCardActionsMap,
	setChatCardSentMessageMap,
	clearChatsData,
	clearTabOverviewChatsData,
	clearSearchChatsData,
	clearBulkReplyChatsData,
} = inboxSlice.actions;

export const getInboxDataFromGlobalState = (state: RootState) => state.inbox;

export default inboxSlice.reducer;
