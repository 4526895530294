/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo } from 'react';
import { IInboxLayoutContext, IInboxLayoutProviderProps } from './InboxLayoutProvider.types';
import { AppDispatch } from '@store/index';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
	ADVANCED,
	ARCHIVED,
	BOOKMARK,
	CHAT_SEARCH_PARAM,
	EXPERIENCE_MAX,
	EXPERIENCE_MIN,
	INBOX_TAB_SEARCH_PARAM,
	READ,
	REPLIED,
	SORT,
	TIME_PERIOD,
	UNREAD,
	UNREAD_FILTER_OPTIONS_DEFAULT_SORT_OPTION_MAPPINGS,
	allMainFiltersArr,
} from '@utils/constants';
import {
	clearTabOverviewChatsData,
	fetchInboxData,
	getAllFiltersFromPreAppliedFilters,
	getFiltersObj,
	getInboxDataFromGlobalState,
	initialState,
	setAdvancedFilterOptions,
	setCurrChatsDataSelectedChatId,
	setCurrChatsDataSelectedChatVisited,
	setNewMainFilterOverviewDetails,
	setSelectedFilters,
	setSelectedMainFilter,
	setTabOverviewDetails,
} from '@store/inboxSlice/inboxSlice';
import {
	getUserDetailsFromGlobalState,
	setUserDeviceInformation,
} from '@store/userDetailsSlice/userDetailsSlice';
import {
	clearSelectedChat,
	getSelectedChatDetailsFromGlobalState,
} from '@store/selectedChatSlice/selectedChatSlice';
import { getInboxOverviewDetails } from '@api/user';
import useWindowSize from '@hooks/useWindow';
import useFetch from '@hooks/useFetch';
import { getTabOverview } from '@api/chats';
import { Tabs, isProUserFunc } from '@src/models/user';
import {
	areURLSearchParamsEqual,
	deepEqualFilters,
	getAllFiltersFromParams,
	getValidUpdatedSearchToUpdate,
	isPWAApp,
	isSuperDMApp,
	removeKeysFromAppliedFiltersObject,
} from '@utils/common';
import { IAppliedFiltersPayload, IGetTabOverviewPayload } from '@api/chats/chats.types';
import { IGetInboxOverviewPayload } from '@api/user/user.types';
import { IRNMessageEventData } from '@components/RootLayout/RootLayout.types';
import { getServiceWorkerInstance } from '@src/serviceWorkerRegistration';
import { requestForToken } from '@config/firebase';

export const InboxLayoutContext = React.createContext<IInboxLayoutContext>({
	categoryFiltersFromInboxOverview: [],
	filtersWithOptions: [],
	isLoadingData: false,
	isFiltersChanged: false,
	mainFilters: [],
	onClickMainFilter: () => {},
	onClickMultiFilter: () => {},
	onClickSingleFilter: () => {},
	onClickOverviewFilter: () => {},
	onClickOverviewFilterDesktop: () => {},
	savedFiltersFromInboxOverview: [],
	isTabDefaultFiltersChanged: false,
	selectedChatUsernameFromURL: '',
	isProUser: false,
});

export const InboxLayoutProvider = ({ children }: IInboxLayoutProviderProps) => {
	const { isMobile } = useWindowSize();
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();

	const selectedChatUsernameFromURL = decodeURIComponent(searchParams.get(CHAT_SEARCH_PARAM) ?? '');
	const selectedInboxTabNameFromURL = decodeURIComponent(
		searchParams.get(INBOX_TAB_SEARCH_PARAM) ?? ''
	);

	const {
		mainFilters,
		selectedMainFilter,
		filters,
		selectedMultiFilters,
		selectedSingleFilters,
		chatsData,
		fetchStatus,
		currChatsDataSelectedChatId,
		currChatsDataSelectedChatInboxType,
		currChatDataSelectedChatVisited,
		currChatsListView,
	} = useSelector(getInboxDataFromGlobalState);

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const { selectedChat } = useSelector(getSelectedChatDetailsFromGlobalState);

	const {
		callApi: callInboxOverviewDetails,
		status: callInboxOverviewDetailsStatus,
		response: callInboxOverviewDetailsResponse,
	} = useFetch(getInboxOverviewDetails);

	const { callApi: callGetTabOverview, status: callGetTabOverviewStatus } =
		useFetch(getTabOverview);

	const savedFiltersFromInboxOverview = useMemo(() => {
		const savedFilters = callInboxOverviewDetailsResponse?.formattedData?.savedFilters ?? [];

		if (!savedFilters) return [];

		return savedFilters;
	}, [callInboxOverviewDetailsResponse]);

	const categoryFiltersFromInboxOverview = useMemo(() => {
		const categories = callInboxOverviewDetailsResponse?.formattedData?.categories ?? [];

		if (!categories) return [];

		return categories;
	}, [callInboxOverviewDetailsResponse]);

	const isLoadingData =
		(!selectedChatUsernameFromURL && fetchStatus === 'idle') ||
		fetchStatus === 'loading' ||
		callInboxOverviewDetailsStatus === 'loading' ||
		callGetTabOverviewStatus === 'loading' ||
		(!!selectedChatUsernameFromURL && !selectedChat);

	const isChatsDataEmpty = !isLoadingData && chatsData.length === 0;

	const isProUser = isProUserFunc(userDetails) ?? false;
	const userId = userDetails?.userId;

	const filtersWithOptions = useMemo(() => {
		if (!filters.length || !isProUser) return [];

		return filters.filter((eachFilter) => {
			const hasFilterOptions =
				Object.keys(eachFilter.options ?? {}).length > 0 && eachFilter.filterName !== ADVANCED;
			let shouldIncludeFilter = isChatsDataEmpty
				? !!selectedSingleFilters[eachFilter.filterName] ||
				  selectedMultiFilters[eachFilter.filterName]?.length > 0
				: true;

			if (eachFilter.filterName === UNREAD) {
				shouldIncludeFilter = !!selectedMainFilter?.unreadFilterRequired;
			}

			if (eachFilter.filterName === SORT) {
				shouldIncludeFilter = !!chatsData.length;
			}

			if (eachFilter.filterName === TIME_PERIOD) {
				shouldIncludeFilter = !!selectedMainFilter?.showTimePeriodFilter;
			}

			return hasFilterOptions && shouldIncludeFilter;
		});
	}, [filters, isChatsDataEmpty, isProUser]);

	const mainFiltersToDisplay = useMemo(() => {
		if (!mainFilters || !isProUser) return [];
		return mainFilters;
	}, [mainFilters, isProUser]);

	const isTabDefaultFiltersChanged = useMemo(() => {
		if (!isProUser) return false;

		const filtersObjFromSelectedFilters = getFiltersObj(
			selectedSingleFilters,
			selectedMultiFilters,
			filters
		);

		// check if it is in tab default filters state
		const tabDefaultFilters = selectedMainFilter?.preAppliedFilters
			? { ...selectedMainFilter?.preAppliedFilters }
			: {};

		// manipulating selected filters status, read, replied, bookmark, time period
		// as the filters are not changed in UI to detect the filters change
		const keysToBeRemoved = [
			UNREAD,
			ARCHIVED,
			BOOKMARK,
			READ,
			REPLIED,
			TIME_PERIOD,
			ADVANCED,
			SORT,
		];

		const updatedFiltersObjFromTabDefaultFilters = removeKeysFromAppliedFiltersObject(
			tabDefaultFilters,
			keysToBeRemoved
		);

		const updatedFiltersObjFromSelectedFilters = removeKeysFromAppliedFiltersObject(
			filtersObjFromSelectedFilters,
			keysToBeRemoved
		);

		const isTabDefaultFiltersState = deepEqualFilters(
			updatedFiltersObjFromSelectedFilters,
			updatedFiltersObjFromTabDefaultFilters
		);

		if (isTabDefaultFiltersState) return false;

		return true;
	}, [selectedMainFilter, selectedSingleFilters, selectedMultiFilters, filters, isProUser]);

	const isFiltersChanged = useMemo(() => {
		if (!isProUser) return false;

		const filtersObjFromSelectedFilters = getFiltersObj(
			selectedSingleFilters,
			selectedMultiFilters,
			filters
		);

		const filtersObjFromInitialFiltersState = getFiltersObj(
			initialState.selectedSingleFilters,
			initialState.selectedMultiFilters,
			filters
		);

		// check if it is in default initial filters state
		const isDefaultInitialFiltersState = deepEqualFilters(
			filtersObjFromSelectedFilters,
			filtersObjFromInitialFiltersState
		);

		if (isDefaultInitialFiltersState) return false;

		// check if it is in tab default filters state
		if (!isTabDefaultFiltersChanged) return false;

		// manipulating selected filters status, read, replied, bookmark, time period
		// as the filters are not changed in UI to detect the filters change
		const keysToBeRemoved = [
			UNREAD,
			ARCHIVED,
			BOOKMARK,
			READ,
			REPLIED,
			TIME_PERIOD,
			ADVANCED,
			SORT,
		];

		const updatedFiltersObjFromSelectedFilters = removeKeysFromAppliedFiltersObject(
			filtersObjFromSelectedFilters,
			keysToBeRemoved
		);

		// check if it is in tab overview saved filters state
		let tabOverviewSavedFiltersState = false;
		const tabOverviewSavedFiltersArr = savedFiltersFromInboxOverview;

		for (const savedFilter of tabOverviewSavedFiltersArr) {
			const savedFilterObj = removeKeysFromAppliedFiltersObject(
				savedFilter.appliedFilters ?? {},
				keysToBeRemoved
			);

			if (deepEqualFilters(updatedFiltersObjFromSelectedFilters, savedFilterObj)) {
				tabOverviewSavedFiltersState = true;
				break;
			}
		}

		if (tabOverviewSavedFiltersState) return false;

		//check if it is in tab overview default category filters state
		let tabOverviewCategoryFiltersState = false;
		const tabOverviewCategoryFiltersArr = categoryFiltersFromInboxOverview;

		for (const categoryFilter of tabOverviewCategoryFiltersArr) {
			const categoryFilterObj = removeKeysFromAppliedFiltersObject(
				categoryFilter.appliedFilters ?? {},
				keysToBeRemoved
			);

			if (deepEqualFilters(updatedFiltersObjFromSelectedFilters, categoryFilterObj)) {
				tabOverviewCategoryFiltersState = true;
				break;
			}
		}

		if (tabOverviewCategoryFiltersState) return false;

		return true;
	}, [
		selectedMultiFilters,
		selectedSingleFilters,
		savedFiltersFromInboxOverview,
		categoryFiltersFromInboxOverview,
		isTabDefaultFiltersChanged,
		filters,
		isProUser,
	]);

	const handleOnClickMainFilter = async (mainFilterToSelect: Tabs) => {
		const searchParamsToUpdate = new URLSearchParams();
		searchParamsToUpdate.set(INBOX_TAB_SEARCH_PARAM, encodeURIComponent(mainFilterToSelect.name));

		setSearchParams(searchParamsToUpdate, { replace: !!selectedChatUsernameFromURL });
	};

	const handleOnClickMultiFilter = (filterName: string, updatedOptionsIds: string[]) => {
		dispatch(setCurrChatsDataSelectedChatId({}));
		setSearchParams(
			(prevParams) => {
				const searchParamsToUpdate = new URLSearchParams(prevParams);
				searchParamsToUpdate.delete(CHAT_SEARCH_PARAM);
				updatedOptionsIds.length > 0
					? searchParamsToUpdate.set(
							filterName,
							updatedOptionsIds.map((optionId) => encodeURIComponent(optionId)).join(',')
					  )
					: searchParamsToUpdate.delete(filterName);
				return searchParamsToUpdate;
			},
			{ replace: !!selectedChatUsernameFromURL }
		);
	};

	const handleOnClickSingleFilter = (filterName: string, optionId: string, isSelected: boolean) => {
		const minExpValue = selectedSingleFilters[EXPERIENCE_MIN]
			? Number(selectedSingleFilters[EXPERIENCE_MIN])
			: null;
		const maxExpValue = selectedSingleFilters[EXPERIENCE_MAX]
			? Number(selectedSingleFilters[EXPERIENCE_MAX])
			: null;

		if (filterName === EXPERIENCE_MAX && minExpValue && minExpValue > Number(optionId)) {
			return;
		}

		if (filterName === EXPERIENCE_MIN && maxExpValue && Number(optionId) > maxExpValue) {
			return;
		}

		dispatch(setCurrChatsDataSelectedChatId({}));

		setSearchParams(
			(prevParams) => {
				const searchParamsToUpdate = new URLSearchParams(prevParams);
				searchParamsToUpdate.delete(CHAT_SEARCH_PARAM);
				isSelected
					? searchParamsToUpdate.set(filterName, encodeURIComponent(optionId))
					: searchParamsToUpdate.delete(filterName);

				// handling when unread filter is changed
				const isUnreadFilterChanged = filterName === UNREAD;

				if (isUnreadFilterChanged) {
					searchParamsToUpdate.set(
						SORT,
						encodeURIComponent(UNREAD_FILTER_OPTIONS_DEFAULT_SORT_OPTION_MAPPINGS[optionId])
					);
				}

				return searchParamsToUpdate;
			},
			{ replace: !!selectedChatUsernameFromURL }
		);
	};

	const handleOnClickOverviewFilter: IInboxLayoutContext['onClickOverviewFilter'] = (
		preAppliedFiltersObj
	) => {
		dispatch(setCurrChatsDataSelectedChatId({}));
		setSearchParams(
			(prevParams) => {
				const searchParamsToUpdate = new URLSearchParams(prevParams);
				searchParamsToUpdate.delete(CHAT_SEARCH_PARAM);

				const {
					selectedSingleFilters: updatedSelectedSingleFilters,
					selectedMultiFilters: updatedSelectedMultiFilter,
				} = getAllFiltersFromPreAppliedFilters({
					...getFiltersObj(selectedSingleFilters, selectedMultiFilters, filters),
					...preAppliedFiltersObj,
				});

				Object.entries(updatedSelectedSingleFilters).forEach(([filterName, filterValue]) => {
					filterValue
						? searchParamsToUpdate.set(filterName, encodeURIComponent(filterValue))
						: searchParamsToUpdate.delete(filterName);
				});

				Object.entries(updatedSelectedMultiFilter).forEach(([filterName, filterValue]) => {
					filterValue.length > 0
						? searchParamsToUpdate.set(
								filterName,
								filterValue.map((value) => encodeURIComponent(value)).join(',')
						  )
						: searchParamsToUpdate.delete(filterName);
				});

				return searchParamsToUpdate;
			},
			{ replace: !!selectedChatUsernameFromURL }
		);
	};

	const handleOverviewFilterClickDesktop = (
		preAppliedFilters: IAppliedFiltersPayload,
		filterName: string
	) => {
		if (!selectedMainFilter) return;
		dispatch(clearTabOverviewChatsData());
		dispatch(
			setTabOverviewDetails({
				preAppliedFilters: {
					...getFiltersObj(selectedSingleFilters, selectedMultiFilters, filters),
					...preAppliedFilters,
				},
				filterName: filterName,
			})
		);
		navigate(`/${selectedMainFilter.name.toLocaleLowerCase()}/overview`);
	};

	const updatedSearchParamsState = useMemo(() => {
		const searchParamsToUpdate = getValidUpdatedSearchToUpdate(searchParams, isProUser);

		return {
			searchParamsToUpdate,
			areURLSearchParamsValid: areURLSearchParamsEqual(searchParams, searchParamsToUpdate),
		};
	}, [isProUser, searchParams]);

	// handling search params
	useEffect(() => {
		(async function () {
			if (!updatedSearchParamsState.areURLSearchParamsValid) {
				setSearchParams(updatedSearchParamsState.searchParamsToUpdate, {
					replace: true,
				});
				return;
			}

			!selectedChatUsernameFromURL && !!selectedChat && dispatch(clearSelectedChat());

			if (!selectedInboxTabNameFromURL || selectedChatUsernameFromURL) return;

			if (
				selectedMainFilter?.name === selectedInboxTabNameFromURL &&
				currChatsDataSelectedChatId &&
				currChatsDataSelectedChatInboxType === 'INBOX_VIEW' &&
				currChatDataSelectedChatVisited
			) {
				dispatch(setCurrChatsDataSelectedChatVisited({ isVisited: false }));
				return;
			}

			const mainFilterToSelect = [...allMainFiltersArr].find((mainFilter) => {
				return mainFilter.name === selectedInboxTabNameFromURL;
			});

			if (!mainFilterToSelect) return;

			dispatch(setSelectedMainFilter({ selectedMainFilter: mainFilterToSelect }));
			const preAppliedFilters = mainFilterToSelect.preAppliedFilters;

			if (preAppliedFilters) {
				const { selectedSingleFilters, selectedMultiFilters } =
					getAllFiltersFromPreAppliedFilters(preAppliedFilters);
				const { multiSelectFiltersFromParams, singleSelectFiltersFromParams } =
					getAllFiltersFromParams(searchParams);

				const singleSelectedFiltersToUpdate = {
					...selectedSingleFilters,
					...singleSelectFiltersFromParams,
				};

				const multiSelectedFiltersToUpdate = {
					...selectedMultiFilters,
					...multiSelectFiltersFromParams,
				};

				dispatch(
					setAdvancedFilterOptions({
						preAppliedFilters: preAppliedFilters,
						inboxType: 'INBOX_VIEW',
					})
				);

				dispatch(
					setSelectedFilters({
						singleSelectedFilters: singleSelectedFiltersToUpdate,
						multiSelectedFilters: multiSelectedFiltersToUpdate,
						inboxType: 'INBOX_VIEW',
						reArrangeFilters: isMobile,
					})
				);
			}

			const archiveCountRequired =
				!!mainFilterToSelect?.archiveCountRequired && !!userDetails?.archivalEnabled;

			dispatch(
				fetchInboxData({
					filterReloadRequired: isProUser,
					archivedCountRequired: archiveCountRequired,
					lastFiveMessagesRequired:
						selectedSingleFilters[UNREAD] === UNREAD &&
						currChatsListView === 'SWIPE_CARD' &&
						isMobile,
					topPicksThreshold: userDetails?.topPicksThreshold,
				})
			);
		})();
	}, [updatedSearchParamsState]);

	// handle calling tab overview details
	useEffect(() => {
		const canCallTabOverviewDetails =
			!!selectedMainFilter?.overviewEnabled &&
			currChatsDataSelectedChatInboxType !== 'INBOX_VIEW' &&
			!selectedChatUsernameFromURL &&
			!isTabDefaultFiltersChanged;

		if (!canCallTabOverviewDetails) return;

		const filterToCheck = selectedMainFilter;

		const appliedFilters = getFiltersObj(selectedSingleFilters, selectedMultiFilters, filters);
		const filterName = filterToCheck.name;

		let abortControllerInboxOverview: AbortController;
		let abortControllerTabOverview: AbortController;

		(async function () {
			try {
				// fetch for overview if overview enabled for user

				if (!userDetails?.userId) return;

				const userId = userDetails.userId;

				const payload: IGetInboxOverviewPayload = {
					user_id: userId,
				};

				abortControllerInboxOverview = new AbortController();

				const overviewDetails = await callInboxOverviewDetails(
					payload,
					abortControllerInboxOverview.signal
				);
				const overviewData = overviewDetails.data;

				// fetch for tab overview if overview details available
				if (!overviewData) return;

				const tabOverviewPayload: IGetTabOverviewPayload = {
					overview: overviewData,
					user_id: userDetails.userId,
					tab: {
						name: filterName,
						pre_applied_filter: appliedFilters,
					},
				};

				abortControllerTabOverview = new AbortController();

				const tabOverviewDetails = await callGetTabOverview(
					tabOverviewPayload,
					abortControllerTabOverview.signal
				);

				dispatch(
					setNewMainFilterOverviewDetails({ newMainFilterOverviewDetails: tabOverviewDetails })
				);
			} catch (error) {
				// handle handle tab overview call error
			}
		})();

		return () => {
			if (abortControllerInboxOverview) abortControllerInboxOverview.abort();
			if (abortControllerTabOverview) abortControllerTabOverview.abort();
		};
	}, [selectedMainFilter, selectedSingleFilters, selectedMultiFilters]);

	// handling notifications permission for pwa and SuperDM app
	useEffect(() => {
		if (!userId) return;

		const userNotificationsAllowedDevices = userDetails?.devicesInfo ?? [];
		const isPWAMobileApp = isPWAApp();
		const isSuperDMMobileApp = isSuperDMApp();

		if (isSuperDMMobileApp) {
			if (window.ReactNativeWebView) {
				const eventData: IRNMessageEventData = {
					eventType: 'REQUEST_DEVICE_TOKEN',
				};
				window.ReactNativeWebView.postMessage(JSON.stringify(eventData));
			}
			return;
		}

		if (!isPWAMobileApp) return;

		(async function () {
			const { instance, registered } = await getServiceWorkerInstance();

			if (registered && instance) {
				await requestForToken(
					userId,
					instance,
					userNotificationsAllowedDevices,
					(updatedAllowedNotificationsDevicesInfo) => {
						dispatch(
							setUserDeviceInformation({
								updatedDevicesInfo: updatedAllowedNotificationsDevicesInfo,
							})
						);
					}
				);
			}
		})();
	}, [userId]);

	return (
		<InboxLayoutContext.Provider
			value={{
				isLoadingData: isLoadingData,
				onClickSingleFilter: handleOnClickSingleFilter,
				onClickMultiFilter: handleOnClickMultiFilter,
				onClickMainFilter: handleOnClickMainFilter,
				onClickOverviewFilter: handleOnClickOverviewFilter,
				onClickOverviewFilterDesktop: handleOverviewFilterClickDesktop,
				isFiltersChanged: isFiltersChanged,
				filtersWithOptions: filtersWithOptions,
				mainFilters: mainFiltersToDisplay,
				categoryFiltersFromInboxOverview: categoryFiltersFromInboxOverview,
				savedFiltersFromInboxOverview: savedFiltersFromInboxOverview,
				isTabDefaultFiltersChanged: isTabDefaultFiltersChanged,
				selectedChatUsernameFromURL: selectedChatUsernameFromURL,
				isProUser: isProUser,
			}}
		>
			{children}
		</InboxLayoutContext.Provider>
	);
};
