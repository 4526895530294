import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import Text from '@components/ui/Text';
import classes from './Inbox.styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchPaginatedInboxData,
	getInboxDataFromGlobalState,
	setCurrChatsDataSelectedChatId,
} from '@store/inboxSlice/inboxSlice';
import { AppDispatch } from '@store/index';
import {
	ALL,
	BULK_ACTIONS_NUDGE_CHATS_DATA_THRESHOLD,
	CHATS,
	CHAT_SEARCH_PARAM,
	FETCH_CHATS_DATA_PAGE_SIZE,
	UNREAD,
	UNREAD_OPTIONS,
	archiveOverviewHeading,
	archivePreAppliedFilters,
	mixPanelEvents,
} from '@utils/constants';
import UserInfo from '@components/ui/UserInfo';
import {
	getSelectedChatDetailsFromGlobalState,
	setSelectedChat,
} from '@store/selectedChatSlice/selectedChatSlice';
import { SenderChatData, getFullNameSenderProfileFunc } from '@src/models/inbox';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import { getAmPmTimeFromUTC, getMonthDayFromUTC, isTodayTime } from '@utils/date';
import mixpanelActions from '@utils/mixpanel';
import NoResultsFound from '@components/NoResultsFound';
import useWindowSize from '@hooks/useWindow';
import { isLiteUserFunc, isProUserFunc } from '@src/models/user';
import JoinWaitList from '@components/JoinWaitList';
import InboxError from '@components/InboxError';
import { IInboxProps } from './Inbox.types';
import { getEmptyStatesData } from '@utils/common';
import useInboxLayoutContext from '@hooks/useInboxLayoutContext';
import Button from '@components/ui/Button';
import { ArrowLeftIcon } from '@src/hoc/withIconStyles';
import InboxMoreActions from '@components/InboxLayout/InboxMoreActions';

function Inbox({ scrollMode, isFiltersDisplayed }: IInboxProps) {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const loadingRef = useRef<HTMLDivElement | null>(null);
	const {
		chatsData,
		fetchStatus,
		paginationDetails,
		currChatsDataSelectedChatId,
		currChatsDataSelectedChatIndex,
		currChatsDataSelectedChatInboxType,
		currReadChatsMap,
		currReportChatsMap,
		currBlockChatsMap,
		currAssignedLabelsChatsMap,
		selectedMainFilter,
		archivedChatsDataTotalCount,
		chatsDataTotalCount,
		selectedSingleFilters,
		currChatsListView,
		filters,
		tapToRefresh,
	} = useSelector(getInboxDataFromGlobalState);
	const { selectedChat } = useSelector(getSelectedChatDetailsFromGlobalState);
	const {
		isTabDefaultFiltersChanged,
		onClickMainFilter,
		onClickOverviewFilterDesktop,
		onClickSingleFilter,
	} = useInboxLayoutContext();
	const pageSize = paginationDetails?.pageSize ?? FETCH_CHATS_DATA_PAGE_SIZE;

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const dispatch = useDispatch<AppDispatch>();
	const selectedChatUsernameFromURL = decodeURIComponent(searchParams.get(CHAT_SEARCH_PARAM) ?? '');
	const { isMobile } = useWindowSize();

	const isLoadingPaginatedData = paginationDetails?.hasMoreChats ?? false;

	const pageContainerRef = useRef<HTMLDivElement | null>(null);
	const chatIdRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

	const isUnreadFilterSelected = selectedSingleFilters[UNREAD] === UNREAD;

	const showBulkActionsNudge =
		!isLoadingPaginatedData &&
		chatsDataTotalCount >= BULK_ACTIONS_NUDGE_CHATS_DATA_THRESHOLD &&
		isUnreadFilterSelected &&
		!selectedChatUsernameFromURL;

	const showCountWithBackButton =
		isUnreadFilterSelected && !isMobile && currChatsListView === 'SCROLL_CARD';

	const countTextToDisplay = `${chatsDataTotalCount} ${chatsDataTotalCount > 1 ? 'DMs' : 'DM'}`;

	const userPersonalLabels = userDetails?.personalLabels ?? [];
	const tempChatAssignedPersonalLabels = (
		currAssignedLabelsChatsMap[selectedChat?.chatId ?? -1] ??
		selectedChat?.personalLabels ??
		[]
	).filter((label) => {
		return userPersonalLabels.includes(label);
	});

	useEffect(() => {
		if (
			!selectedChat &&
			currChatsDataSelectedChatId &&
			currChatsDataSelectedChatInboxType === 'INBOX_VIEW'
		) {
			chatIdRefs.current[currChatsDataSelectedChatId]?.scrollIntoView({
				behavior: 'instant',
				block: 'center',
			});
		}
	}, [selectedChat]);

	useEffect(() => {
		if (
			!scrollMode &&
			currChatsDataSelectedChatId &&
			currChatsDataSelectedChatInboxType === 'INBOX_VIEW'
		) {
			const ele = chatIdRefs.current[currChatsDataSelectedChatId];

			const eleTopPos = ele?.getBoundingClientRect().top ?? 0;

			ele?.scrollIntoView({
				behavior: 'instant',
				block: 'start',
			});

			if (eleTopPos < 300 && isUnreadFilterSelected && !isMobile) {
				pageContainerRef.current?.scrollTo({
					top: 0,
					behavior: 'instant',
				});
			}
		}
	}, [scrollMode]);

	useEffect(() => {
		const obsElement = loadingRef.current;

		if (!obsElement || !chatsData) return;

		const obsOptions = {
			root: null,
			threshold: 0.5,
		};

		const obsCallback = (entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;

			if (entry.isIntersecting) {
				dispatch(
					fetchPaginatedInboxData({
						lastFiveMessagesRequired: isUnreadFilterSelected && isMobile,
						topPicksThreshold: userDetails?.topPicksThreshold,
					})
				);
			}
		};

		const observer = new IntersectionObserver(obsCallback, obsOptions);
		observer.observe(obsElement);

		return () => {
			if (!obsElement) return;
			observer.unobserve(obsElement);
		};
	}, [chatsData]);

	useEffect(() => {
		mixpanelActions.trackInbox(mixPanelEvents.INBOX_OPENED, `${chatsData.length}`, 'no');
	}, [chatsData]);

	const showTempChat = !chatsData.length && !!selectedChat;

	const handleOnClickUser = (selectedChatData: SenderChatData) => {
		const selectedChatDataSenderDetails = selectedChatData.senderDetails;

		if (!selectedChatDataSenderDetails) return;

		mixpanelActions.trackInboxChat(
			mixPanelEvents.INBOX_CHAT_OPENED,
			selectedChatDataSenderDetails.username ?? '',
			`${selectedChatData.read ? 'true' : 'false'}`,
			''
		);

		dispatch(
			setCurrChatsDataSelectedChatId({
				chatId: selectedChatData.chatId,
				index: chatsData.length,
				inboxType: 'INBOX_VIEW',
			})
		);
		dispatch(setSelectedChat({ selectedChatData: selectedChatData }));

		setSearchParams(
			(prevParams) => {
				const searchParamsToUpdate = new URLSearchParams(prevParams);
				searchParamsToUpdate.set(
					CHAT_SEARCH_PARAM,
					encodeURIComponent(selectedChatDataSenderDetails.username)
				);
				return searchParamsToUpdate;
			},
			{ replace: !!selectedChatUsernameFromURL }
		);
	};

	const handleClickBackButton = () => {
		navigate(-1);
	};

	const showProUserEmptyState =
		!chatsData.length &&
		fetchStatus !== 'loading' &&
		fetchStatus !== 'idle' &&
		isProUserFunc(userDetails) &&
		!selectedChatUsernameFromURL;

	const showLiteUserEmptyState =
		!chatsData.length &&
		fetchStatus !== 'loading' &&
		fetchStatus !== 'idle' &&
		isLiteUserFunc(userDetails) &&
		!selectedChatUsernameFromURL;

	const showErrorState = fetchStatus === 'error';

	if (showErrorState) {
		return <InboxError />;
	}

	if (showProUserEmptyState) {
		const emptyStateData = getEmptyStatesData({
			selectedMainFilterName: selectedMainFilter?.name,
			isMobile: isMobile,
			isFiltersDisplayed: isFiltersDisplayed,
			isDefaultFiltersChanged: isTabDefaultFiltersChanged,
			isUnreadFilterSelected: isUnreadFilterSelected,
		});

		const Icon = emptyStateData.icon;

		const archivedCount = archivedChatsDataTotalCount ?? 0;

		const ctaText = isTabDefaultFiltersChanged
			? emptyStateData.clearFiltersCTAText
			: tapToRefresh
			? emptyStateData.tapToRefreshCTAText
			: isUnreadFilterSelected
			? emptyStateData.viewAllDMsCTAText
			: archivedCount > 0
			? emptyStateData.archivedDMsCTAText
			: '';

		const unreadFilter = filters.find((filter) => filter.filterName === UNREAD);

		return (
			<NoResultsFound
				heading={emptyStateData.heading}
				subText={emptyStateData.subText}
				styles={emptyStateData.containerStyles}
				icon={<Icon size={emptyStateData.iconSize} style={emptyStateData.iconStyles} />}
				ctaActionText={ctaText}
				ctaAction={() => {
					if (isTabDefaultFiltersChanged) {
						selectedMainFilter && onClickMainFilter(selectedMainFilter);
					} else if (tapToRefresh) {
						onClickSingleFilter('tap_to_refresh', 'true', true);
					} else if (isUnreadFilterSelected) {
						unreadFilter && onClickSingleFilter(UNREAD, UNREAD_OPTIONS['NOT_UNREAD'], true);
					} else {
						onClickOverviewFilterDesktop(archivePreAppliedFilters, archiveOverviewHeading);
					}
				}}
			/>
		);
	}

	if (showLiteUserEmptyState) {
		return <JoinWaitList />;
	}

	return (
		<div
			className={clsx(
				classes.pageContainer,
				selectedChatUsernameFromURL && classes.pageContainerMobile
			)}
			ref={pageContainerRef}
		>
			{showCountWithBackButton && (
				<div className={classes.countWithBackBtn}>
					<Button
						btnText={<ArrowLeftIcon size={1.6} />}
						onClick={handleClickBackButton}
						customClass={classes.backIcon}
					/>
					<Text variant="p" tiny lineHeight={1.6} secondary>
						{countTextToDisplay}
					</Text>
				</div>
			)}
			{showTempChat && (
				<UserInfo
					key={selectedChat?.chatId}
					headline={selectedChat?.senderDetails?.profileData?.headline ?? ''}
					name={
						selectedChat?.senderDetails?.profileData
							? getFullNameSenderProfileFunc(selectedChat.senderDetails.profileData)
							: ''
					}
					readStatus={selectedChat?.chatId ? selectedChat.read : true}
					profilePicURL={selectedChat?.senderDetails?.profileData?.profilePicture}
					assignedPersonalLabels={tempChatAssignedPersonalLabels}
					isUserSelected={true}
					customClass={classes.userInfoCustomClass}
				/>
			)}
			{chatsData.map((chatData, index) => {
				const { senderDetails, chatId, label, lastMessage, read } = chatData;
				const senderProfileData = senderDetails?.profileData;
				const monthDayFormat = getMonthDayFromUTC(lastMessage?.createdAt ?? '');
				const timeAmPmFormat = getAmPmTimeFromUTC(lastMessage?.createdAt ?? '');
				const isTodayMessage = isTodayTime(lastMessage?.createdAt ?? '');

				const profilePicURL = senderProfileData?.profilePicture;
				const profileName = senderProfileData
					? getFullNameSenderProfileFunc(senderProfileData)
					: '';
				const profileHeadLine = senderProfileData?.headline ?? '';

				const dateDisplay = isTodayMessage ? `${timeAmPmFormat ?? ''}` : `${monthDayFormat ?? ''}`;

				const disableAnimation =
					currChatsDataSelectedChatIndex && index < currChatsDataSelectedChatIndex;

				const readStatus = currReadChatsMap[chatData.chatId ?? -1] ?? read;

				const chatReported = currReportChatsMap[chatData.chatId ?? -1] ?? false;
				const chatBlocked = currBlockChatsMap[chatData.chatId ?? -1] ?? false;

				const assignedPersonalLabels = (
					currAssignedLabelsChatsMap[chatData.chatId ?? -1] ?? chatData.personalLabels
				).filter((label) => {
					return userPersonalLabels.includes(label);
				});

				return (
					<div
						key={chatId}
						role="button"
						tabIndex={0}
						aria-label="click to chat"
						className={clsx(
							classes.chatRow,
							index === 0 &&
								(selectedMainFilter?.name === ALL || selectedMainFilter?.name === CHATS) &&
								classes.firstChatRow,
							disableAnimation && classes.chatRowNoAnimation,
							(chatReported || chatBlocked) && classes.chatReported
						)}
						onClick={(e) => {
							e.stopPropagation();
							handleOnClickUser(chatData);
						}}
						style={{
							animationDelay: disableAnimation ? 'unset' : `${(index % pageSize) * 0.05}s`,
						}}
						ref={(el) => {
							if (chatId) {
								chatIdRefs.current[chatId] = el;
							}
						}}
					>
						<UserInfo
							headline={profileHeadLine}
							name={profileName}
							readStatus={readStatus}
							profilePicURL={profilePicURL}
							recentMessage={lastMessage?.content ?? ''}
							recentMessageDate={dateDisplay}
							isUserSelected={senderDetails?.username === selectedChat?.senderDetails?.username}
							assignedPersonalLabels={assignedPersonalLabels}
						/>

						{!selectedChatUsernameFromURL && (
							<>
								<Text variant="p" small ultraLight secondary customClass={classes.lastMessage}>
									{label && (
										<Text variant="span" light tiny customClass={classes.label}>
											{label}
										</Text>
									)}
									{lastMessage?.content ?? ''}
								</Text>
								<Text variant="span" tiny ultraLight tertiaryV0 customClass={classes.dateDisplay}>
									{dateDisplay}
								</Text>
							</>
						)}
					</div>
				);
			})}
			<div aria-hidden ref={loadingRef} className={classes.loadingContainer}>
				{isLoadingPaginatedData && (
					<Text variant="p" ultraLight small secondary>
						{'Loading DMs...'}
					</Text>
				)}
			</div>
			{showBulkActionsNudge && <InboxMoreActions nudgeMode chatsView={'LIST_VIEW'} />}
		</div>
	);
}

export default Inbox;
