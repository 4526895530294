import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import Text from '@components/ui/Text';
import classes from './Landing.styles.module.scss';
import Button from '@components/ui/Button';
import {
	ACCESS_TOKEN,
	ACCOUNT_UNDER_REVIEW,
	EARLY_ACCESS_FORM,
	GOOGLE_OAUTH_TOKEN_PARAM,
	INBOX_PATH,
	REDIRECT_URL,
	mainFiltersArr,
	mixPanelEvents,
} from '@utils/constants';
import { loginUser } from '@api/auth';
import useFetch from '@hooks/useFetch';
import { getUserDetails } from '@api/user';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/index';
import { getLocalStorage, setLocalStorage } from '@utils/localStorage';
import {
	getUserDetailsFromGlobalState,
	setUserData,
} from '@store/userDetailsSlice/userDetailsSlice';
import { setMainFilters } from '@store/inboxSlice/inboxSlice';
import Toast from '@components/ui/Toast';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import { AccountNotFoundIcon, LogoTextIcon } from '@src/hoc/withIconStyles';
import mixpanelActions from '@utils/mixpanel';
import AuthModal from '@components/AuthModal';
import { IAuthModalProps } from '@components/AuthModal/AuthModal.types';
import { IUserLoginPayload } from '@api/auth/auth.types';
import {
	isAccountBlockedFunc,
	isAccountPendingFunc,
	isAccountRejectedFunc,
	isAccountVerifiedFunc,
	isProUserFunc,
} from '@src/models/user';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';

function Landing() {
	const toastRef = React.useRef<IRefProps>(null);

	const [showSignInModal, setShowSignInModal] = useState(false);
	const [modalToastError, setModalToastError] = useState('');

	const dispatch = useDispatch<AppDispatch>();

	const [searchParams, setSearchParams] = useSearchParams();

	const redirectURL = decodeURIComponent(searchParams.get(REDIRECT_URL) ?? '');
	const googleOAuthToken = React.useRef(
		decodeURIComponent(searchParams.get(GOOGLE_OAUTH_TOKEN_PARAM) ?? '')
	);

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const {
		callApi: login,
		status: loginStatus,
		errorStatus: loginErrorStatus,
	} = useFetch(loginUser);
	const { callApi: fetchUserDetails, status: fetchUserDetailsStatus } = useFetch(getUserDetails);

	useEffect(() => {
		mixpanelActions.trackLandingPage(
			mixPanelEvents.LANDING_PAGE_OPENED,
			undefined,
			!!getLocalStorage(ACCESS_TOKEN)
		);

		if (googleOAuthToken.current) {
			handleModalSignInSuccess({ googleOAuthCode: googleOAuthToken.current });
			setSearchParams((prevSearchParams) => {
				const searchParamsToSet = new URLSearchParams(prevSearchParams);
				searchParamsToSet.delete(GOOGLE_OAUTH_TOKEN_PARAM);
				return searchParamsToSet;
			});
		}
	}, []);

	const onClickJoinWaitList = () => {
		mixpanelActions.trackLandingPage(mixPanelEvents.LANDING_PAGE_EARLY_ACCESS_CLICKED);
		window.open(EARLY_ACCESS_FORM, '_blank');
	};

	const onClickSignIn = () => {
		mixpanelActions.trackLandingPage(mixPanelEvents.LANDING_PAGE_SIGN_IN_CLICKED);
		setShowSignInModal(true);
	};

	const isLoadingSignIn = loginStatus === 'loading' || fetchUserDetailsStatus === 'loading';

	const handleOnClickPrivacy = () => {
		window.open(`${window.location.origin}/privacy`, '_blank');
	};
	const handleOnClickTermsAndConditions = () => {
		window.open(`${window.location.origin}/tnc`, '_blank');
	};

	const handleModalSignInSuccess: IAuthModalProps['onSuccessAuth'] = async ({
		googleOAuthCode,
		email,
		otp,
	}) => {
		toastRef.current?.unPublish();
		setModalToastError('');
		const payload: IUserLoginPayload = {
			email: email ?? null,
			login_type: googleOAuthCode ? 'GOOGLE' : 'EMAIL',
			otp: otp ?? null,
			remember_me: true,
			token_id: googleOAuthCode ?? null,
		};

		try {
			const resData = await login(payload);
			const jwtToken = resData?.jwtToken ?? null;

			if (jwtToken) {
				setLocalStorage(ACCESS_TOKEN, jwtToken);
				const data = await fetchUserDetails(jwtToken);
				if (data) {
					mixpanelActions.identifyUserAndSetEmail({
						email: data.email,
						username: data.username,
						userType: data.userType,
						userID: data.userId,
					});
					mixpanelActions.registerSecondaryUser(
						data.isSecondaryUser ? 'yes' : 'no',
						data.secondaryUserEmail
					);
					dispatch(setUserData({ userData: data ? { ...data } : null }));
					isProUserFunc(data) &&
						dispatch(
							setMainFilters({
								filtersToPrepend: [...mainFiltersArr],
							})
						);
				}
			} else {
				toastRef.current?.publish();
			}
			setShowSignInModal(false);
			// can check draft user condition check to show model to alert user to register instead of login
		} catch (error) {
			// handle error

			if (loginErrorStatus === 403) {
				setModalToastError('Too many attempts!');
				return;
			}

			setModalToastError(error);
		}
	};

	const handleCloseModal = () => {
		setShowSignInModal(false);
		setModalToastError('');
	};

	if (
		userDetails?.userId &&
		(isAccountRejectedFunc(userDetails) || isAccountBlockedFunc(userDetails))
	) {
		return <Navigate to={ACCOUNT_UNDER_REVIEW} replace />;
	}

	if (
		userDetails?.userId &&
		(isAccountVerifiedFunc(userDetails) || isAccountPendingFunc(userDetails))
	) {
		window.history.replaceState({ idx: 0 }, '');

		return redirectURL ? (
			<Navigate to={redirectURL} replace />
		) : (
			<Navigate to={INBOX_PATH} replace />
		);
	}

	return (
		<section className={classes.landingMainContainer}>
			<div
				className={clsx(
					classes.landingContainer,
					!googleOAuthToken.current && classes.landingContainerAnimation,
					!!googleOAuthToken.current && classes.landingContainerNoAnimation
				)}
			>
				<LogoTextIcon size={4} />
				<Text variant="h1" semiBold customClass={classes.landingText}>
					{'The best folks, just a DM away.'}
				</Text>
				<Button
					btnText={
						<Text variant="span" white semiBold>
							{'Get early access'}
						</Text>
					}
					onClick={onClickJoinWaitList}
					primary
					customClass={classes.joinBtn}
				/>
				<Button
					btnText={<Text variant="span">{'Sign in'}</Text>}
					onClick={onClickSignIn}
					customClass={classes.signInBtn}
					isLoading={isLoadingSignIn}
				/>
			</div>
			<div
				className={clsx(
					classes.bottomBtnsContainer,
					!googleOAuthToken.current && classes.bottomBtnsContainerAnimation,
					!!googleOAuthToken.current && classes.bottomBtnsContainerNoAnimation
				)}
			>
				<Button
					btnText={<Text variant="span" secondary small light>{`Terms`}</Text>}
					onClick={handleOnClickTermsAndConditions}
				/>
				<Button
					btnText={<Text variant="span" secondary small light>{`Privacy`}</Text>}
					onClick={handleOnClickPrivacy}
				/>
			</div>
			{showSignInModal && (
				<AuthModal
					modalHeader={'Sign in to SuperDM'}
					onCloseModal={handleCloseModal}
					showModal={showSignInModal}
					authType="LOGIN"
					onSuccessAuth={handleModalSignInSuccess}
					modalError={modalToastError}
					isLoadingAuth={isLoadingSignIn}
					onClickContinueEmailBtn={(inputEmail) => {
						mixpanelActions.trackLandingPage(
							mixPanelEvents.LANDING_PAGE_EMAIL_PROCEED_CLICKED,
							inputEmail
						);
					}}
					onClickGoogleBtn={() => {
						mixpanelActions.trackLandingPage(mixPanelEvents.LANDING_PAGE_GOOGLE_BTN_CLICK);
					}}
					onOTPInputShown={() => {
						mixpanelActions.trackLandingPage(mixPanelEvents.LANDING_PAGE_OTP_SHOWN);
					}}
					onOTPSubmitted={() => {
						mixpanelActions.trackLandingPage(mixPanelEvents.LANDING_PAGE_EMAIL_OTP_SUBMITTED);
					}}
				/>
			)}
			<Toast
				ref={toastRef}
				toastType="ERROR"
				header="No Account Found"
				content="To create account, apply for early access"
				icon={<AccountNotFoundIcon size={1.8} className={classes.toastIcon} />}
			/>
		</section>
	);
}
export default Landing;
