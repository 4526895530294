import mixpanel from 'mixpanel-browser';
import { getSuperDMAppVersion, isPWAApp, isSuperDMApp } from './common';
import { TChatsListView } from '@api/auth/auth.types';

const mixPanelProjectToken = process.env.MIX_PANEL_PROJECT_TOKEN;

const mixpanelCommonConfig = {
	ignore_dnt: true,
};

const mixpanelEnvConfig =
	process.env.ENVIRONMENT === 'PROD'
		? { api_host: 'https://sm-proxy.superdm.me' }
		: { debug: true };

if (mixPanelProjectToken) {
	mixpanel.init(mixPanelProjectToken, { ...mixpanelCommonConfig, ...mixpanelEnvConfig });
	mixpanel.register({
		device_useragent: window.navigator.userAgent,
		isPWA: isPWAApp(),
		isSuperDMApp: isSuperDMApp(),
		SuperDMAppVersion: isSuperDMApp() ? getSuperDMAppVersion() : null,
		web_app_version: process.env.APP_VERSION as string,
	});
}

interface IObj {
	[key: string]: string | null | number | string[] | unknown | boolean;
}

interface ITrackVerifyLinkedInParams {
	eventName: string;
	trigger?: 'topbar' | 'chat';
	errorReason?: string;
}
interface ITrackFilterParams {
	eventName: string;
	tabName: string;
	currentSort?: string;
	labelName?: string;
	selectedValue?: string;
}

type TScreen = 'card' | 'chat';

interface IQuickReplyActionParams {
	eventName: string;
	labelName?: string;
	chatUsername?: string;
	trigger?: 'slash' | 'icon';
	replyCount?: number;
	screen?: TScreen;
	type?: 'pill' | 'list';
}

interface ISetUserPropertiesParams {
	email: string;
	username: string;
	userType: string;
	userID: number;
}

export type TOverviewFilterType =
	| 'Top Picks'
	| 'Category'
	| 'Saved Filters'
	| 'Insight Filters'
	| 'All DMs';

interface IOverviewPropertiesParams {
	eventName: string;
	tabOverviewFilterType: TOverviewFilterType | string;
	tabOverviewFilterName: string;
}

interface IMessageErrorParams {
	eventName: string;
	messageErrorType: 'Min Limit' | 'Max Limit' | 'Category';
	validationCount?: number;
	charCount?: number;
	errorShown?: string;
}

interface IAttachmentParams {
	eventName: string;
	isTemp: string;
	attachment?: string;
	errorShown?: string;
}

interface ITeamMemberUserParams {
	eventName: string;
	errorReason?: string;
}

export type TBookmarkPosition =
	| 'HEADER'
	| 'BOTTOM'
	| 'CHECKBOX'
	| 'SCROLL_CARD'
	| 'MESSAGES'
	| 'SWIPE_CARD';

export type TQuickReplyLocation = 'WIDGET' | 'NEW';
export type TCardBookmarkLocation = TQuickReplyLocation;
export type TCardView = TChatsListView;
export type TCardReadLocation = TQuickReplyLocation;
export type TInboxBulkActionsLocation = 'NUDGE' | 'MORE_ACTIONS';

const mixpanelActions = {
	trackEvent: (eventName: string) => {
		mixpanel.track(eventName);
	},
	trackLandingPage: (eventName: string, emailSubmitted?: string, tokenExist?: boolean) => {
		const obj: IObj = {};

		if (emailSubmitted) {
			obj['email_submitted'] = emailSubmitted;
		}

		if (typeof tokenExist === 'boolean') {
			obj['token_exist'] = tokenExist;
		}

		mixpanel.track(eventName, obj);
	},
	trackOnboardingLogin: (eventName: string, emailSubmitted?: string) => {
		const obj: IObj = {};

		if (emailSubmitted) {
			obj['email_submitted'] = emailSubmitted;
		}

		mixpanel.track(eventName, obj);
	},
	trackHeader: (eventName: string) => {
		mixpanel.track(eventName);
	},
	trackInbox: (eventName: string, chatsCount: string, isTemp: string) => {
		const obj: IObj = {
			chats_count: chatsCount,
			isTemp,
		};

		mixpanel.track(eventName, { ...obj });
	},
	trackLinkedInProfile: (eventName: string) => {
		mixpanel.track(eventName);
	},
	trackOnboardingPause: (eventName: string) => {
		mixpanel.track(eventName);
	},
	trackOnboarding: (
		eventName: string,
		receiverUsername: string,
		submittedURL?: string,
		errorMsg?: string,
		emailSubmitted?: string
	) => {
		const obj: IObj = {
			receiver_username: receiverUsername,
		};

		if (submittedURL) {
			obj['submitted_url'] = submittedURL;
		}

		if (errorMsg) {
			obj['error_shown'] = errorMsg;
		}

		if (emailSubmitted) {
			obj['email_submitted'] = emailSubmitted;
		}

		mixpanel.track(eventName, { ...obj });
	},
	trackOnboardingEducation: (eventName: string, currStepTitle: string) => {
		const obj: IObj = {
			current_step_title: currStepTitle,
		};

		mixpanel.track(eventName, { ...obj });
	},
	trackVerifyLinkedIn: ({ eventName, errorReason, trigger }: ITrackVerifyLinkedInParams) => {
		const obj: IObj = {};

		if (trigger) {
			obj['trigger'] = trigger;
		}

		if (errorReason) {
			obj['error_reason'] = errorReason;
		}

		mixpanel.track(eventName, { ...obj });
	},
	trackChatInboxCategory: (
		eventName: string,
		chatUsername: string,
		isTemp: string,
		selectedCategory?: string,
		attachmentType?: string,
		viaQuickReply?: boolean,
		screen?: TScreen,
		cardView?: TCardView
	) => {
		const obj: IObj = {
			chat_username: chatUsername,
			isTemp: isTemp,
		};

		if (attachmentType) {
			obj['attachment'] = attachmentType;
		}

		if (selectedCategory) {
			obj['selected_category'] = selectedCategory;
		}

		const isViaQuickReply = typeof viaQuickReply === 'boolean';

		if (isViaQuickReply) {
			obj['via_quick_reply'] = viaQuickReply;
		}

		if (screen) {
			obj['screen'] = screen;
		}

		if (cardView) {
			obj['card_layout'] = cardView;
		}

		mixpanel.track(eventName, { ...obj });
	},
	setUserLinkedInProperties: (linkedInURL: string, verified: boolean, username?: string) => {
		const obj: IObj = {};
		if (!verified) {
			obj['LinkedIn URL'] = linkedInURL;
		}

		if (verified) {
			obj['LinkedIn URL Verified'] = 'true';
		}

		if (username) {
			obj['username'] = username;
		}

		mixpanel.people.set({ ...obj });
	},
	trackInboxEmptyStates: (eventName: string) => {
		mixpanel.track(eventName);
	},
	trackInboxChat: (
		eventName: string,
		chatUsername: string,
		chatReadStatus: string,
		category: string
	) => {
		const obj: IObj = { chat_username: chatUsername };

		if (category) {
			obj['category'] = category;
		}

		if (chatReadStatus) {
			obj['read_status'] = chatReadStatus;
		}

		mixpanel.track(eventName, { ...obj });
	},
	trackChatBookmark: (
		eventName: string,
		chatUsername: string,
		bookmarkPosition: TBookmarkPosition
	) => {
		const obj: IObj = { chat_username: chatUsername, position: bookmarkPosition };
		mixpanel.track(eventName, { ...obj });
	},
	trackMarkChatAsRead: (eventName: string, chatUsername: string) => {
		const obj: IObj = { chat_username: chatUsername };
		mixpanel.track(eventName, { ...obj });
	},
	trackPageNotFound: (eventName: string) => {
		mixpanel.track(eventName);
	},
	trackInboxMainFilters: (eventName: string, prevTabName: string, currTabName: string) => {
		const obj = {
			current_tab: prevTabName,
			new_tab: currTabName,
		};

		mixpanel.track(eventName, { ...obj });
	},
	trackFilters: ({
		eventName,
		tabName,
		currentSort,
		labelName,
		selectedValue,
	}: ITrackFilterParams) => {
		const obj: IObj = {
			tab_name: tabName,
		};

		if (currentSort) {
			obj['current_sort'] = currentSort;
		}

		if (labelName) {
			obj['label_name'] = labelName;
		}

		if (selectedValue) {
			obj['selected_value'] = selectedValue;
		}

		mixpanel.track(eventName, { ...obj });
	},
	trackSaveFilter: (eventName: string, filterName?: string) => {
		const obj: IObj = {};

		if (filterName) {
			obj['save_filter_name'] = filterName;
		}

		mixpanel.track(eventName, { ...obj });
	},
	trackAttachments: ({ eventName, isTemp, attachment, errorShown }: IAttachmentParams) => {
		const obj: IObj = {
			isTemp: isTemp,
		};

		if (attachment) {
			obj['attachment'] = attachment;
		}

		if (errorShown) {
			obj['error_shown'] = errorShown;
		}

		mixpanel.track(eventName, { ...obj });
	},
	trackCannedResponse: ({
		eventName,
		chatUsername,
		labelName,
		replyCount,
		trigger,
		screen,
		type,
	}: IQuickReplyActionParams) => {
		const obj: IObj = {};

		if (chatUsername) {
			obj['chat_username'] = chatUsername;
		}

		if (labelName) {
			obj['label_name'] = labelName;
		}

		if (replyCount) {
			obj['replies_count'] = replyCount;
		}

		if (trigger) {
			obj['trigger'] = trigger;
		}

		if (screen) {
			obj['screen'] = screen;
		}

		if (type) {
			obj['type'] = type;
		}

		mixpanel.track(eventName, { ...obj });
	},
	trackNotification: (eventName: string, url: string) => {
		const obj: IObj = {
			url: url,
		};

		mixpanel.track(eventName, { ...obj });
	},
	trackSearch: (eventName: string, searchStr: string, searchResultsCount?: number) => {
		const obj: IObj = {
			search_term: searchStr,
		};

		if (typeof searchResultsCount === 'number') {
			obj['number_of_results'] = searchResultsCount;
		}

		mixpanel.track(eventName, { ...obj });
	},
	trackSendAsEmail: (eventName: string, chatUsername: string, mails?: string[]) => {
		const obj: IObj = {
			chat_username: chatUsername,
		};

		if (mails?.length) {
			obj['mails'] = mails;
		}

		mixpanel.track(eventName, { ...obj });
	},
	trackOverview: ({
		eventName,
		tabOverviewFilterName,
		tabOverviewFilterType,
	}: IOverviewPropertiesParams) => {
		const obj: IObj = {
			widget_type: tabOverviewFilterType,
			widget_name: tabOverviewFilterName,
		};

		mixpanel.track(eventName, { ...obj });
	},
	trackAllDMClick: (eventName: string, isTabEmpty: boolean) => {
		const obj: IObj = {
			empty_tab: isTabEmpty ? 'yes' : 'no',
		};

		mixpanel.track(eventName, { ...obj });
	},
	trackNewDM: (eventName: string) => {
		mixpanel.track(eventName);
	},
	trackQuickReply: (eventName: string, location?: TQuickReplyLocation, cardView?: TCardView) => {
		const obj: IObj = {};

		if (location) {
			obj['card_location'] = location;
		}

		if (cardView) {
			obj['card_layout'] = cardView;
		}

		mixpanel.track(eventName, { ...obj });
	},
	trackLabel: (eventName: string) => {
		const obj: IObj = {};

		mixpanel.track(eventName, { ...obj });
	},
	trackCardBookmark: (
		eventName: string,
		location?: TCardBookmarkLocation,
		cardView?: TCardView
	) => {
		const obj: IObj = {};

		if (location) {
			obj['card_location'] = location;
		}

		if (cardView) {
			obj['card_layout'] = cardView;
		}

		mixpanel.track(eventName, { ...obj });
	},
	trackCardRead: (eventName: string, location?: TCardReadLocation, cardView?: TCardView) => {
		const obj: IObj = {};

		if (location) {
			obj['card_location'] = location;
		}

		if (cardView) {
			obj['card_layout'] = cardView;
		}

		mixpanel.track(eventName, { ...obj });
	},
	trackCardTagsScroll: (eventName: string, location?: TCardReadLocation, cardView?: TCardView) => {
		const obj: IObj = {};

		if (location) {
			obj['card_location'] = location;
		}

		if (cardView) {
			obj['card_layout'] = cardView;
		}

		mixpanel.track(eventName, { ...obj });
	},
	trackDeleteChatMessage: (eventName: string, chatUsername: string) => {
		const obj: IObj = {
			chat_username: chatUsername,
		};

		mixpanel.track(eventName, { ...obj });
	},
	trackMessageError: ({
		eventName,
		messageErrorType,
		charCount,
		validationCount,
		errorShown,
	}: IMessageErrorParams) => {
		const obj: IObj = {
			message_error_type: messageErrorType,
		};

		if (charCount) {
			obj['character_count'] = charCount;
		}

		if (validationCount) {
			obj['validation_count'] = validationCount;
		}

		if (errorShown) {
			obj['error_shown'] = errorShown;
		}

		mixpanel.track(eventName, { ...obj });
	},
	trackAppCrash: (eventName: string) => {
		mixpanel.track(eventName);
	},
	trackGoogleOAuth: (eventName: string, error?: string) => {
		const obj: IObj = {};

		if (error) {
			obj['error_reason'] = error;
		}

		mixpanel.track(eventName, { ...obj });
	},
	trackWebAppVersionCheck: (eventName: string) => {
		mixpanel.track(eventName);
	},
	trackAppUpdateModal: (eventName: string) => {
		mixpanel.track(eventName);
	},
	trackCardSwipe: (eventName: string) => {
		mixpanel.track(eventName);
	},
	trackCardLayoutChange: (eventName: string, card_layout: TCardView) => {
		const obj: IObj = {
			card_layout,
		};

		mixpanel.track(eventName, { ...obj });
	},
	trackSwitchUser: (eventName: string, userName: string) => {
		const obj: IObj = {
			user_name: userName,
		};

		mixpanel.track(eventName, { ...obj });
	},
	trackBulkMarkAsRead: (eventName: string, location?: TInboxBulkActionsLocation) => {
		const obj: IObj = {
			location,
		};

		mixpanel.track(eventName, { ...obj });
	},
	trackBulkReply: (eventName: string, location?: TInboxBulkActionsLocation) => {
		const obj: IObj = {
			location,
		};

		mixpanel.track(eventName, { ...obj });
	},
	trackTeamMember: ({ eventName, errorReason }: ITeamMemberUserParams) => {
		const obj: IObj = {};

		if (errorReason) {
			obj['error_reason'] = errorReason;
		}

		mixpanel.track(eventName, { ...obj });
	},
	registerSecondaryUser: (isSecondaryUser: string, secondaryUserEmail?: string | null) => {
		const obj: IObj = {
			is_secondary_user: isSecondaryUser,
		};

		if (secondaryUserEmail) {
			obj['secondary_user_email'] = secondaryUserEmail;
		}

		mixpanel.register({ ...obj });
	},
	identifyUserAndSetEmail: ({ email, userType, username, userID }: ISetUserPropertiesParams) => {
		mixpanel.identify(`${userID}`);
		mixpanel.people.set({ $distinct_id: userID });
		mixpanel.people.set({ $email: email ?? `email not found for userId: ${userID}` });
		mixpanel.people.set({ username: username });
		mixpanel.people.set({ user_type: userType });
	},
	resetUser: () => {
		mixpanel.reset();
	},
};

export default mixpanelActions;
