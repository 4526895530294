import axios from 'axios';
import { ACCESS_TOKEN, DEVICE_TYPE, WEB_VERSION_CODE, mixPanelEvents } from '@utils/constants';
import { clearLocalStorage, getLocalStorage } from '@utils/localStorage';
import { clearRootState, setAppUpdateState } from '@utils/store';
import mixpanelActions from '@utils/mixpanel';
import { getDeviceDimensionsInfo } from '@utils/common';

export const appAxiosAuthInstance = axios.create({
	baseURL: process.env.API_BASE_ENDPOINT_V1,
});

appAxiosAuthInstance.interceptors.request.use(
	(config) => {
		config.headers[WEB_VERSION_CODE] = process.env.APP_VERSION as string;
		config.headers[DEVICE_TYPE] = getDeviceDimensionsInfo(
			window.innerHeight,
			window.innerWidth
		).deviceType;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

appAxiosAuthInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (axios.isAxiosError(error)) {
			if (error.response?.status === 416) {
				setAppUpdateState(true);
			}
		}

		return Promise.reject(error);
	}
);

export const appAxiosInstance = axios.create({
	baseURL: process.env.API_BASE_ENDPOINT_V1,
});

appAxiosInstance.interceptors.request.use(
	(config) => {
		const accessToken = getLocalStorage(ACCESS_TOKEN);
		config.headers.Authorization = `Bearer ${accessToken}`;
		config.headers[WEB_VERSION_CODE] = process.env.APP_VERSION as string;
		config.headers[DEVICE_TYPE] = getDeviceDimensionsInfo(
			window.innerHeight,
			window.innerWidth
		).deviceType;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

appAxiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		if (axios.isAxiosError(error)) {
			if (error.response?.status === 401) {
				mixpanelActions.trackEvent(mixPanelEvents.API_ERROR_UNAUTHORIZED);
				clearLocalStorage();
				mixpanelActions.resetUser();
				clearRootState();
			}

			if (error.response?.status === 416) {
				setAppUpdateState(true);
			}
		}
		return Promise.reject(error);
	}
);

export type TErrorType = 'AbortError' | 'HTTP Error' | 'Network Error' | 'Request Error';
export type TErrorMessage = string | null;

export function getErrorDetails(error: unknown): {
	errorType: TErrorType;
	errorMessage: TErrorMessage;
	errorStatusCode: number | null;
} {
	// Handle AxiosError (network errors, HTTP errors, etc.)

	let errorType: TErrorType = 'Request Error';
	let errorMessage: TErrorMessage = '';
	let errorStatusCode: number | null = null;

	if (axios.isAxiosError(error)) {
		if (error.message === 'canceled') {
			console.error('Request was aborted:', error.message);
			errorType = 'AbortError';
			errorMessage = null; // Re-throw the AbortError if the request was aborted
		} else if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			console.error('HTTP Error:', error.response.status, error.response.data);
			const errorObj = error.response.data as { message: string };
			errorType = 'HTTP Error';
			errorMessage = errorObj.message ?? 'There was some problem, please try again';
			errorStatusCode = error.response.status;
		} else if (error.request) {
			// The request was made but no response was received
			console.error('Network Error:', error.request);
			errorType = 'Network Error';
			errorMessage = 'There was some problem, please try again';
		} else {
			// Something happened in setting up the request that triggered an Error
			console.error('Request Error:', error.message);
			errorType = 'Request Error';
			errorMessage = 'There was some problem, please try again';
		}
	} else {
		// Handle the case where the error is not an AxiosError
		console.error('Error:', error);
		errorType = 'Request Error';
		errorMessage = 'There was some problem, please try again';
	}

	return { errorType, errorMessage, errorStatusCode };
}
