import withIconStyles from './withIconStyles';

import GoogleSVGIcon from '@public/icons/googleIcon.svg';
import InfoSVGIcon from '@public/icons/infoIcon.svg';
import ArrowUpRightSVGIcon from '@public/icons/arrowUpRightIcon.svg';
import LinkedInSVGIcon from '@public/icons/linkedInIcon.svg';
import AlertSVGIcon from '@public/icons/alertIcon.svg';
import DropDownSVGIcon from '@public/icons/dropDownIcon.svg';
import CheckSVGIcon from '@public/icons/checkIcon.svg';
import SearchSVGIcon from '@public/icons/searchIcon.svg';
import PlusSVGIcon from '@public/icons/plusIcon.svg';
import CopySVGIcon from '@public/icons/copyIcon.svg';
import LinkSVGIcon from '@public/icons/linkIcon.svg';
import OpenNewTabSVGIcon from '@public/icons/openNewTabIcon.svg';
import SendSVGIcon from '@public/icons/sendIcon.svg';
import CloseSVGIcon from '@public/icons/closeIcon.svg';
import NotFoundSVGIcon from '@public/icons/notFoundIcon.svg';
import AccountNotFundSVGIcon from '@public/icons/accountNotFoundIcon.svg';
import PartySVGIcon from '@public/icons/partyIcon.svg';
import CheckCircleSVGIcon from '@public/icons/checkCircleIcon.svg';
import LogoTextSVGIcon from '@public/icons/logoWithText.svg';
import MainPageLogoTextSVGMobileIcon from '@public/icons/mainPageLogoMobile.svg';
import UnverifiedSVGLogo from '@public/icons/unverifiedLogo.svg';
import EditSVGIcon from '@public/icons/editIcon.svg';
import DragSVGIcon from '@public/icons/dragIcon.svg';
import DeleteSVGIcon from '@public/icons/deleteIcon.svg';
import UserSVGIcon from '@public/icons/userIcon.svg';
import SettingsSVGIcon from '@public/icons/settingsIcon.svg';
import ArrowLeftSVGIcon from '@public/icons/arrowLeftIcon.svg';
import BookmarkSVGIcon from '@public/icons/bookmarkIcon.svg';
import BookmarkSVGIconColored from '@public/icons/bookmarkIconColored.svg';
import UploadPDFSVGIcon from '@public/icons/uploadPDFIcon.svg';
import PdfFileSVGIcon from '@public/icons/pdfFileIcon.svg';
import PdfFileSVGWhiteIcon from '@public/icons/pdfFileIconWhite.svg';
import DownloadSVGIcon from '@public/icons/downloadIcon.svg';
import LogoutSVGIcon from '@public/icons/logoutIcon.svg';
import QuickReplySVGIcon from '@public/icons/quickReplyIcon.svg';
import EditIconV2SVGIcon from '@public/icons/editIconV2.svg';
import PlusIconV2SVGIcon from '@public/icons/plusIconV2.svg';
import ImageSVGIcon from '@public/icons/imageIcon.svg';
import ImageWhiteSVGIcon from '@public/icons/imageIconWhite.svg';
import ThumpsUpSVGIcon from '@public/icons/thumbsUpIcon.svg';
import ThumbsUpSVGIconColored from '@public/icons/thumbsUpIconColored.svg';
import ForwardEmailSVGIcon from '@public/icons/forwardEmailIcon.svg';
import MoreOptionsSVGIcon from '@public/icons/moreOptionsIcon.svg';
import PinSVGIcon from '@public/icons/pinIcon.svg';
import TopPicksSVGIcon from '@public/icons/topPicksIcon.svg';
import SearchLongSVGIcon from '@public/icons/searchIconLong.svg';
import OverviewProfilePicSVGIcon from '@public/icons/overviewPicFallbackIcon.svg';
import ChevronRightSVGIcon from '@public/icons/chevronRightIcon.svg';
import OverviewEducationSVGIcon from '@public/icons/overviewEducationFallbackIcon.svg';
import OverviewCompanySVGIcon from '@public/icons/overviewCompanyFallbackIcon.svg';
import TickSVGIconColored from '@public/icons/tickColoredIcon.svg';
import MessageSVGIcon from '@public/icons/messageIcon.svg';
import BookMarkEmptyStateSVGIcon from '@public/icons/bookmarkEmptyStateIcon.svg';
import NewTabEmptyStateSVGIcon from '@public/icons/newTabEmptyStateIcon.svg';
import MinusSVGIcon from '@public/icons/minusIcon.svg';
import ThreedotsSVGIcon from '@public/icons/threedot.svg';
import HighlyFollowSVGIcon from '@public/icons/highFollowIcon.svg';
import GraduateSVGIcon from '@public/icons/graduateIcon.svg';
import EmployeeSVGIcon from '@public/icons/employeeIcon.svg';
import CheckSVGIconV2 from '@public/icons/checkIconV2.svg';
import QuicKReplyFilledSVGIcon from '@public/icons/quickReplyFilledIcon.svg';
import BookmarkBoldSVGIcon from '@public/icons/BookmarkBoldIcon.svg';
import ErrorSVGIcon from '@public/icons/errorIcon.svg';
import TickBoldIconSVG from '@public/icons/tickBoldIcon.svg';
import ArchiveBoxIconSVG from '@public/icons/archiveBoxIcon.svg';
import FilterCloseSVGIcon from '@public/icons/filterCloseIcon.svg';
import SparkleSVGIcon from '@public/icons/sparkleIcon.svg';
import EyeClosedSVGIcon from '@public/icons/eyeClosedIcon.svg';
import SortFilterSVGIcon from '@public/icons/sortFilterIcon.svg';
import CaretUpDownSVGIcon from '@public/icons/caretUpDownIcon.svg';
import UndoSVGIcon from '@public/icons/undoIcon.svg';
import SwipeLeftSVGIcon from '@public/icons/swipeLeftIcon.svg';
import SwipeRightSVGIcon from '@public/icons/swipeRightIcon.svg';
import EyeSVGIcon from '@public/icons/eyeIcon.svg';
import RowsSVGIcon from '@public/icons/rowsIcon.svg';
import SlideshowSVGIcon from '@public/icons/slideshowIcon.svg';
import EditIconV3SVGIcon from '@public/icons/editIconV3.svg';
import GreenTickSVGIcon from '@public/icons/greenTickIcon.svg';
import ArrowDownSVGIconV2 from '@public/icons/arrowDownIconV2.svg';
import GoogleTextSVGIcon from '@public/icons/googleTextIcon.svg';
import VerifiedSVGIcon from '@public/icons/verifiedIcon.svg';
import EditSVGIconV4 from '@public/icons/editIconV4.svg';
import DeleteSVGIconV2 from '@public/icons/deleteIconV2.svg';
import TickSVGIconBoldV2 from '@public/icons/tickIconBoldV2.svg';
import ArrowRightSVGIcon from '@public/icons/arrowRightIcon.svg';
import LabelSVGIcon from '@public/icons/labelIcon.svg';
import BlockIconSVGIcon from '@public/icons/block.svg';
import LabelColorSVGIcon from '@public/icons/labelColorIcon.svg';
import ForwardToEmailSVGIconV2 from '@public/icons/forwardToEmailIcon.svg';
import LabelTickIconSVG from '@public/icons/labelTickIcon.svg';
import ListViewSVGIcon from '@public/icons/listViewIcon.svg';
import SwipeViewSVGIcon from '@public/icons/swipeViewIcon.svg';
import arrowBendUpLeftSVGIcon from '@public/icons/arrowBendUpLeftIcon.svg';
import ThreeDotsSVGIconV2 from '@public/icons/threeDotsIconV2.svg';
import InfoSVGIconColored from '@public/icons/infoIconColored.svg';
import ErrorSVGIconV2 from '@public/icons/errorIconV2.svg';
import ResyncSVGIcon from '@public/icons/resync.svg';
import UnverifiedSVGIconV2 from '@public/icons/UnverifiedIconV2.svg';

export const GoogleIcon = withIconStyles(GoogleSVGIcon);
export const InfoIcon = withIconStyles(InfoSVGIcon);
export const ArrowUpRightIcon = withIconStyles(ArrowUpRightSVGIcon);
export const LinkedInIcon = withIconStyles(LinkedInSVGIcon);
export const AlertIcon = withIconStyles(AlertSVGIcon);
export const DropDownIcon = withIconStyles(DropDownSVGIcon);
export const CheckIcon = withIconStyles(CheckSVGIcon);
export const SearchIcon = withIconStyles(SearchSVGIcon);
export const PlusIcon = withIconStyles(PlusSVGIcon);
export const CopyIcon = withIconStyles(CopySVGIcon);
export const LinkIcon = withIconStyles(LinkSVGIcon);
export const OpenNewTabIcon = withIconStyles(OpenNewTabSVGIcon);
export const SendIcon = withIconStyles(SendSVGIcon);
export const CloseIcon = withIconStyles(CloseSVGIcon);
export const PartyIcon = withIconStyles(PartySVGIcon);
export const NotFoundIcon = withIconStyles(NotFoundSVGIcon);
export const AccountNotFoundIcon = withIconStyles(AccountNotFundSVGIcon);
export const CheckCircleIcon = withIconStyles(CheckCircleSVGIcon);
export const LogoTextIcon = withIconStyles(LogoTextSVGIcon);
export const MainPageLogoTextMobileIcon = withIconStyles(MainPageLogoTextSVGMobileIcon);
export const UnverifiedIcon = withIconStyles(UnverifiedSVGLogo);
export const EditIcon = withIconStyles(EditSVGIcon);
export const DragIcon = withIconStyles(DragSVGIcon);
export const DeleteIcon = withIconStyles(DeleteSVGIcon);
export const UserIcon = withIconStyles(UserSVGIcon);
export const SettingsIcon = withIconStyles(SettingsSVGIcon);
export const ArrowLeftIcon = withIconStyles(ArrowLeftSVGIcon);
export const BookmarkIcon = withIconStyles(BookmarkSVGIcon);
export const UploadPDFIcon = withIconStyles(UploadPDFSVGIcon);
export const PdfFileIcon = withIconStyles(PdfFileSVGIcon);
export const PdfFileWhiteIcon = withIconStyles(PdfFileSVGWhiteIcon);
export const DownloadIcon = withIconStyles(DownloadSVGIcon);
export const LogoutIcon = withIconStyles(LogoutSVGIcon);
export const QuickReplyIcon = withIconStyles(QuickReplySVGIcon);
export const EditIconV2 = withIconStyles(EditIconV2SVGIcon);
export const PlusIconV2 = withIconStyles(PlusIconV2SVGIcon);
export const ImageIcon = withIconStyles(ImageSVGIcon);
export const ImageWhiteIcon = withIconStyles(ImageWhiteSVGIcon);
export const ThumpsUpIcon = withIconStyles(ThumpsUpSVGIcon);
export const ThumpsUpIconColored = withIconStyles(ThumbsUpSVGIconColored);
export const ForwardEmailIcon = withIconStyles(ForwardEmailSVGIcon);
export const MoreOptionsIcon = withIconStyles(MoreOptionsSVGIcon);
export const PinIcon = withIconStyles(PinSVGIcon);
export const TopPicksIcon = withIconStyles(TopPicksSVGIcon);
export const SearchIconLong = withIconStyles(SearchLongSVGIcon);
export const OverviewProfilePicIcon = withIconStyles(OverviewProfilePicSVGIcon);
export const ChevronRightIcon = withIconStyles(ChevronRightSVGIcon);
export const OverviewEducationIcon = withIconStyles(OverviewEducationSVGIcon);
export const OverviewCompanyIcon = withIconStyles(OverviewCompanySVGIcon);
export const BookmarkIconColored = withIconStyles(BookmarkSVGIconColored);
export const TickIconColored = withIconStyles(TickSVGIconColored);
export const MessageIcon = withIconStyles(MessageSVGIcon);
export const BookMarkEmptyStateIcon = withIconStyles(BookMarkEmptyStateSVGIcon);
export const NewTabEmptyStateIcon = withIconStyles(NewTabEmptyStateSVGIcon);
export const MinusIcon = withIconStyles(MinusSVGIcon);
export const HighlyFollowIcon = withIconStyles(HighlyFollowSVGIcon);
export const GraduateIcon = withIconStyles(GraduateSVGIcon);
export const EmployeeIcon = withIconStyles(EmployeeSVGIcon);
export const CheckIconV2 = withIconStyles(CheckSVGIconV2);
export const QuicKReplyFilledIcon = withIconStyles(QuicKReplyFilledSVGIcon);
export const BookmarkBoldIcon = withIconStyles(BookmarkBoldSVGIcon);
export const ErrorIcon = withIconStyles(ErrorSVGIcon);
export const TickBoldIcon = withIconStyles(TickBoldIconSVG);
export const ArchiveBoxIcon = withIconStyles(ArchiveBoxIconSVG);
export const FilterCloseIcon = withIconStyles(FilterCloseSVGIcon);
export const SparkleIcon = withIconStyles(SparkleSVGIcon);
export const EyeClosedIcon = withIconStyles(EyeClosedSVGIcon);
export const SortFilterIcon = withIconStyles(SortFilterSVGIcon);
export const CaretUpDownIcon = withIconStyles(CaretUpDownSVGIcon);
export const UndoIcon = withIconStyles(UndoSVGIcon);
export const SwipeLeftIcon = withIconStyles(SwipeLeftSVGIcon);
export const SwipeRightIcon = withIconStyles(SwipeRightSVGIcon);
export const EyeIcon = withIconStyles(EyeSVGIcon);
export const RowsIcon = withIconStyles(RowsSVGIcon);
export const SlideshowIcon = withIconStyles(SlideshowSVGIcon);
export const EditIconV3 = withIconStyles(EditIconV3SVGIcon);
export const GreenTickIcon = withIconStyles(GreenTickSVGIcon);
export const ArrowDownIconV2 = withIconStyles(ArrowDownSVGIconV2);
export const GoogleTextIcon = withIconStyles(GoogleTextSVGIcon);
export const VerifiedIcon = withIconStyles(VerifiedSVGIcon);
export const EditIconV4 = withIconStyles(EditSVGIconV4);
export const DeleteIconV2 = withIconStyles(DeleteSVGIconV2);
export const TickIconBoldV2 = withIconStyles(TickSVGIconBoldV2);
export const ArrowRightIcon = withIconStyles(ArrowRightSVGIcon);
export const LabelIcon = withIconStyles(LabelSVGIcon);
export const LabelColorIcon = withIconStyles(LabelColorSVGIcon);
export const ForwardToEmailIconV2 = withIconStyles(ForwardToEmailSVGIconV2);
export const LabelTickIcon = withIconStyles(LabelTickIconSVG);
export const ThreedotsIcon = withIconStyles(ThreedotsSVGIcon);
export const BlockIconSVG = withIconStyles(BlockIconSVGIcon);
export const ListViewIcon = withIconStyles(ListViewSVGIcon);
export const SwipeViewIcon = withIconStyles(SwipeViewSVGIcon);
export const ArrowBendUpLeftIcon = withIconStyles(arrowBendUpLeftSVGIcon);
export const ThreeDotsIconV2 = withIconStyles(ThreeDotsSVGIconV2);
export const InfoIconColored = withIconStyles(InfoSVGIconColored);
export const ErrorIconV2 = withIconStyles(ErrorSVGIconV2);
export const ResyncIcon = withIconStyles(ResyncSVGIcon);
export const UnverifiedIconV2 = withIconStyles(UnverifiedSVGIconV2);
