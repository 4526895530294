import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
	UserLinkedProfileInMetaData,
	isAccountBlockedFunc,
	isAccountPendingFunc,
	isAccountRejectedFunc,
	isAccountVerifiedFunc,
	isProUserFunc,
} from '@src/models/user';
import {
	ACCESS_TOKEN,
	GOOGLE_OAUTH_TOKEN_PARAM,
	INBOX_PATH,
	MAIN_PATH,
	mainFiltersArr,
	mixPanelEvents,
} from '@utils/constants';
import { AppDispatch } from '@store/index';
import { clearRootState } from '@utils/store';
import { IOnboardingProps, TOnboardingStates } from './Onboarding.types';
import AccountUnderReview from '@components/AccountUnderReview';
import {
	clearDraftData,
	getUserDetailsFromGlobalState,
	setOnboardingInputsState,
	setUserData,
} from '@store/userDetailsSlice/userDetailsSlice';
import classes from './Onboarding.styles.module.scss';
import Text from '@components/ui/Text';
import Linkify from 'linkify-react';
import MessageStep from './MessageStep';
import useWindowSize from '@hooks/useWindow';
import LoginStep from './LoginStep';
import mixpanelActions from '@utils/mixpanel';
import LinkedInStep from './LinkedInStep';
import StepMiniInfo from './StepMiniInfo';
import AccountCreatedStep from './AccountCreatedStep';
import DotsLoader from '@components/ui/DotsLoader';
import Button from '@components/ui/Button';
import Avatar from '@components/ui/Avatar';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';
import SlidingText from './SlidingText';
import { AccountNotFoundIcon, VerifiedIcon } from '@src/hoc/withIconStyles';
import { loginUser } from '@api/auth';
import useFetch from '@hooks/useFetch';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import Toast from '@components/ui/Toast';
import AuthModal from '@components/AuthModal';
import { IAuthModalProps } from '@components/AuthModal/AuthModal.types';
import { IUserLoginPayload } from '@api/auth/auth.types';
import { setLocalStorage } from '@utils/localStorage';
import { getUserDetails } from '@api/user';
import { setMainFilters } from '@store/inboxSlice/inboxSlice';
import EducationModal from './EducationModal';

function Onboarding({
	receiverFirstName,
	receiverFullName,
	receiverInboxCategoriesMap,
	receiverIntroUpdatesTextArr,
	receiverPreferences,
	receiverProfilePicURL,
	receiverUserName,
	redirectURL,
	receiverMaxCharCountEnabled,
	receiverMinCharCountEnabled,
}: IOnboardingProps) {
	const navigate = useNavigate();
	const { isMobile } = useWindowSize();
	const dispatch = useDispatch<AppDispatch>();

	const [searchParams, setSearchParams] = useSearchParams();

	const shouldResetOnBack = useRef<boolean>(false);
	const introPageShownTriggered = useRef<boolean>(false);

	const {
		data: userDetails,
		draftData: userDraftData,
		onboardingInputsState,
	} = useSelector(getUserDetailsFromGlobalState);

	const [showEducationModal, setShowEducationModal] = useState(false);

	// app login section states
	const googleOAuthToken = React.useRef(
		decodeURIComponent(searchParams.get(GOOGLE_OAUTH_TOKEN_PARAM) ?? '')
	);
	const [showSignInModal, setShowSignInModal] = useState(!!googleOAuthToken.current);
	const [modalToastError, setModalToastError] = useState('');
	const {
		callApi: login,
		status: loginStatus,
		errorStatus: loginErrorStatus,
	} = useFetch(loginUser);
	const { callApi: fetchUserDetails, status: fetchUserDetailsStatus } = useFetch(getUserDetails);
	const toastRef = React.useRef<IRefProps>(null);

	const [onBoardingStates, setOnBoardingStates] = useState<TOnboardingStates | null>();

	// intro section states
	const [slideReceiverIntroUpdates, setSlideReceiverIntroUpdates] = useState<boolean>(true);

	// google login section states
	const [googleOAuthCode, setGoogleLoginOAuthCode] = useState<string | null>(null);
	const [otp, setOTP] = useState<number | null>(null);
	const [email, setEmail] = useState<string | null>(null);

	// linkedIn section states
	const [linkedInProfileMetaData, setLinkedInProfileMetaData] =
		useState<UserLinkedProfileInMetaData | null>(null);
	const [linkedInURL, setLinkedInURL] = useState<string | null>(null);

	const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
	const [isSendingMessageState, setIsSendingMessageState] = useState<boolean>(false);

	const isLoadingSignIn = loginStatus === 'loading' || fetchUserDetailsStatus === 'loading';

	const disableMessageAnimationRef = useRef<boolean>(false);
	const disableLoginAnimationRef = useRef<boolean>(false);
	const disableLinkedInAnimationRef = useRef<boolean>(false);

	shouldResetOnBack.current = !userDetails;

	const displayLoginStep = onBoardingStates === 'GOOGLE_LOGIN';
	const displayLinkedInStep = onBoardingStates === 'CONNECT_LINKED_IN';
	const displayMessageStep = onBoardingStates === 'MESSAGE';

	const displayOnboardingSteps = displayMessageStep || displayLoginStep || displayLinkedInStep;

	const displayMessageStepInfo = displayLoginStep || displayLinkedInStep;
	const displayLoginStepInfo = displayLinkedInStep;

	const handleSendMessage = (
		selectedInboxCategory: string | null,
		message: string,
		selectedPdfFile: File | null
	) => {
		dispatch(
			setOnboardingInputsState({
				selectedCategory: selectedInboxCategory,
				message: message,
				selectedPdfFile: selectedPdfFile,
			})
		);

		if (userDraftData) {
			setOnBoardingStates('CONNECT_LINKED_IN');
		} else {
			setOnBoardingStates('GOOGLE_LOGIN');
		}
	};

	const handleMessageStepEditBtnClick = () => {
		if (isLoadingState) return;
		mixpanelActions.trackOnboarding(
			mixPanelEvents.INTRO_PAGE_MESSAGE_EDIT_CLICKED,
			receiverUserName
		);
		setOnBoardingStates('MESSAGE');
	};

	const resetOnboardingOnRegisterError = () => {
		dispatch(clearDraftData());

		// reset google login onboarding states
		setGoogleLoginOAuthCode(null);
		setOTP(null);
		setEmail(null);

		// reset linkedIn onboarding states
		setLinkedInProfileMetaData(null);
		setLinkedInURL(null);
	};

	const handleClickSuperDMText = () => {
		if (isLoadingState) return;
		mixpanelActions.trackOnboarding(mixPanelEvents.INTRO_PAGE_SUPER_DM_CLICKED, receiverUserName);
		setShowEducationModal(true);
	};

	const handleCloseEducationModal = () => {
		setShowEducationModal(false);
	};

	const handleLoginBtnClick = () => {
		if (isLoadingState) return;
		mixpanelActions.trackOnboarding(mixPanelEvents.INTRO_PAGE_LOGIN_CLICKED, receiverUserName);
		setShowSignInModal(true);
		mixpanelActions.trackOnboarding(mixPanelEvents.INTRO_PAGE_EMAIL_MODAL_SHOWN, receiverUserName);
	};

	const handleCloseModal = () => {
		setShowSignInModal(false);
		setModalToastError('');
	};

	const handleModalSignInSuccess: IAuthModalProps['onSuccessAuth'] = async ({
		googleOAuthCode,
		email,
		otp,
	}) => {
		toastRef.current?.unPublish();
		setModalToastError('');
		const payload: IUserLoginPayload = {
			email: email ?? null,
			login_type: googleOAuthCode ? 'GOOGLE' : 'EMAIL',
			otp: otp ?? null,
			remember_me: true,
			token_id: googleOAuthCode ?? null,
		};

		try {
			const resData = await login(payload);
			const jwtToken = resData?.jwtToken ?? null;

			if (jwtToken) {
				setLocalStorage(ACCESS_TOKEN, jwtToken);
				const data = await fetchUserDetails(jwtToken);
				if (data) {
					mixpanelActions.identifyUserAndSetEmail({
						email: data.email,
						username: data.username,
						userType: data.userType,
						userID: data.userId,
					});
					mixpanelActions.registerSecondaryUser(
						data.isSecondaryUser ? 'yes' : 'no',
						data.secondaryUserEmail
					);
					dispatch(setUserData({ userData: data ? { ...data } : null }));
					isProUserFunc(data) &&
						dispatch(
							setMainFilters({
								filtersToPrepend: [...mainFiltersArr],
							})
						);
				}
			} else {
				toastRef.current?.publish();
			}
			setShowSignInModal(false);
			// can check draft user condition check to show model to alert user to register instead of login
		} catch (error) {
			// handle error

			if (loginErrorStatus === 403) {
				setModalToastError('Too many attempts!');
				return;
			}

			setModalToastError(error);
		}
	};

	useEffect(() => {
		if (googleOAuthToken.current) {
			handleModalSignInSuccess({ googleOAuthCode: googleOAuthToken.current });
			setSearchParams((prevSearchParams) => {
				const searchParamsToSet = new URLSearchParams(prevSearchParams);
				searchParamsToSet.delete(GOOGLE_OAUTH_TOKEN_PARAM);
				return searchParamsToSet;
			});
		}
	}, []);

	useEffect(() => {
		return () => {
			if (shouldResetOnBack.current) {
				clearRootState();
			}
		};
	}, []);

	useEffect(() => {
		if (!receiverUserName || !displayOnboardingSteps || introPageShownTriggered.current) return;
		mixpanelActions.trackOnboarding(mixPanelEvents.INTRO_PAGE_SHOWN, receiverUserName);
		introPageShownTriggered.current = true;
	}, [receiverUserName, displayOnboardingSteps]);

	useEffect(() => {
		const isDraftUser = !!userDraftData;

		if (!userDetails && !isDraftUser && receiverUserName) {
			// no user exist show google oauth sign up
			setOnBoardingStates('MESSAGE');
		} else if (isDraftUser && !userDetails) {
			// draft user means users signed up via google but haven't connected linked in account'
			setOnBoardingStates('CONNECT_LINKED_IN');
		} else if (isAccountRejectedFunc(userDetails)) {
			// account is rejected or under manual verification for connected linked in account
			setOnBoardingStates('ACCOUNT_UNDER_REVIEW');
		} else if (isAccountBlockedFunc(userDetails)) {
			// account is blocked by other user
			setOnBoardingStates('ACCOUNT_BLOCKED');
		} else if (
			(isAccountVerifiedFunc(userDetails) || isAccountPendingFunc(userDetails)) &&
			isDraftUser
		) {
			// using draft user as a condition to check if user entered first time or not, to show account created screen
			setOnBoardingStates('ACCOUNT_CREATED');
		} else if (
			(isAccountVerifiedFunc(userDetails) || isAccountPendingFunc(userDetails)) &&
			redirectURL
		) {
			// account is created but not got verified by linked oauth or verified
			window.history.replaceState({ idx: 0 }, '');
			navigate(redirectURL, { replace: true });
		} else if (isAccountVerifiedFunc(userDetails) || isAccountPendingFunc(userDetails)) {
			// account is created but not got verified by linked oauth or verified
			navigate(INBOX_PATH, { replace: true });
		} else {
			navigate(MAIN_PATH, { replace: true });
		}
	}, [userDetails, userDraftData, redirectURL, receiverUserName, navigate]);

	if (onBoardingStates === 'ACCOUNT_BLOCKED' || onBoardingStates === 'ACCOUNT_UNDER_REVIEW') {
		return <AccountUnderReview />;
	}

	if (onBoardingStates === 'ACCOUNT_CREATED') {
		return <AccountCreatedStep redirectURL={redirectURL} receiverUsername={receiverUserName} />;
	}

	const receiverProfileContainer = (
		<div className={classes.receiverProfileContainer}>
			<Avatar
				size={isMobile ? 6.4 : 8}
				profilePicURL={receiverProfilePicURL}
				fallbackIcon={FallbackPicIcon}
			/>

			<div className={classes.nameWithIcon}>
				<Text variant="h2" semiBold medium lineHeight={2}>
					{receiverFullName}
				</Text>
				<VerifiedIcon size={2} />
			</div>

			{!!receiverIntroUpdatesTextArr.length && (
				<div className={classes.textWithBlinkContainer}>
					<Text variant="span" customClass={classes.updatesBlink} />
					<SlidingText shouldSlide={slideReceiverIntroUpdates}>
						{receiverIntroUpdatesTextArr.map((text, index) => (
							<Text variant="p" key={index} customClass={classes.updatesText}>
								{text}
							</Text>
						))}
					</SlidingText>
				</div>
			)}
		</div>
	);

	const loginBtn = (
		<Button
			btnText={
				<Text variant="span" tertiary>
					{'Login'}
				</Text>
			}
			onClick={handleLoginBtnClick}
			customClass={classes.loginBtn}
		/>
	);

	return (
		<main className={classes.onboardingMainLayout}>
			<div
				className={clsx(
					classes.onboardingMainLayoutHeader,
					!googleOAuthToken.current && classes.onboardingMainLayoutHeaderAnimation,
					!!googleOAuthToken.current && classes.onboardingMainLayoutHeaderNoAnimation
				)}
			>
				<Text variant={'h1'}>
					{`Send ${receiverFirstName} a `}
					<Button
						btnText={
							<Text variant="span" tertiary>
								{'SuperDM'}
							</Text>
						}
						onClick={handleClickSuperDMText}
					/>
				</Text>
				{isMobile && loginBtn}
			</div>
			<div
				className={clsx(
					classes.onboardingLayout,
					!googleOAuthToken.current && classes.onboardingLayoutAnimation,
					!!googleOAuthToken.current && classes.onboardingLayoutNoAnimation
				)}
			>
				{!isMobile && (
					<section className={classes.receiverInfoSection}>
						{receiverProfileContainer}
						<Text variant="p" tertiary tiny lineHeight={1.6} light>
							{'Already sent a message? '}
							{loginBtn}
						</Text>
					</section>
				)}
				<section className={classes.onboardingStepsSection}>
					<div className={classes.introMessageContainer}>
						{isMobile && receiverProfileContainer}
						{!isMobile && <Text variant={'h3'}>{receiverFullName}</Text>}
						<Text variant="p">
							<Linkify options={{ target: '_blank' }}>{receiverPreferences}</Linkify>
						</Text>
					</div>
					{displayOnboardingSteps && (
						<div className={classes.onboardingStepsContainer}>
							{isSendingMessageState && (
								<div className={classes.sendDMStepContainer}>
									<DotsLoader
										customDesktopSize={60}
										customMobileSize={60}
										customMargin={'-30px 0 0 0'}
									/>
									<Text variant="p" lineHeight={1.6} tiny>
										{'Sending your DM'}
									</Text>
								</div>
							)}
							{displayMessageStep && (
								<MessageStep
									inboxCategoriesMap={receiverInboxCategoriesMap}
									onSendMessage={handleSendMessage}
									receiverUsername={receiverUserName}
									receiverFirstName={receiverFirstName ?? receiverUserName}
									maxCharCountEnabled={receiverMaxCharCountEnabled}
									minCharCountEnabled={receiverMinCharCountEnabled}
									message={onboardingInputsState?.message ?? ''}
									selectedPdfFile={onboardingInputsState?.selectedPdfFile ?? null}
									selectedInboxCategory={onboardingInputsState?.selectedCategory ?? null}
									onInputActivityChange={(isActive) => {
										setSlideReceiverIntroUpdates(!isActive);
									}}
								/>
							)}
							{displayMessageStepInfo && (
								<StepMiniInfo
									infoText={onboardingInputsState?.message ?? ''}
									onClickEditBtn={handleMessageStepEditBtnClick}
									shouldTrimInfoText={true}
									withAnimation={!disableMessageAnimationRef.current}
									onAnimationEnd={() => {
										disableMessageAnimationRef.current = true;
									}}
									onDisplayInfo={() => {
										mixpanelActions.trackOnboarding(
											mixPanelEvents.INTRO_PAGE_STEP_ONE_SUCCESS,
											receiverUserName
										);
									}}
								/>
							)}
							{displayLoginStep && (
								<LoginStep
									withAnimation={!disableLoginAnimationRef.current}
									receiverUsername={receiverUserName}
									receiverName={receiverFirstName ?? receiverUserName}
									onAuthDataAvailable={(email, googleOAuthCode, otp) => {
										setEmail(email);
										setGoogleLoginOAuthCode(googleOAuthCode);
										setOTP(otp);
									}}
									initialEmail={email}
									setLoadingState={setIsLoadingState}
									message={onboardingInputsState?.message ?? ''}
									selectedInboxCategory={onboardingInputsState?.selectedCategory ?? null}
								/>
							)}
							{displayLoginStepInfo && (
								<StepMiniInfo
									infoText={email ?? userDraftData?.email ?? ''}
									withAnimation={!disableLoginAnimationRef.current}
									onAnimationEnd={() => {
										disableLoginAnimationRef.current = true;
									}}
									onDisplayInfo={() => {
										mixpanelActions.trackOnboarding(
											mixPanelEvents.INTRO_PAGE_STEP_TWO_SUCCESS,
											receiverUserName
										);
									}}
								/>
							)}
							{displayLinkedInStep && (
								<LinkedInStep
									withAnimation={!disableLinkedInAnimationRef.current}
									receiverUsername={receiverUserName}
									receiverName={receiverFirstName ?? receiverUserName}
									message={onboardingInputsState?.message ?? ''}
									selectedPdfFile={onboardingInputsState?.selectedPdfFile ?? null}
									selectedInboxCategory={onboardingInputsState?.selectedCategory ?? null}
									googleOAuthCode={googleOAuthCode}
									email={email}
									otp={otp}
									initialLinkedInProfileMetaData={linkedInProfileMetaData}
									initialLinkedInURL={linkedInURL}
									setLoadingState={setIsLoadingState}
									onRegisterError={resetOnboardingOnRegisterError}
									setSendingMessageState={setIsSendingMessageState}
									draftJWTToken={userDraftData?.draftJWTToken ?? null}
									onLinkedInMetaDataAvailable={(linkedInMetaData, linkedInURL) => {
										setLinkedInProfileMetaData(linkedInMetaData);
										setLinkedInURL(linkedInURL);
									}}
								/>
							)}
						</div>
					)}
				</section>
			</div>
			{showEducationModal && (
				<EducationModal showModal={showEducationModal} onClose={handleCloseEducationModal} />
			)}
			{showSignInModal && (
				<AuthModal
					modalHeader={'Sign in to SuperDM'}
					onCloseModal={handleCloseModal}
					showModal={showSignInModal}
					authType="LOGIN"
					onSuccessAuth={handleModalSignInSuccess}
					modalError={modalToastError}
					isLoadingAuth={isLoadingSignIn}
					onClickContinueEmailBtn={(inputEmail) => {
						mixpanelActions.trackOnboardingLogin(
							mixPanelEvents.INTRO_PAGE_EMAIL_PROCEED_CLICKED,
							inputEmail
						);
					}}
					onClickGoogleBtn={() => {
						mixpanelActions.trackOnboardingLogin(mixPanelEvents.INTRO_PAGE_GOOGLE_BTN_CLICK);
					}}
					onOTPInputShown={() => {
						mixpanelActions.trackOnboardingLogin(mixPanelEvents.INTRO_PAGE_OTP_SHOWN);
					}}
					onOTPSubmitted={() => {
						mixpanelActions.trackOnboardingLogin(mixPanelEvents.INTRO_PAGE_EMAIL_OTP_SUBMITTED);
					}}
				/>
			)}
			<Toast
				ref={toastRef}
				toastType="ERROR"
				header="No Account Found"
				content="To create account, apply for early access"
				icon={<AccountNotFoundIcon size={1.8} />}
			/>
		</main>
	);
}

export default Onboarding;
