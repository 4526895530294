import React, { useEffect, useRef } from 'react';
import Text from '@components/ui/Text';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchPaginatedTabOverviewInboxData,
	getInboxDataFromGlobalState,
	setCurrChatsDataSelectedChatId,
} from '@store/inboxSlice/inboxSlice';
import { AppDispatch } from '@store/index';
import {
	CHAT_SEARCH_PARAM,
	FETCH_CHATS_DATA_PAGE_SIZE,
	mixPanelEvents,
	UNREAD,
} from '@utils/constants';
import UserInfo from '@components/ui/UserInfo';
import {
	getSelectedChatDetailsFromGlobalState,
	setSelectedChat,
} from '@store/selectedChatSlice/selectedChatSlice';
import { SenderChatData, getFullNameSenderProfileFunc } from '@src/models/inbox';
import { useNavigate } from 'react-router-dom';
import { getAmPmTimeFromUTC, getMonthDayFromUTC, isTodayTime } from '@utils/date';
import clsx from 'clsx';
import mixpanelActions from '@utils/mixpanel';
import NoResultsFound from '@components/NoResultsFound';
import classes from './OverviewInbox.styles.module.scss';
import useWindowSize from '@hooks/useWindow';
import InboxError from '@components/InboxError';
import { IOverviewInboxProps } from './OverviewInbox.types';
import { getEmptyStatesData } from '@utils/common';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';

function OverviewInbox({ overviewMode, filtersDisplayed }: IOverviewInboxProps) {
	const loadingRef = useRef<HTMLDivElement | null>(null);
	const {
		tabOverviewChatsData,
		tabOverviewFetchStatus,
		tabOverviewPaginationDetails,
		currChatsDataSelectedChatId,
		currChatsDataSelectedChatIndex,
		currChatsDataSelectedChatInboxType,
		currReadChatsMap,
		currReportChatsMap,
		currBlockChatsMap,
		currAssignedLabelsChatsMap,
		selectedMainFilter,
		selectedSingleFilters,
	} = useSelector(getInboxDataFromGlobalState);
	const { selectedChat } = useSelector(getSelectedChatDetailsFromGlobalState);

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const dispatch = useDispatch<AppDispatch>();

	const navigate = useNavigate();
	const { isMobile } = useWindowSize();

	const chatIdRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

	const isLoading = tabOverviewFetchStatus === 'loading';
	const isLoadingPaginationData = tabOverviewPaginationDetails?.hasMoreChats ?? false;

	const showEmptyState = !isLoading && !tabOverviewChatsData.length;

	const showError = tabOverviewFetchStatus === 'error';

	const pageSize = tabOverviewPaginationDetails?.pageSize ?? FETCH_CHATS_DATA_PAGE_SIZE;

	const userPersonalLabels = userDetails?.personalLabels ?? [];

	useEffect(() => {
		if (
			!selectedChat &&
			currChatsDataSelectedChatId &&
			currChatsDataSelectedChatInboxType === 'TAB_OVERVIEW_VIEW'
		) {
			chatIdRefs.current[currChatsDataSelectedChatId]?.scrollIntoView({
				behavior: 'instant',
				block: 'center',
			});
		}
	}, [selectedChat]);

	useEffect(() => {
		if (
			!overviewMode &&
			currChatsDataSelectedChatId &&
			currChatsDataSelectedChatInboxType === 'TAB_OVERVIEW_VIEW'
		) {
			chatIdRefs.current[currChatsDataSelectedChatId]?.scrollIntoView({
				behavior: 'instant',
				block: 'start',
			});
		}
	}, [overviewMode]);

	useEffect(() => {
		const obsElement = loadingRef.current;

		if (!obsElement || !tabOverviewChatsData) return;

		const obsOptions = {
			root: null,
			threshold: 0.5,
		};

		const obsCallback = (entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;

			if (entry.isIntersecting) {
				dispatch(
					fetchPaginatedTabOverviewInboxData({
						lastFiveMessagesRequired: selectedSingleFilters[UNREAD] === UNREAD && isMobile,
					})
				);
			}
		};

		const observer = new IntersectionObserver(obsCallback, obsOptions);
		observer.observe(obsElement);

		return () => {
			if (!obsElement) return;
			observer.unobserve(obsElement);
		};
	}, [tabOverviewChatsData]);

	const handleOnClickUser = (selectedChatData: SenderChatData) => {
		mixpanelActions.trackInboxChat(
			mixPanelEvents.INBOX_CHAT_OPENED,
			selectedChatData.senderDetails?.username ?? '',
			`${selectedChatData.read ? 'true' : 'false'}` ?? '',
			''
		);
		if (!selectedChatData.senderDetails) return;
		dispatch(
			setCurrChatsDataSelectedChatId({
				chatId: selectedChatData.chatId,
				index: tabOverviewChatsData.length,
				inboxType: 'TAB_OVERVIEW_VIEW',
			})
		);
		dispatch(setSelectedChat({ selectedChatData: selectedChatData }));

		isMobile &&
			navigate(`/message?${CHAT_SEARCH_PARAM}=${selectedChatData.senderDetails.username}`, {
				replace: !isMobile,
			});
	};

	if (showError) {
		return <InboxError />;
	}

	if (showEmptyState) {
		const emptyStateData = getEmptyStatesData({
			selectedMainFilterName: selectedMainFilter?.name,
			isMobile: isMobile,
			isFiltersDisplayed: filtersDisplayed,
			isUnreadFilterSelected: selectedSingleFilters[UNREAD] === UNREAD,
		});

		const Icon = emptyStateData.icon;

		return (
			<NoResultsFound
				heading={emptyStateData.heading}
				subText={emptyStateData.subText}
				styles={emptyStateData.containerStyles}
				icon={<Icon size={emptyStateData.iconSize} style={emptyStateData.iconStyles} />}
			/>
		);
	}

	const isSelectedChat = isMobile ? false : selectedChat;

	return (
		<div className={clsx(classes.pageContainer, isSelectedChat && classes.pageContainerMobile)}>
			{tabOverviewChatsData.map((chatData, index) => {
				const { senderDetails, chatId, label, lastMessage, read } = chatData;
				const senderProfileData = senderDetails?.profileData;
				const monthDayFormat = getMonthDayFromUTC(lastMessage?.createdAt ?? '');
				const timeAmPmFormat = getAmPmTimeFromUTC(lastMessage?.createdAt ?? '');
				const isTodayMessage = isTodayTime(lastMessage?.createdAt ?? '');

				const dateDisplay = isTodayMessage ? `${timeAmPmFormat ?? ''}` : `${monthDayFormat ?? ''}`;

				const disableAnimation =
					currChatsDataSelectedChatIndex !== null && index < currChatsDataSelectedChatIndex;

				const readStatus = currReadChatsMap[chatData.chatId ?? -1] ?? read;

				const chatReported = currReportChatsMap[chatData.chatId ?? -1] ?? false;
				const chatBlocked = currBlockChatsMap[chatData.chatId ?? -1] ?? false;

				const assignedPersonalLabels = (
					currAssignedLabelsChatsMap[chatData.chatId ?? -1] ?? chatData.personalLabels
				).filter((label) => {
					return userPersonalLabels.includes(label);
				});

				return (
					<div
						key={chatId}
						role="button"
						tabIndex={0}
						aria-label="click to chat"
						className={clsx(
							clsx(
								classes.chatRow,
								index === 0 && classes.firstChatRow,
								disableAnimation && classes.chatRowNoAnimation,
								(chatReported || chatBlocked) && classes.chatReported
							)
						)}
						onClick={(e) => {
							e.stopPropagation();
							handleOnClickUser(chatData);
						}}
						style={{
							animationDelay: disableAnimation ? 'unset' : `${(index % pageSize) * 0.05}s`,
						}}
						ref={(el) => {
							if (chatId) {
								chatIdRefs.current[chatId] = el;
							}
						}}
					>
						<UserInfo
							headline={senderProfileData?.headline ?? ''}
							name={senderProfileData ? getFullNameSenderProfileFunc(senderProfileData) : ''}
							readStatus={readStatus}
							profilePicURL={senderProfileData?.profilePicture}
							recentMessage={lastMessage?.content ?? ''}
							recentMessageDate={dateDisplay}
							assignedPersonalLabels={assignedPersonalLabels}
							isUserSelected={senderDetails?.username === selectedChat?.senderDetails?.username}
						/>

						{!isSelectedChat && (
							<>
								<Text variant="p" small ultraLight secondary customClass={classes.lastMessage}>
									{label && (
										<Text variant="span" light tiny customClass={classes.label}>
											{label}
										</Text>
									)}
									{lastMessage?.content ?? ''}
								</Text>
								<Text variant="span" tiny ultraLight tertiaryV0 customClass={classes.dateDisplay}>
									{dateDisplay}
								</Text>
							</>
						)}
					</div>
				);
			})}
			<div aria-hidden ref={loadingRef} className={classes.loadingContainer}>
				{isLoadingPaginationData && (
					<Text variant="p" ultraLight small secondary>
						{'Loading DMs...'}
					</Text>
				)}
			</div>
		</div>
	);
}

export default OverviewInbox;
