import React, { useState, useEffect, useRef, useMemo } from 'react';
import clsx from 'clsx';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import {
	CloseIcon,
	TickIconColored,
	AlertIcon,
	CheckCircleIcon,
	PlusIconV2,
	EditIconV4,
	DeleteIconV2,
} from '@src/hoc/withIconStyles';
import useFetch from '@hooks/useFetch';
import { getSecondaryUsers, editSecondaryUsers, getUserExtraCategories } from '@api/user';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/index';
import useWindowSize from '@hooks/useWindow';
import Modal from '@components/ui/Modal';
import { ICheckboxStates } from './TeamMembers.types';
import { isValidEmail } from '@utils/common';
import { setSecondaryUsers } from '@store/userDetailsSlice/userDetailsSlice';
import Toast from '@components/ui/Toast';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import classes from './TeamMembers.styles.module.scss';
import { SecondaryUser } from '@src/models/user';
import { IEditSecondaryUsersPayload, ISecondaryUser } from '@api/user/user.types';
import Loader from '@components/ui/Loader';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';

const TeamMembers = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { isMobile } = useWindowSize();

	// form data and errors
	const [formData, setFormData] = useState({
		name: '',
		email: '',
	});
	const [errors, setErrors] = useState({
		name: '',
		email: '',
		categories: '',
	});
	const inputsRefMaps = useRef<{ [key: string]: HTMLInputElement | null }>({});

	const [checkboxStates, setCheckboxStates] = useState<ICheckboxStates>({
		fullInboxAccess: true,
		accessToCategories: null,
	});

	const [editSecondaryUser, setEditSecondaryUser] = useState<SecondaryUser | null>(null);

	// toast states
	const [toastState, setToastState] = useState({ error: '', info: '' });
	const toastRef = useRef<IRefProps>(null);

	const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
	const [openSecondaryUserModal, setOpenSecondaryUserModal] = useState(false);

	const modalHeaderRef = useRef<HTMLDivElement | null>(null);
	const modalBtnsContainerRef = useRef<HTMLDivElement | null>(null);
	const formSectionRef = useRef<HTMLDivElement | null>(null);

	const { callApi: callFetchSecondaryUsers, status: callFetchSecondaryUsersStatus } =
		useFetch(getSecondaryUsers);
	const { callApi: callEditSecondaryUsersAPI, status: callEditSecondaryUserStatus } =
		useFetch(editSecondaryUsers);
	const {
		callApi: callFetchMoreCategories,
		status: callFetchMoreCategoriesStatus,
		response: callExtraCategoriesResponse,
	} = useFetch(getUserExtraCategories);

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const userId = userDetails?.userId;
	const secondaryUsers = useMemo(() => {
		return userDetails?.secondaryUsers ?? [];
	}, [userDetails]);
	const activeCategories =
		userDetails?.inboxCategories.map((inboxCategory) => inboxCategory.name) ?? [];

	const isLoadingSecondaryUsers = callFetchSecondaryUsersStatus === 'loading';
	const isLoadingEditSecondaryUsers = callEditSecondaryUserStatus === 'loading';
	const isLoadingExtraCategories = callFetchMoreCategoriesStatus === 'loading';
	const isExtraCategoriesLoaded = callFetchMoreCategoriesStatus === 'success';

	const isEditMode = !!editSecondaryUser;
	const displayCategoriesSection = !!activeCategories.length;

	const categoriesToDisplay = [...activeCategories, ...(callExtraCategoriesResponse ?? [])];

	const validate = () => {
		const isEmailAlreadyExists = secondaryUsers.some(
			(user) => user.email.toLocaleLowerCase() === formData.email.toLocaleLowerCase()
		);
		const isEmailValid = isValidEmail(formData.email);
		const isEmailEmpty = !formData.email.trim();

		const newErrors = {
			name: !formData.name.trim() ? 'Name cannot be empty' : '',
			email: isEmailEmpty
				? 'Email cannot be empty'
				: !isEmailValid
				? 'Enter valid email'
				: !isEditMode && isEmailAlreadyExists
				? 'Member already added'
				: '',
			categories:
				displayCategoriesSection &&
				!checkboxStates.fullInboxAccess &&
				!checkboxStates.accessToCategories?.length
					? 'Select at least one inbox'
					: '',
		};

		inputsRefMaps.current[newErrors.name ? 'name' : newErrors.email ? 'email' : '']?.focus();

		setErrors(newErrors);

		return !Object.values(newErrors).some((error) => !!error);
	};

	const handleMoreCategories = async () => {
		if (isLoadingEditSecondaryUsers || isLoadingExtraCategories || !userId) return;
		toastRef.current?.unPublish();

		mixpanelActions.trackTeamMember({ eventName: mixPanelEvents.SHOW_ALL_CATEGORIES_CLICKED });

		try {
			await callFetchMoreCategories(userId);
		} catch (error) {
			setToastState({ info: '', error: error });
			toastRef.current?.publish();
		}
	};

	const handleSecondaryUsers = async () => {
		const isDataValidate = validate();

		if (!isDataValidate || !userId || isLoadingEditSecondaryUsers) return;

		toastRef.current?.unPublish();

		try {
			const secondaryUserUpdateObj: ISecondaryUser = {
				name: formData.name,
				email: formData.email,
				full_inbox_access: checkboxStates.fullInboxAccess,
				access_to_labels: checkboxStates.accessToCategories,
			};

			let secondaryUsersToUpdate: ISecondaryUser[] = [
				...secondaryUsers.map((secondaryUser) => {
					if (
						isEditMode &&
						editSecondaryUser?.email.toLocaleLowerCase() === secondaryUser.email.toLocaleLowerCase()
					) {
						return { ...secondaryUserUpdateObj };
					} else {
						return {
							email: secondaryUser.email,
							name: secondaryUser.name,
							access_to_labels: secondaryUser.accessToLabels,
							full_inbox_access: secondaryUser.fullInboxAccess,
						};
					}
				}),
			];

			if (!isEditMode) {
				secondaryUsersToUpdate = [secondaryUserUpdateObj, ...secondaryUsersToUpdate];
			}

			const payload: IEditSecondaryUsersPayload = {
				user_id: userId,
				secondary_user_list: secondaryUsersToUpdate,
			};

			const updatedSecondaryUsers = await callEditSecondaryUsersAPI(payload);

			dispatch(setSecondaryUsers({ secondaryUsers: updatedSecondaryUsers }));
			handleCloseAddNewMemberModal();
			setToastState({ error: '', info: isEditMode ? 'Member updated' : 'Invite sent' });
			toastRef.current?.publish();

			if (isEditMode) {
				mixpanelActions.trackTeamMember({
					eventName: mixPanelEvents.EDIT_TEAM_MEMBER_SUCCESS,
				});
			} else {
				mixpanelActions.trackTeamMember({
					eventName: mixPanelEvents.ADD_TEAM_MEMBER_SUCCESS,
				});
			}
		} catch (error) {
			setToastState({ info: '', error: error });
			toastRef.current?.publish();

			if (isEditMode) {
				mixpanelActions.trackTeamMember({
					eventName: mixPanelEvents.EDIT_TEAM_MEMBER_FAILED,
					errorReason: error,
				});
			} else {
				mixpanelActions.trackTeamMember({
					eventName: mixPanelEvents.ADD_TEAM_MEMBER_FAILED,
					errorReason: error,
				});
			}
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (isLoadingEditSecondaryUsers) return;

		const { name, value } = e.target;

		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));

		setErrors((prevState) => ({
			...prevState,
			[name]: '',
		}));
	};

	const handleEditSecondaryUser = (secondaryUserToEdit: SecondaryUser) => {
		mixpanelActions.trackTeamMember({ eventName: mixPanelEvents.EDIT_TEAM_MEMBER_CLICKED });

		setEditSecondaryUser(secondaryUserToEdit);

		setFormData({
			name: secondaryUserToEdit.name,
			email: secondaryUserToEdit.email,
		});

		setCheckboxStates({
			fullInboxAccess: secondaryUserToEdit.fullInboxAccess,
			accessToCategories: secondaryUserToEdit.accessToLabels,
		});

		setOpenSecondaryUserModal(true);
	};

	const handleOpenAddNewMemberModal = () => {
		mixpanelActions.trackTeamMember({ eventName: mixPanelEvents.ADD_TEAM_MEMBER_CLICKED });
		setOpenSecondaryUserModal(true);
	};

	const handleCloseAddNewMemberModal = () => {
		if (isLoadingEditSecondaryUsers) return;

		setOpenSecondaryUserModal(false);
		setOpenDeleteConfirmationModal(false);
		setEditSecondaryUser(null);
		setFormData({ name: '', email: '' });
		setCheckboxStates({ fullInboxAccess: true, accessToCategories: null });
		setErrors({ name: '', email: '', categories: '' });
	};

	const handleDeleteIconClick = () => {
		if (isLoadingEditSecondaryUsers) return;

		mixpanelActions.trackTeamMember({ eventName: mixPanelEvents.DELETE_TEAM_MEMBER_CLICKED });

		setOpenSecondaryUserModal(false);
		setOpenDeleteConfirmationModal(true);
	};

	const handleDeleteBackClick = () => {
		if (isLoadingEditSecondaryUsers) return;

		setOpenDeleteConfirmationModal(false);
		setOpenSecondaryUserModal(true);
	};

	const handleDeleteSecondaryUsers = async () => {
		if (isLoadingEditSecondaryUsers) return;

		toastRef.current?.unPublish();

		try {
			const updatedSecondaryUsers = secondaryUsers.filter(
				(secondaryUser) =>
					secondaryUser.email.toLocaleLowerCase() !== editSecondaryUser?.email.toLocaleLowerCase()
			);

			const updatedSecondaryUsersRes = await callEditSecondaryUsersAPI({
				user_id: userId,
				secondary_user_list: updatedSecondaryUsers,
			});

			dispatch(setSecondaryUsers({ secondaryUsers: updatedSecondaryUsersRes }));

			handleCloseAddNewMemberModal();

			setToastState({ error: '', info: 'Member deleted' });

			toastRef.current?.publish();

			mixpanelActions.trackTeamMember({ eventName: mixPanelEvents.DELETE_TEAM_MEMBER_SUCCESS });
		} catch (error) {
			setToastState({ info: '', error: error });
			toastRef.current?.publish();

			mixpanelActions.trackTeamMember({
				eventName: mixPanelEvents.DELETE_TEAM_MEMBER_FAILED,
				errorReason: error,
			});
		}
	};

	const handleCheckboxChange = (
		type: 'fullInboxAccess' | 'limitedAccess',
		category: string | null = null
	) => {
		if (isLoadingEditSecondaryUsers) return;

		setCheckboxStates((prevState) => {
			if (type === 'fullInboxAccess') {
				return {
					...prevState,
					fullInboxAccess: !prevState.fullInboxAccess,
					accessToCategories: !prevState.fullInboxAccess ? null : prevState.accessToCategories,
				};
			} else if (type === 'limitedAccess' && category) {
				const updatedCategories = prevState.accessToCategories
					? [...prevState.accessToCategories]
					: [];
				if (updatedCategories.includes(category)) {
					return {
						...prevState,
						accessToCategories: updatedCategories.filter((cat) => cat !== category),
					};
				} else {
					updatedCategories.push(category);
					return {
						...prevState,
						accessToCategories: updatedCategories,
					};
				}
			}
			return prevState;
		});

		setErrors((prevState) => ({
			...prevState,
			categories: '',
		}));
	};

	const handleFormSectionScroll = () => {
		const headerEle = modalHeaderRef.current;
		const buttonsContainerEle = modalBtnsContainerRef.current;
		const formSectionEle = formSectionRef.current;

		if (formSectionEle && formSectionEle.scrollTop > 0) {
			headerEle?.classList.add(classes.mainHeaderBorder);
		} else {
			headerEle?.classList.remove(classes.mainHeaderBorder);
		}

		if (
			formSectionEle &&
			Math.round(formSectionEle.scrollTop + formSectionEle.clientHeight) !==
				Math.round(formSectionEle.scrollHeight)
		) {
			buttonsContainerEle?.classList.add(classes.buttonContainerBorder);
		} else {
			buttonsContainerEle?.classList.remove(classes.buttonContainerBorder);
		}
	};

	useEffect(() => {
		if (!userId) return;

		(async function () {
			try {
				const response = await callFetchSecondaryUsers(userId);
				dispatch(setSecondaryUsers({ secondaryUsers: response }));
			} catch (error) {
				setToastState({ info: '', error: error });
				toastRef.current?.publish();
			}
		})();
	}, [userId]);

	return (
		<section className={classes.teamMemberSection}>
			<Text variant="h2" semiBold>
				{'Team Members'}
			</Text>
			<Text variant="p" tiny secondary light lineHeight={1.6}>
				{'Add team members to manage your inbox.'}
			</Text>

			<Button
				prefixIcon={<PlusIconV2 size={1.6} className={classes.plusIcon} />}
				btnText={
					<Text variant="span" small lineHeight={1.6}>
						{'Add team member'}
					</Text>
				}
				onClick={handleOpenAddNewMemberModal}
				customClass={classes.addMemberBtnWithIcon}
			/>

			<div className={classes.listContainer}>
				{isLoadingSecondaryUsers && (
					<div className={classes.loadingContainer}>
						<Loader size={2.4} />
					</div>
				)}

				{!isLoadingSecondaryUsers &&
					secondaryUsers.map((user) => (
						<div
							className={classes.secondaryUserContainer}
							key={user.email}
							tabIndex={0}
							onClick={() => handleEditSecondaryUser(user)}
						>
							<Text variant="p" tiny lineHeight={1.6} semiBold customClass={classes.name}>
								{`${user.name}`}
							</Text>
							<div className={classes.ellipse} />
							<Text variant="p" tiny lineHeight={1.6} light customClass={classes.categoryAccess}>
								{user.fullInboxAccess ? 'Full inbox access' : 'Limited inbox access'}
							</Text>

							<EditIconV4 size={1.8} />
						</div>
					))}
			</div>

			<Modal
				onCloseModal={handleCloseAddNewMemberModal}
				showModal={openSecondaryUserModal}
				bottomInMobile
				noPadding
				customClass={classes.modalContainer}
			>
				<div className={classes.mainHeader} ref={modalHeaderRef}>
					<Text variant="h2" medium semiBold>
						{`${isEditMode ? 'Edit' : 'Add'} team member`}
					</Text>
					{isEditMode && (
						<Button
							btnText={<DeleteIconV2 size={2.4} />}
							onClick={handleDeleteIconClick}
							customClass={classes.btnWithIcon}
						/>
					)}
					{!isEditMode && !isMobile && (
						<Button
							btnText={<CloseIcon size={2.2} />}
							onClick={handleCloseAddNewMemberModal}
							customClass={classes.btnWithIcon}
						/>
					)}
				</div>
				<div
					className={classes.secondaryUserForms}
					onScroll={handleFormSectionScroll}
					ref={formSectionRef}
				>
					<div className={classes.inputField}>
						<label htmlFor="name">
							<Text variant="span" lineHeight={2.2} semiBold>
								Name
							</Text>
						</label>
						<input
							id="name"
							type="text"
							name="name"
							value={formData.name}
							onChange={handleInputChange}
							placeholder="Enter name..."
							className={clsx(classes.input, errors.name && classes.errorInput)}
							disabled={isEditMode}
							ref={(el) => {
								inputsRefMaps.current['name'] = el;
							}}
						/>
						{!!errors.name && (
							<Text variant="p" tiny error customClass={classes.errorText}>
								{errors.name}
							</Text>
						)}
					</div>
					<div className={classes.inputField}>
						<label htmlFor="name">
							<Text variant="span" lineHeight={2.2} semiBold>
								Email
							</Text>
						</label>
						<input
							type="email"
							name="email"
							value={formData.email}
							onChange={handleInputChange}
							placeholder="Enter email..."
							className={clsx(classes.input, errors.email && classes.errorInput)}
							disabled={isEditMode}
							ref={(el) => {
								inputsRefMaps.current['email'] = el;
							}}
						/>
						{!!errors.email && (
							<Text variant="p" tiny error customClass={classes.errorText}>
								{errors.email}
							</Text>
						)}
					</div>
					{displayCategoriesSection && (
						<div className={classes.secondaryUserCheckboxSection}>
							<div
								className={classes.checkActionContainer}
								tabIndex={0}
								onClick={() => handleCheckboxChange('fullInboxAccess')}
							>
								<div
									className={clsx(
										classes.checkbox,
										checkboxStates.fullInboxAccess && classes.checkboxActive
									)}
								>
									{checkboxStates.fullInboxAccess && <TickIconColored size={1.4} />}
								</div>
								<Text variant="p" small light lineHeight={2}>
									{`Give full inbox access`}
								</Text>
							</div>
							{!checkboxStates.fullInboxAccess && (
								<div className={classes.limitedAccessContainer}>
									<Text variant="p" semiBold fontSize={1.1} lineHeight={1.6} tertiary>
										{`Give access to selected categories`}
									</Text>
									{categoriesToDisplay.map((category, index) => (
										<div
											tabIndex={0}
											className={classes.checkActionContainer}
											key={`${category}-${index}`}
											onClick={() => handleCheckboxChange('limitedAccess', category)}
										>
											<div
												className={clsx(
													classes.checkbox,
													checkboxStates.accessToCategories?.includes(category) &&
														classes.checkboxActive
												)}
											>
												{checkboxStates.accessToCategories?.includes(category) && (
													<TickIconColored size={1.4} />
												)}
											</div>
											<Text variant="p" small light lineHeight={2}>
												{category}
											</Text>
										</div>
									))}
									{!isExtraCategoriesLoaded && (
										<Button
											btnText={
												<Text variant="span" semiBold small lineHeight={1.8} brandPrimaryColor>
													{'Show all'}
												</Text>
											}
											onClick={handleMoreCategories}
											isLoading={isLoadingExtraCategories}
											customLoader={<Loader size={1.8} />}
											customClass={classes.showAllCategoriesBtn}
										/>
									)}
								</div>
							)}
							{!!errors.categories && (
								<Text variant="p" tiny error customClass={classes.errorText}>
									{errors.categories}
								</Text>
							)}
						</div>
					)}
				</div>
				<div className={classes.buttonsContainer} ref={modalBtnsContainerRef}>
					<Button
						btnText={
							<Text variant="span" white semiBold lineHeight={2.2}>
								{isEditMode ? 'Save' : 'Send Invite'}
							</Text>
						}
						primary
						onClick={handleSecondaryUsers}
						customClass={classes.addMemberBtn}
						isLoading={isLoadingEditSecondaryUsers}
						customLoader={<Loader size={2.2} allWhite />}
					/>
				</div>
			</Modal>

			<Modal
				onCloseModal={handleDeleteBackClick}
				showModal={openDeleteConfirmationModal}
				bottomInMobile
				noPadding
				customClass={classes.modalContainer}
			>
				<Text variant="h2" medium semiBold customClass={classes.deleteHeader}>
					{`Delete member`}
				</Text>

				<Text variant="p" small={isMobile} lineHeight={isMobile ? 2 : 2.2} light secondary>
					{'Are you sure you want to delete this member?'}
				</Text>
				<div className={classes.buttonsContainer}>
					<Button
						btnText={
							<Text variant="span" brandPrimaryColor semiBold lineHeight={2.2}>
								{'Back'}
							</Text>
						}
						onClick={handleDeleteBackClick}
						customClass={classes.backBtn}
					/>
					<Button
						btnText={
							<Text variant="span" white semiBold lineHeight={2.2}>
								{'Yes, delete'}
							</Text>
						}
						primary
						onClick={handleDeleteSecondaryUsers}
						customClass={classes.deleteBtn}
						isLoading={isLoadingEditSecondaryUsers}
						customLoader={<Loader size={2.2} allWhite />}
					/>
				</div>
			</Modal>

			<Toast
				ref={toastRef}
				toastType={toastState.info ? 'INFO' : 'ERROR'}
				header={toastState.info ? toastState.info : toastState.error}
				icon={
					toastState.info ? (
						<CheckCircleIcon size={1.8} />
					) : (
						<AlertIcon size={1.8} className={classes.toastIcon} />
					)
				}
			/>
		</section>
	);
};

export default TeamMembers;
