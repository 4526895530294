import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import classes from './RootLayout.styles.module.scss';
import { getLocalStorage, setLocalStorage } from '@utils/localStorage';
import {
	ACCESS_TOKEN,
	NOTIFICATION_DEVICE_TOKEN,
	SOURCE_NOTIFICATION,
	mainFiltersArr,
	mixPanelEvents,
} from '@utils/constants';
import useFetch from '@hooks/useFetch';
import {
	allowNotifications,
	getUserCannedResponses,
	getUserDetails,
	getUserOtherAccounts,
	getUserPersonalLabels,
} from '@api/user';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/index';
import {
	getUserDetailsFromGlobalState,
	setUserCannedResponses,
	setUserData,
	setUserOtherAccounts,
	setUserPersonalLabels,
} from '@store/userDetailsSlice/userDetailsSlice';
import { setMainFilters } from '@store/inboxSlice/inboxSlice';
import mixpanelActions from '@utils/mixpanel';
import { IRNMessageEventData } from './RootLayout.types';
import { IAllowNotificationsPayload } from '@api/user/user.types';
import { isIphone, isSuperDMApp } from '@utils/common';
import { isProUserFunc } from '@src/models/user';
import Offline from '@components/Offline';
import DotsLoader from '@components/ui/DotsLoader';
import AppUpdateModal from '@components/AppUpdateModal';

function RootLayout() {
	const dispatch = useDispatch<AppDispatch>();

	const { data: userDetails, appUpdateRequired } = useSelector(getUserDetailsFromGlobalState);

	const {
		callApi: fetchUserDetails,
		status: fetchUserDetailsStatus,
		errorType: fetchUserDetailsErrorType,
	} = useFetch(getUserDetails);
	const { callApi: fetchUserOtherAccounts } = useFetch(getUserOtherAccounts);
	const { callApi: callUserCannedResponses } = useFetch(getUserCannedResponses);
	const { callApi: callFetchUserPersonalLabels } = useFetch(getUserPersonalLabels);

	const jwtToken = getLocalStorage(ACCESS_TOKEN);

	const userDetailsLoading = !userDetails
		? (fetchUserDetailsStatus === 'idle' || fetchUserDetailsStatus === 'loading') && !!jwtToken
		: fetchUserDetailsStatus === 'loading' && !!jwtToken;

	const isAppLoading = userDetailsLoading;

	const userId = userDetails?.userId;
	const isProUser = userDetails ? isProUserFunc(userDetails) : false;

	const isOffline =
		!window.navigator.onLine ||
		(fetchUserDetailsErrorType && fetchUserDetailsErrorType !== 'HTTP Error');

	useEffect(() => {
		const ele = document.getElementById('root');
		const isSuperDMMobileApp = isSuperDMApp();

		mixpanelActions.trackWebAppVersionCheck(mixPanelEvents.WEB_APP_VERSION_CHECK);

		if (ele) {
			ele.classList.add(classes.fixedLayout);
			ele.classList.add(classes.rootLayoutBackgroundChange);
		}

		const urlSearchParams = window.location.search;

		if (urlSearchParams) {
			const params = new URLSearchParams(urlSearchParams);

			const sourceParam = params.get('source');

			if (sourceParam === SOURCE_NOTIFICATION) {
				mixpanelActions.trackNotification(
					mixPanelEvents.NOTIFICATION_CLICKED,
					window.location.href
				);
			}
		}

		const handleInstallPrompt = (e: Event) => {
			e.preventDefault();
		};

		if (isSuperDMMobileApp) {
			if (window.ReactNativeWebView) {
				const eventData: IRNMessageEventData = {
					eventType: 'REQUEST_TRACK_NOTIFICATION_CLICKED',
				};
				window.ReactNativeWebView.postMessage(JSON.stringify(eventData));
			}
			return;
		}

		window.addEventListener('beforeinstallprompt', handleInstallPrompt);

		return () => {
			window.removeEventListener('beforeinstallprompt', handleInstallPrompt);
		};
	}, []);

	// handling user details
	useEffect(() => {
		if (!jwtToken || isOffline) return;

		(async function () {
			try {
				const data = await fetchUserDetails(jwtToken);

				if (data) {
					mixpanelActions.identifyUserAndSetEmail({
						email: data.email,
						username: data.username,
						userType: data.userType,
						userID: data.userId,
					});
					mixpanelActions.registerSecondaryUser(
						data.isSecondaryUser ? 'yes' : 'no',
						data.secondaryUserEmail
					);
					dispatch(setUserData({ userData: data ? { ...data } : null }));
					if (isProUserFunc(data)) {
						dispatch(
							setMainFilters({
								filtersToPrepend: [...mainFiltersArr],
							})
						);
					}
				}
			} catch (error) {
				// handle error
			}
		})();
	}, []);

	// handling SuperDM native app events
	useEffect(() => {
		const messageHandler = (e: MessageEvent) => {
			const eventData = e.data as IRNMessageEventData;

			if (
				eventData.eventType === 'STORE_DEVICE_TOKEN' ||
				eventData.eventType === 'REFRESH_DEVICE_TOKEN'
			) {
				const userId = userDetails?.userId;
				const userNotificationsAllowedDevices = userDetails?.devicesInfo ?? [];
				const token = eventData.eventData as string;

				if (!userId || !token) return;

				const isPermissionTokenExist = userNotificationsAllowedDevices.find(
					(deviceInfo) => deviceInfo.fcmToken === token
				);

				if (isPermissionTokenExist?.fcmToken) return;

				(async function () {
					try {
						const payload: IAllowNotificationsPayload = {
							device_type: isIphone() ? 'MOBILE_IOS' : 'MOBILE_ANDROID',
							notification_allowed: true,
							token: token,
							user_id: userId,
						};

						await allowNotifications(payload);
						setLocalStorage(NOTIFICATION_DEVICE_TOKEN, token);
					} catch (error) {
						console.error('allow notification api error');
					}
				})();
			}
			if (eventData.eventType === 'TRACK_NOTIFICATION_CLICKED') {
				mixpanelActions.trackNotification(
					mixPanelEvents.NOTIFICATION_CLICKED,
					eventData.eventData ?? window.location.href
				);
			}
		};

		window.addEventListener('message', messageHandler);

		return () => {
			window.removeEventListener('message', messageHandler);
		};
	}, [userDetails]);

	// handling calling canned response
	useEffect(() => {
		if (!userId || !isProUser) return;

		(async function () {
			try {
				const cannedResponses = await callUserCannedResponses(userId);
				dispatch(setUserCannedResponses({ cannedResponses: cannedResponses }));
			} catch (error) {
				// handle canned response error
			}
		})();

		(async function () {
			try {
				const personalLabels = await callFetchUserPersonalLabels(userId);
				dispatch(setUserPersonalLabels({ personalLabels: personalLabels }));
			} catch (error) {
				// handle error
			}
		})();

		(async function () {
			try {
				const data = await fetchUserOtherAccounts();
				dispatch(setUserOtherAccounts({ otherAccounts: data }));
			} catch (error) {
				// handle error
			}
		})();
	}, [userId, isProUser]);

	if (isOffline) {
		return (
			<div className={classes.rootLayoutContainer}>
				<Offline />
			</div>
		);
	}

	if (isAppLoading) {
		return (
			<div className={classes.rootLayoutContainer}>
				<DotsLoader />
			</div>
		);
	}

	return (
		<>
			<Outlet />
			{appUpdateRequired && <AppUpdateModal />}
		</>
	);
}

export default RootLayout;
