import React, { useEffect, useRef, useState } from 'react';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import {
	ArrowRightIcon,
	BookmarkIcon,
	BookmarkIconColored,
	QuicKReplyFilledIcon,
	SparkleIcon,
	TickBoldIcon,
} from '@src/hoc/withIconStyles';
import Avatar from '@components/ui/Avatar';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/index';
import {
	addToChatCardActionsMap,
	getInboxDataFromGlobalState,
	removeFromChatCardActionsMap,
	setBookmarkChatActionCount,
	setCurrBookmarkChatsMap,
	setCurrReadChatsMap,
	setReadChatActionCount,
} from '@store/inboxSlice/inboxSlice';
import useFetch from '@hooks/useFetch';
import { bookmarkChat, markChatAsRead, sendChatMessage } from '@api/chats';
import {
	IBookmarkChatPayload,
	IMarkAsReadPayload,
	ISendChatMessagePayload,
} from '@api/chats/chats.types';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';
import { getFullNameSenderProfileFunc } from '@src/models/inbox';
import { getAmPmTimeFromUTC, getMonthDayFromUTC, isTodayTime } from '@utils/date';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import { formatFirstName } from '@utils/common';
import { IScrollChatCardProps } from './ScrollChatCard.types';
import Tags from '@components/SwipeChatCards/Tags';
import CannedResponseMobile from '@components/CannedResponseMobile';
import useChatActionsToastContext from '@hooks/useChatActionsToastContext';
import classes from './ScrollChatCard.styles.module.scss';
import clsx from 'clsx';
import useWindowSize from '@hooks/useWindow';
import PopoverLayoutDesktop from '@components/PopoverLayoutDesktop/PopoverLayoutDesktop';

function ScrollChatCard({
	withBorder,
	inboxChatsDataType,
	chatData,
	onOpenChatClick,
	chatsDataSelectedChatId,
	canDismissCard,
}: IScrollChatCardProps) {
	const { isMobile } = useWindowSize();
	const dispatch = useDispatch<AppDispatch>();
	const {
		chatCardActionsMap,
		currReadChatsMap,
		currBookmarkChatsMap,
		currReportChatsMap,
		currBlockChatsMap,
		currAssignedLabelsChatsMap,
	} = useSelector(getInboxDataFromGlobalState);
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const { callApi: callBookmarkChat, status: callBookmarkChatStatus } = useFetch(bookmarkChat);
	const { callApi: callMarkChatAsRead, status: callMarkChatAsReadStatus } =
		useFetch(markChatAsRead);
	const { callApi: sendMessage, status: sendMessageStatus } = useFetch(sendChatMessage);
	const [displayShowMoreBtn, setDisplayShowMoreBtn] = useState(false);
	const [openCannedResponse, setOpenCannedResponse] = useState(false);

	const { setToastData } = useChatActionsToastContext();

	const divRef = useRef<HTMLDivElement | null>(null);
	const recentMessageRef = useRef<HTMLDivElement | null>(null);

	const { chatId, read, bookmark, label, tags, senderDetails, lastMessage, topPick } = chatData;
	const chatRead = currReadChatsMap[chatId ?? -1] ?? read;
	const chatBookmarked = currBookmarkChatsMap[chatData.chatId ?? -1] ?? bookmark;
	const chatReported = currReportChatsMap[chatData.chatId ?? -1] ?? false;
	const chatBlocked = currBlockChatsMap[chatData.chatId ?? -1] ?? false;
	const recentMessage = chatData.lastMessage?.content ?? '';

	const userPersonalLabels = userDetails?.personalLabels ?? [];
	const assignedPersonalLabels = (
		currAssignedLabelsChatsMap[chatData.chatId ?? -1] ?? chatData.personalLabels
	).filter((label) => {
		return userPersonalLabels.includes(label);
	});

	const assignedPersonalLabel = assignedPersonalLabels?.[0];
	const assignedPersonalLabelsExtraCount =
		assignedPersonalLabels && assignedPersonalLabels.length > 1
			? assignedPersonalLabels.length - 1
			: 0;

	const monthDayFormat = getMonthDayFromUTC(lastMessage?.createdAt ?? '');
	const timeAmPmFormat = getAmPmTimeFromUTC(lastMessage?.createdAt ?? '');
	const isTodayMessage = isTodayTime(lastMessage?.createdAt ?? '');
	const recentMessageDate = isTodayMessage ? `${timeAmPmFormat ?? ''}` : `${monthDayFormat ?? ''}`;

	const senderProfileData = senderDetails?.profileData;
	const profilePicURL = senderProfileData?.profilePicture;
	const profileName = senderProfileData ? getFullNameSenderProfileFunc(senderProfileData) : '';
	const profileHeadline = senderProfileData?.headline ?? '';
	const senderUsername = senderDetails?.username ?? '';
	const senderId = senderDetails?.userId;

	const userId = userDetails?.userId;

	const shouldChatDisappear =
		chatId &&
		(!!chatCardActionsMap[chatId] || !!currReportChatsMap[chatId] || !!currBlockChatsMap[chatId]);

	const cardTagText = bookmark ? 'FROM YOUR BOOKMARKS' : topPick ? 'TOP PICK' : '';

	const CardTagIcon = cardTagText === 'TOP PICK' ? SparkleIcon : null;

	useEffect(() => {
		const dismissCard =
			chatsDataSelectedChatId &&
			canDismissCard &&
			chatsDataSelectedChatId === chatId &&
			!chatCardActionsMap[chatId] &&
			chatRead &&
			!chatReported &&
			chatRead &&
			!chatBlocked;

		if (dismissCard) {
			setTimeout(() => {
				dispatch(
					addToChatCardActionsMap({
						chatId: chatId,
						inboxChatsDataType: inboxChatsDataType,
					})
				);
				setToastData({
					toastStatus: 'success',
					toastText: 'Marked as read.',
					actionText: 'View again',
					chatId: chatId,
					onClickToastAction: onOpenChatClick,
				});
			}, 250);
		}
	}, [canDismissCard, chatReported, chatBlocked, chatRead]);

	const disappearCard = () => {
		if (!divRef.current) return;
		divRef.current.classList.add(classes.closeScrollChatCard);
		divRef.current.style.height = '0px';
	};

	const showCard = () => {
		if (!divRef.current) return;
		divRef.current.classList.remove(classes.closeScrollChatCard);
		divRef.current.style.height = `${divRef.current.scrollHeight}px`;
	};

	const handleBookmark = async () => {
		if (!(chatId && senderId && userId && callBookmarkChatStatus !== 'loading')) return;

		const payload: IBookmarkChatPayload = {
			chat_id: chatId,
			sender_id: senderId,
			user_id: userId,
			bookmark: !chatBookmarked,
		};

		mixpanelActions.trackCardBookmark(
			mixPanelEvents.CARD_BOOKMARK_CLICKED,
			inboxChatsDataType === 'INBOX_VIEW' ? 'NEW' : 'WIDGET',
			'SCROLL_CARD'
		);

		try {
			await callBookmarkChat(payload);

			// handling bookmark chat state in redux
			dispatch(
				setBookmarkChatActionCount({
					chatBookmarked: payload.bookmark,
					chatId: payload.chat_id,
				})
			);
			dispatch(
				setCurrBookmarkChatsMap({
					chatId: payload.chat_id,
					chatBookmarked: payload.bookmark,
				})
			);

			if (payload.bookmark) {
				mixpanelActions.trackChatBookmark(
					mixPanelEvents.CHAT_ADDED_TO_BOOKMARK,
					senderUsername,
					'SCROLL_CARD'
				);
			} else {
				mixpanelActions.trackChatBookmark(
					mixPanelEvents.CHAT_REMOVED_FROM_BOOKMARK,
					senderUsername,
					'SCROLL_CARD'
				);
			}
		} catch (error) {
			// handle error
		}
	};

	const handleMarkChatAsRead = async (isChatRead?: boolean) => {
		if (!(chatId && senderId && userId && callMarkChatAsReadStatus !== 'loading')) return;

		const payload: IMarkAsReadPayload = {
			chat_id: chatId,
			sender_id: senderId,
			user_id: userId,
			read: isChatRead ?? !chatRead,
		};

		const canDisplayToast = payload.read;

		canDisplayToast &&
			mixpanelActions.trackCardRead(
				mixPanelEvents.CARD_MARK_AS_READ_CLICKED,
				inboxChatsDataType === 'INBOX_VIEW' ? 'NEW' : 'WIDGET',
				'SCROLL_CARD'
			);

		// handling card disappear state in redux
		canDisplayToast &&
			dispatch(
				addToChatCardActionsMap({
					chatId: chatId,
					inboxChatsDataType: inboxChatsDataType,
				})
			);

		try {
			await callMarkChatAsRead(payload);

			canDisplayToast &&
				setToastData({
					onClickToastAction: undoMarkChatAsRead,
					toastStatus: 'success',
					toastText: 'Marked as read.',
					actionText: 'Undo',
					chatId: chatId,
				});

			dispatch(setReadChatActionCount({ chatRead: payload.read, chatId: chatId }));
			dispatch(setCurrReadChatsMap({ chatId: chatId, chatRead: payload.read }));

			if (payload.read) {
				mixpanelActions.trackMarkChatAsRead(mixPanelEvents.CHAT_MARKED_AS_READ, senderUsername);
			} else {
				mixpanelActions.trackMarkChatAsRead(mixPanelEvents.CHAT_MARKED_AS_UNREAD, senderUsername);
			}
		} catch (error) {
			// handle error
		}
	};

	const undoMarkChatAsRead = () => {
		if (!chatId) return;

		mixpanelActions.trackCardRead(
			mixPanelEvents.CARD_MARK_AS_READ_UNDO_CLICKED,
			inboxChatsDataType === 'INBOX_VIEW' ? 'NEW' : 'WIDGET',
			'SCROLL_CARD'
		);

		dispatch(
			removeFromChatCardActionsMap({
				chatId: chatId,
				inboxChatsDataType: inboxChatsDataType,
				lastChatActionsCard: chatData,
			})
		);
		handleMarkChatAsRead(false);
	};

	const openChat = () => {
		mixpanelActions.trackQuickReply(mixPanelEvents.CARD_QUICK_REPLY_OPEN_CHAT_CLICKED);
		onOpenChatClick();
	};

	const handleSendMessage = async (message: string, shouldBookmark: boolean) => {
		if (sendMessageStatus === 'loading') return;

		if (!senderId || !userId) return;

		const content = message.trim();

		const payload: ISendChatMessagePayload = {
			chat_id: chatId,
			content: content,
			content_type: 'TEXT',
			label: label,
			receiver_id: senderId,
			sender_id: userId,
			timestamp: new Date().toISOString(),
			attachment: null,
			mark_as_read: true,
		};

		// handling card disappear state in redux

		dispatch(
			addToChatCardActionsMap({
				chatId: chatId,
				inboxChatsDataType: inboxChatsDataType,
			})
		);

		try {
			await sendMessage(payload);

			dispatch(setReadChatActionCount({ chatRead: true, chatId: chatId }));
			dispatch(setCurrReadChatsMap({ chatId: chatId, chatRead: true }));

			mixpanelActions.trackChatInboxCategory(
				mixPanelEvents.INBOX_MESSAGE_SUCCESS,
				senderUsername ?? '',
				'no',
				payload.label ?? '',
				payload.attachment?.type,
				true,
				'card',
				'SCROLL_CARD'
			);

			if (shouldBookmark) {
				if (!(chatId && senderId && userId && callBookmarkChatStatus !== 'loading')) return;
				const bookmarkPayload: IBookmarkChatPayload = {
					chat_id: chatId,
					sender_id: senderId,
					user_id: userId,
					bookmark: shouldBookmark,
				};

				await callBookmarkChat(bookmarkPayload);

				// handling bookmark chat state in redux
				dispatch(
					setBookmarkChatActionCount({
						chatBookmarked: bookmarkPayload.bookmark,
						chatId: bookmarkPayload.chat_id,
					})
				);
				dispatch(
					setCurrBookmarkChatsMap({
						chatId: bookmarkPayload.chat_id,
						chatBookmarked: bookmarkPayload.bookmark,
					})
				);

				mixpanelActions.trackChatBookmark(
					mixPanelEvents.CHAT_ADDED_TO_BOOKMARK,
					senderUsername,
					'SCROLL_CARD'
				);
			}

			setToastData({
				onClickToastAction: openChat,
				toastStatus: 'success',
				toastText: 'Message sent.',
				actionText: 'View chat',
				chatId: chatId,
			});
		} catch (error) {
			// handle error
		}
	};

	const handleCloseCannedResponse = () => {
		setOpenCannedResponse(false);
	};

	const handleQuickReplyBtnClick = () => {
		mixpanelActions.trackQuickReply(
			mixPanelEvents.CARD_QUICK_REPLY_CLICKED,
			inboxChatsDataType === 'INBOX_VIEW' ? 'NEW' : 'WIDGET',
			'SCROLL_CARD'
		);
		setOpenCannedResponse(true);
	};

	useEffect(() => {
		if (!recentMessageRef.current) return;
		const displayHeight = recentMessageRef.current.clientHeight;
		const actualHeight = recentMessageRef.current.scrollHeight;

		if (displayHeight >= actualHeight) return;

		setDisplayShowMoreBtn(true);
	}, []);

	useEffect(() => {
		if (!divRef.current) return;

		if (!shouldChatDisappear) {
			showCard();
		} else {
			disappearCard();
		}
	}, [shouldChatDisappear]);

	return (
		<div className={classes.scrollChatCardContainer} ref={divRef}>
			<div
				className={clsx(
					classes.profileInfoContainer,
					!!cardTagText && classes.profileContainerWithTag
				)}
			>
				<Avatar
					size={4.8}
					profilePicURL={profilePicURL}
					fallbackIcon={FallbackPicIcon}
					customRootClass={classes.profileAvatar}
				/>

				{!!cardTagText && (
					<div className={classes.profileTag}>
						{CardTagIcon && <CardTagIcon size={1.2} className={classes.sparkleTagIcon} />}

						<Text
							variant="h2"
							color={'#AD8700'}
							lineHeight={1.4}
							fontSize={1.1}
							bold
							customClass={classes.chatTagText}
						>
							{cardTagText}
						</Text>
					</div>
				)}

				<Text variant="h3" semiBold customClass={classes.profileName}>
					{profileName}
				</Text>

				<Button
					btnText={
						chatBookmarked ? (
							<BookmarkIconColored size={2.4} className={classes.bookmarkIconActive} />
						) : (
							<BookmarkIcon size={2.4} className={classes.bookmarkIcon} />
						)
					}
					onClick={handleBookmark}
					customClass={classes.bookmarkBtn}
				/>

				<Text variant="p" light small customClass={classes.profileHeadline}>
					{profileHeadline}
				</Text>
			</div>

			<Tags tags={tags} cardView={'SCROLL_CARD'} />

			<div className={classes.recentMessage} ref={recentMessageRef}>
				{!!label && !assignedPersonalLabel && (
					<Text
						variant="span"
						semiBold
						small
						lineHeight={2.1}
						secondary={isMobile}
						customClass={classes.label}
					>
						{label}
					</Text>
				)}
				{!!assignedPersonalLabel && (
					<Text
						variant="span"
						lineHeight={1.8}
						fontSize={1.4}
						color={'#4A00C4'}
						customClass={classes.assignedLabelTextWithDot}
					>
						{assignedPersonalLabel}
						{assignedPersonalLabelsExtraCount > 0 && (
							<Text
								variant="span"
								lineHeight={1.4}
								fontSize={1.2}
								color={'#4A00C4'}
								customClass={classes.labelCountText}
							>
								{`+${assignedPersonalLabelsExtraCount}`}
							</Text>
						)}
					</Text>
				)}
				<Text variant="span" light small secondary={isMobile} lineHeight={2.1}>
					{recentMessage}
				</Text>

				{displayShowMoreBtn && (
					<Text variant="span" small tertiary customClass={classes.showMoreText}>
						<Text variant="span" small secondary light>
							{'... '}
						</Text>
						{'read more'}
					</Text>
				)}
			</div>

			<Text variant="span" light tertiaryV0 customClass={classes.recentMessageTime}>
				{recentMessageDate}
			</Text>

			{!isMobile && <div className={classes.withBorderContainer} />}

			<div className={classes.actionsContainer}>
				<Button
					btnText={
						<Text
							variant="span"
							small
							brandPrimaryColor
							semiBold
							customClass={classes.markChatAsReadText}
						>
							{'Mark as read'}
						</Text>
					}
					prefixIcon={<TickBoldIcon size={isMobile ? 2 : 1.6} />}
					onClick={handleMarkChatAsRead}
					customClass={isMobile ? classes.markAsReadBtn : classes.markAsReadBtnDesktop}
				/>
				{isMobile && (
					<Button
						btnText={
							<Text variant="span" small white semiBold>
								{'Quick reply'}
							</Text>
						}
						prefixIcon={<QuicKReplyFilledIcon size={2} />}
						onClick={handleQuickReplyBtnClick}
						primary
						customClass={classes.quickReplyBtn}
					/>
				)}

				{!isMobile && (
					<PopoverLayoutDesktop
						senderFirstName={formatFirstName(senderDetails?.profileData?.firstName ?? '')}
						onSendBtnClick={handleSendMessage}
						isChatBookmarked={chatBookmarked}
						onCloseModal={handleCloseCannedResponse}
					/>
				)}

				{!isMobile && (
					<div className={classes.arrowContainer}>
						<ArrowRightIcon size={1.8} className={classes.arrowIcon} />
					</div>
				)}
			</div>

			{withBorder && isMobile && <div className={classes.withBorderContainer} />}

			{openCannedResponse && (
				<CannedResponseMobile
					onCloseModal={handleCloseCannedResponse}
					senderFirstName={formatFirstName(senderDetails?.profileData?.firstName ?? '')}
					onSendBtnClick={handleSendMessage}
					isChatBookmarked={chatBookmarked}
				/>
			)}
		</div>
	);
}

export default ScrollChatCard;
