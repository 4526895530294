import React, { useEffect } from 'react';
import clsx from 'clsx';
import ChatsSection from './ChatsSection';
import ProfileSection from './ProfileSection';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	getSelectedChatDetailsFromGlobalState,
	setSelectedChat,
} from '@store/selectedChatSlice/selectedChatSlice';
import useFetch from '@hooks/useFetch';
import { getSenderChatDetails } from '@api/chats';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import { AppDispatch } from '@store/index';
import { CHAT_SEARCH_PARAM, PAGE_NOT_FOUND_PATH } from '@utils/constants';
import classes from './Chats.styles.module.scss';
import { getFullNameSenderProfileFunc } from '@src/models/inbox';
import DotsLoader from '@components/ui/DotsLoader';

function Chats() {
	const [searchParams] = useSearchParams(CHAT_SEARCH_PARAM);
	const selectedChatUsername = decodeURIComponent(searchParams.get(CHAT_SEARCH_PARAM) ?? '');
	const { data: userData } = useSelector(getUserDetailsFromGlobalState);
	const { selectedChat, fetchChatMessagesStatus } = useSelector(
		getSelectedChatDetailsFromGlobalState
	);
	const dispatch = useDispatch<AppDispatch>();

	const {
		callApi: fetchSenderChatDetails,
		error: fetchSenderChatDetailsError,
		status: fetchSenderChatDetailsStatus,
	} = useFetch(getSenderChatDetails);

	const selectedChatUserNameFromState = selectedChat?.senderDetails?.profileData
		? getFullNameSenderProfileFunc(selectedChat?.senderDetails?.profileData)
		: null;

	const isLoadingChat =
		fetchChatMessagesStatus === 'loading' || fetchSenderChatDetailsStatus === 'loading';

	useEffect(() => {
		if (selectedChatUserNameFromState) {
			document.title = `${selectedChatUserNameFromState} - ${process.env.TITLE}`;
		}

		return () => {
			document.title = `${process.env.TITLE}`;
		};
	}, [selectedChatUserNameFromState]);

	useEffect(() => {
		// this logic if user comes via link
		// means there is no user selected via inbox or chat list
		// fetch for the user chat details from selectedChatUsername(from URL)

		const isUserNameChatSelected = selectedChat?.senderDetails?.username === selectedChatUsername;
		const shouldCallFetchSenderChatDetails =
			!!selectedChatUsername && !isUserNameChatSelected && !!userData?.userId;

		if (!shouldCallFetchSenderChatDetails) return;

		const abortController = new AbortController();

		(async function () {
			try {
				const data = await fetchSenderChatDetails(
					{
						user_id: userData.userId,
						receiver_username: selectedChatUsername,
					},
					abortController.signal
				);
				dispatch(setSelectedChat({ selectedChatData: data }));
			} catch (error) {
				// handle error
			}
		})();

		return () => {
			if (!abortController) return;
			abortController.abort();
		};
	}, [selectedChatUsername, userData, selectedChat]);

	if (fetchSenderChatDetailsError) {
		return <Navigate to={PAGE_NOT_FOUND_PATH} />;
	}

	return (
		<section className={classes.chatsContainer} key={selectedChat?.chatId}>
			<div className={clsx(classes.loaderContainer, isLoadingChat && classes.showLoaderContainer)}>
				<DotsLoader />
			</div>
			<ChatsSection />
			<ProfileSection />
		</section>
	);
}

export default Chats;
