import React, { useEffect, useRef, useState } from 'react';
import {
	IHandleSendMessageFunc,
	ISwipeableChatCardContentProps,
} from './SwipeableChatCardContent.types';
import { getAmPmTimeFromUTC, getMonthDayFromUTC, isSameDayTime, isTodayTime } from '@utils/date';
import { LastMessage, getFullNameSenderProfileFunc } from '@src/models/inbox';
import ChatCardUserInfo from '../ChatCardUserInfo';
import Text from '@components/ui/Text';
import classes from './SwipeableChatCardContent.styles.module.scss';
import CannedResponseMobile from '@components/CannedResponseMobile';
import { formatFirstName, isSuperDMApp } from '@utils/common';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import useFetch from '@hooks/useFetch';
import { bookmarkChat, getPdfDataToDownload, reactToMessage, sendChatMessage } from '@api/chats';
import {
	IBookmarkChatPayload,
	IDownloadPdfPayload,
	IReactToMessagePayload,
	ISendChatMessagePayload,
	TAttachmentType,
	TReactionType,
} from '@api/chats/chats.types';
import {
	getInboxDataFromGlobalState,
	setBookmarkChatActionCount,
	setChatCardSentMessageMap,
	setCurrBookmarkChatsMap,
	setCurrReadChatsMap,
	setReadChatActionCount,
} from '@store/inboxSlice/inboxSlice';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';
import Button from '@components/ui/Button';
import {
	BookmarkIcon,
	BookmarkIconColored,
	DownloadIcon,
	ErrorIcon,
	ImageIcon,
	ImageWhiteIcon,
	PdfFileIcon,
	PdfFileWhiteIcon,
	QuickReplyIcon,
	SendIcon,
	SparkleIcon,
	ThumpsUpIconColored,
} from '@src/hoc/withIconStyles';
import clsx from 'clsx';
import { AttachmentMetaData } from '@src/models/message';
import { IRNDownloadEventData } from '@components/RootLayout/RootLayout.types';
import Linkify from 'linkify-react';
import { IReactedMessagesIdsWithReaction } from '@components/Chats/ChatsSection/ChatsContent/ChatsContent.types';
import Modal from '@components/ui/Modal';
import Loader from '@components/ui/Loader';
import CustomScrollBar from './CustomScrollBar';
import Tags from '../Tags';
import CannedResponsesHorizontalView from '@components/CannedResponsesHorizontalView';
import EmailSignifier from '@components/Chats/ChatsSection/ChatsContent/EmailSignifier';
import BookmarkNudge from '@components/Chats/ChatsSection/ChatsContent/BookmarkNudge';

function SwipeableChatCardContent({
	chatData,
	onOpenChatClick,
	onMessageContentEndScroll,
	onMessageContentScroll,
}: ISwipeableChatCardContentProps) {
	const [openCannedResponse, setOpenCannedResponse] = useState(false);
	const [reactedMessageIds, setReactedMessageIds] = useState<IReactedMessagesIdsWithReaction>({});
	const [lastFiveMessagesCopy, setLastFiveMessagesCopy] = useState<LastMessage[]>([]);
	const [showUndoReactionModal, setShowUndoReactionModal] = useState<boolean>(false);
	const [chatMessageToUndoReaction, setChatMessageToUndoReaction] = useState<LastMessage | null>(
		null
	);
	const [bookmarkNudgeMessageId, setBookmarkNudgeMessageId] = useState<string | null>(null);
	const [textAreaStr, setTextAreaStr] = useState('');
	const [showViewPrevious, setShowViewPrevious] = useState(false);
	const [scrollScale, setScrollScale] = useState(1);
	const messagesListContainerRef = useRef<HTMLDivElement | null>(null);
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const cardMessageContentScrolled = useRef(false);

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const { currBookmarkChatsMap, chatCardSentMessageMap, currAssignedLabelsChatsMap } = useSelector(
		getInboxDataFromGlobalState
	);
	const { callApi: sendMessage, status: sendMessageStatus } = useFetch(sendChatMessage);
	const { callApi: callBookmarkChat, status: callBookmarkChatStatus } = useFetch(bookmarkChat);
	const { callApi: callGetPdfDataToDownload, status: callGetPdfDataToDownloadStatus } =
		useFetch(getPdfDataToDownload);
	const { callApi: callReactToMessage, status: callReactToMessageStatus } =
		useFetch(reactToMessage);

	const dispatch = useDispatch();

	const { chatId, bookmark, label, tags, senderDetails, topPick, replied } = chatData;
	const chatBookmarked = currBookmarkChatsMap[chatId ?? -1] ?? bookmark;

	const userPersonalLabels = userDetails?.personalLabels ?? [];
	const assignedPersonalLabels = (
		currAssignedLabelsChatsMap[chatData.chatId ?? -1] ?? chatData.personalLabels
	).filter((label) => {
		return userPersonalLabels.includes(label);
	});

	const BookMarkIconToDisplay = chatBookmarked ? BookmarkIconColored : BookmarkIcon;

	const userId = userDetails?.userId;
	const senderId = senderDetails?.userId;
	const senderUsername = senderDetails?.username ?? '';
	const senderFirstName = senderDetails?.profileData?.firstName ?? '';
	const senderProfileData = senderDetails?.profileData;
	const profilePicURL = senderProfileData?.profilePicture;
	const profileName = senderProfileData ? getFullNameSenderProfileFunc(senderProfileData) : '';
	const profileHeadline = senderProfileData?.headline ?? '';

	const showBookmarkNudgeBaseCondition = !chatBookmarked && !replied;

	const sendButtonDisabled = textAreaStr.trim().length === 0;

	const cardTagText = bookmark ? 'FROM YOUR BOOKMARKS' : topPick ? 'TOP PICK' : '';
	const CardTagIcon = cardTagText === 'TOP PICK' ? SparkleIcon : null;

	const isChatCardMessageSent = chatCardSentMessageMap[chatId ?? -1] ?? false;
	const isChatReplied = replied;

	const displayCannedResponseSuggestions = !isChatCardMessageSent && !isChatReplied;
	const displayCannedResponseBtn = textAreaStr.trim().length === 0;

	const displayCustomScrollbar = scrollScale > 0;

	const handleSendTextMessage = async (
		payload: ISendChatMessagePayload,
		messageData?: LastMessage,
		canBookmarkChat?: boolean,
		isCannedResponse?: boolean
	) => {
		const isRetry = messageData?.status === 'FAILED';
		const tempId = messageData ? messageData.id : `temp-id-${lastFiveMessagesCopy.length + 1}`;

		dispatch(setChatCardSentMessageMap({ chatId: chatId }));

		if (!isRetry) {
			const tempChatMessage: LastMessage = {
				content: payload.content,
				id: tempId,
				contentType: payload.content_type,
				createdAt: payload.timestamp,
				senderId: payload.sender_id,
				receiverId: payload.receiver_id,
				status: 'SENDING',
				attachmentType: 'NONE',
				attachmentMetaData: null,
				reaction: 'NONE',
				canBookmarkChat: canBookmarkChat,
				isCannedResponse: isCannedResponse,
			};
			setLastFiveMessagesCopy((prevState) => {
				return [...prevState, tempChatMessage];
			});
			setTextAreaStr('');
		} else {
			setLastFiveMessagesCopy((prevState) => {
				return prevState.map((msg) => {
					if (msg.id === messageData?.id) {
						return {
							...msg,
							status: 'SENDING',
						};
					}
					return msg;
				});
			});
		}

		try {
			const message = await sendMessage(payload);

			setLastFiveMessagesCopy((prevState) => {
				return prevState.map((msg) => {
					if (msg.id === tempId) {
						return {
							...message,
							status: 'SENT',
						};
					}
					return msg;
				});
			});

			setTimeout(() => {
				setLastFiveMessagesCopy((prevState) => {
					return prevState.map((msg) => {
						if (msg.id === message.id) {
							return {
								...msg,
								status: 'DELIVERED',
							};
						}
						return msg;
					});
				});
			}, 10000);

			return message;
		} catch (error) {
			setLastFiveMessagesCopy((prevState) => {
				return prevState.map((msg) => {
					if (msg.id === tempId) {
						return {
							...msg,
							status: 'FAILED',
						};
					}
					return msg;
				});
			});

			return Promise.reject(error);
		}
	};

	const handleSendMessage: IHandleSendMessageFunc = async (paramsObj) => {
		if (sendMessageStatus === 'loading') return;

		if (!senderId || !userId) return;

		// getting values from params object
		const message = paramsObj?.message;
		const canBookmarkChat = paramsObj?.canBookmarkChat;
		const isCannedResponse = paramsObj?.isCannedResponse ?? false;
		const chatMessage = paramsObj?.chatMessage;

		const payloadMessageContent = message ?? textAreaStr.trim();
		const shouldBookmark = canBookmarkChat ?? false;

		const payload: ISendChatMessagePayload = {
			chat_id: chatId,
			content: payloadMessageContent,
			content_type: 'TEXT',
			label: label,
			receiver_id: senderId,
			sender_id: userId,
			timestamp: new Date().toISOString(),
			attachment: null,
			mark_as_read: true,
		};

		try {
			const message = await handleSendTextMessage(
				payload,
				chatMessage,
				shouldBookmark,
				isCannedResponse
			);

			if (showBookmarkNudgeBaseCondition && !isCannedResponse && !canBookmarkChat) {
				setBookmarkNudgeMessageId(message.id);
			}

			dispatch(setReadChatActionCount({ chatRead: true, chatId: chatId }));
			dispatch(setCurrReadChatsMap({ chatId: chatId, chatRead: true }));

			mixpanelActions.trackChatInboxCategory(
				mixPanelEvents.INBOX_MESSAGE_SUCCESS,
				senderUsername ?? '',
				'no',
				payload.label ?? '',
				payload.attachment?.type,
				isCannedResponse,
				'card',
				'SWIPE_CARD'
			);

			if (shouldBookmark) {
				if (!(chatId && senderId && userId && callBookmarkChatStatus !== 'loading')) return;
				const bookmarkPayload: IBookmarkChatPayload = {
					chat_id: chatId,
					sender_id: senderId,
					user_id: userId,
					bookmark: shouldBookmark,
				};

				await callBookmarkChat(bookmarkPayload);

				// handling bookmark chat state in redux
				dispatch(
					setBookmarkChatActionCount({
						chatBookmarked: bookmarkPayload.bookmark,
						chatId: bookmarkPayload.chat_id,
					})
				);
				dispatch(
					setCurrBookmarkChatsMap({
						chatId: bookmarkPayload.chat_id,
						chatBookmarked: bookmarkPayload.bookmark,
					})
				);

				mixpanelActions.trackChatBookmark(
					mixPanelEvents.CHAT_ADDED_TO_BOOKMARK,
					senderUsername,
					'SWIPE_CARD'
				);
			}
		} catch (error) {
			// handle error
		}
	};

	const handleCloseCannedResponse = () => {
		setOpenCannedResponse(false);
	};

	const handleCannedResponseBtnClick = () => {
		mixpanelActions.trackQuickReply(mixPanelEvents.CARD_QUICK_REPLY_CLICKED, 'NEW', 'SWIPE_CARD');
		setOpenCannedResponse(true);
	};

	const handlePdfDownload = async (
		messageId: string,
		userId: number,
		attachmentMetaData: AttachmentMetaData,
		attachmentType?: TAttachmentType
	) => {
		if (callGetPdfDataToDownloadStatus === 'loading') return;

		const payload: IDownloadPdfPayload = {
			message_id: messageId,
			user_id: userId,
			attachment_metadata: {
				content_type: attachmentMetaData.contentType,
				name: attachmentMetaData.name,
				id: attachmentMetaData.id,
			},
		};
		try {
			const res = await callGetPdfDataToDownload(payload);

			const isSuperDMMobileApp = isSuperDMApp();

			const downloadFileType =
				attachmentType === 'JPEG'
					? 'image/jpeg'
					: attachmentType === 'PNG'
					? 'image/png'
					: 'application/pdf';

			const blob = new Blob([res], { type: downloadFileType });

			// convert to base64 and trigger download in superDM app
			if (isSuperDMMobileApp) {
				const reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = function () {
					const base64data = reader.result;
					if (window.ReactNativeWebView) {
						const eventData: IRNDownloadEventData = {
							eventType: 'DOWNLOAD_FILE',
							eventData: {
								blobData: base64data,
								fileName: attachmentMetaData.name,
								fileType: attachmentType,
							},
						};
						window.ReactNativeWebView.postMessage(JSON.stringify(eventData));
					}
				};
				return;
			}

			const fileURL = window.URL.createObjectURL(blob);

			const linkEle = document.createElement('a');
			linkEle.href = fileURL;
			linkEle.download = `${attachmentMetaData.name}`;
			linkEle.click();
			document.body.removeChild(linkEle);
		} catch (error) {
			// handle error
		}
	};

	const handleReactToMessageClick = async (reactionType: TReactionType, message: LastMessage) => {
		if (!chatId || !userDetails?.userId || callReactToMessageStatus === 'loading') return;

		const payload: IReactToMessagePayload = {
			chat_id: chatId,
			message_id: message.id,
			reaction: reactionType,
			user_id: userDetails?.userId,
		};

		const messageId = message.id;
		const messageReaction = reactionType;

		try {
			await callReactToMessage(payload);

			setReactedMessageIds((prevState) => {
				return {
					...prevState,
					[messageId]: messageReaction ?? null,
				};
			});
		} catch (error) {
			// handle react to message error
		}
	};

	const handleCloseUndoReactionModal = () => {
		if (callReactToMessageStatus === 'loading') return;
		setShowUndoReactionModal(false);
	};

	const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const content = e.target.value;

		setTextAreaStr(content);
	};

	const handleBookmarkChat = async () => {
		try {
			if (!(chatId && senderId && userId && callBookmarkChatStatus !== 'loading')) return;

			const payload: IBookmarkChatPayload = {
				chat_id: chatId,
				sender_id: senderId,
				user_id: userId,
				bookmark: !chatBookmarked,
			};

			mixpanelActions.trackCardBookmark(mixPanelEvents.CARD_BOOKMARK_CLICKED, 'NEW', 'SWIPE_CARD');

			await callBookmarkChat(payload);

			// handling bookmark chat state in redux
			dispatch(
				setBookmarkChatActionCount({
					chatBookmarked: payload.bookmark,
					chatId: payload.chat_id,
				})
			);
			dispatch(
				setCurrBookmarkChatsMap({
					chatId: payload.chat_id,
					chatBookmarked: payload.bookmark,
				})
			);

			if (payload.bookmark) {
				mixpanelActions.trackChatBookmark(
					mixPanelEvents.CHAT_ADDED_TO_BOOKMARK,
					senderUsername,
					'SWIPE_CARD'
				);
			} else {
				mixpanelActions.trackChatBookmark(
					mixPanelEvents.CHAT_REMOVED_FROM_BOOKMARK,
					senderUsername,
					'SWIPE_CARD'
				);
			}
		} catch (error) {
			// handle error
		}
	};

	useEffect(() => {
		if (!chatData.lastFiveMessages.length) return;

		if (chatData.lastFiveMessages.length > 4) {
			setShowViewPrevious(true);
			setLastFiveMessagesCopy([...chatData.lastFiveMessages].slice(1, 5));
		} else {
			setShowViewPrevious(false);
			setLastFiveMessagesCopy([...chatData.lastFiveMessages].slice(0, 5));
		}
	}, [chatData]);

	const lastFiveMessagesCopyLength = lastFiveMessagesCopy.length;

	useEffect(() => {
		const ele = messagesListContainerRef.current;

		if (!ele) return;

		setScrollScale((ele.scrollHeight - ele.clientHeight) / 100);

		ele.scrollTo({ top: ele.scrollHeight, behavior: 'instant' });
	}, [lastFiveMessagesCopyLength, displayCannedResponseSuggestions, displayCustomScrollbar]);

	useEffect(() => {
		const textAreaEle = textAreaRef.current;

		if (!textAreaEle) return;

		textAreaEle.style.height = 'max-content';

		if (textAreaStr.length > 0) {
			textAreaEle.style.height = `${textAreaEle.scrollHeight}px`;
		}
	}, [textAreaStr]);

	return (
		<div className={classes.swipeableChatCardContentContainer}>
			{!!cardTagText && (
				<Text
					variant="h2"
					color={'#AD8700'}
					lineHeight={1.4}
					fontSize={1}
					bold
					customClass={classes.chatTagText}
				>
					{CardTagIcon && <CardTagIcon size={1.2} className={classes.sparkleIcon} />}
					{cardTagText}
				</Text>
			)}
			<div
				className={clsx(
					classes.headerContainer,
					!!cardTagText && classes.headerContainerMarginTop16
				)}
			>
				<ChatCardUserInfo
					profileHeadline={profileHeadline}
					profileName={profileName}
					profilePicURL={profilePicURL}
					renderAction={() => {
						return (
							<Button
								btnText={
									<BookMarkIconToDisplay
										size={2.4}
										className={clsx(chatBookmarked && classes.bookmarkIconColored)}
									/>
								}
								onClick={handleBookmarkChat}
								customClass={classes.bookmarkBtn}
							/>
						);
					}}
					assignedLabels={assignedPersonalLabels}
				/>
				<Tags
					tags={tags}
					onTouchStart={() => {
						onMessageContentScroll();
					}}
					onTouchEnd={() => {
						onMessageContentEndScroll();
					}}
					cardView={'SWIPE_CARD'}
				/>
			</div>
			<div
				className={clsx(
					classes.messageContainerWrapper,
					displayCannedResponseSuggestions && classes.marginBottom8
				)}
			>
				<div className={classes.messageContainer} ref={messagesListContainerRef}>
					{showViewPrevious && (
						<Button
							btnText={
								<Text variant="span" fontSize={1.3} lineHeight={1.8} bold brandPrimaryColor>
									{'View previous messages'}
								</Text>
							}
							onClick={onOpenChatClick}
							customClass={clsx(
								classes.viewPreviousBtn,
								!label && classes.viewPreviousMarginBottom
							)}
						/>
					)}

					{!!label && (
						<Text
							variant="h3"
							fontSize={1.2}
							lineHeight={1.6}
							color={'#4A6B89'}
							semiBold
							customClass={classes.label}
						>
							{label}
						</Text>
					)}
					{lastFiveMessagesCopy.map((message, index) => {
						const isSameUserSent = userDetails?.userId === message.senderId;
						const isNextMsgDiffUserSent =
							message.receiverId !== lastFiveMessagesCopy[index + 1]?.receiverId;
						const isLastMessage = index === lastFiveMessagesCopy.length - 1;

						const monthDayFormat = getMonthDayFromUTC(message.createdAt);
						const timeAmPmFormat = getAmPmTimeFromUTC(message.createdAt);
						const isTodayMessage = isTodayTime(message.createdAt);

						const timeDisplayText = isTodayMessage
							? `${timeAmPmFormat ?? ''}`
							: `${monthDayFormat ?? ''}, ${timeAmPmFormat}`;

						const isNextMessageOfSameTime = isSameDayTime(
							message.createdAt,
							lastFiveMessagesCopy[index + 1]?.createdAt
						);

						const statusText =
							message.status === 'FAILED'
								? 'Failed to send. Tap to try again.'
								: message.status === 'SENDING'
								? 'Sending...'
								: message.status === 'SENT'
								? 'Sent'
								: '';

						const isChatFailed = message.status === 'FAILED';
						const isChatSending = message.status === 'SENDING';
						const isChatSent = message.status === 'SENT';
						const isChatDelivered = message.status === 'DELIVERED';

						const displayTimeText = !isNextMessageOfSameTime && (isChatSent || isChatDelivered);

						const attachmentMetaData = message.attachmentMetaData;
						const isPdfAttachment = message.attachmentType === 'PDF';
						const isImageAttachment =
							message.attachmentType === 'JPEG' || message.attachmentType === 'PNG';

						const reaction = reactedMessageIds[message.id] || message.reaction;

						const showReactedIcon = reaction === 'LIKE';

						const emailSignifier = message.emailSignifier;

						const isRecentMessage = index === lastFiveMessagesCopy.length - 1;

						const displayBookmarkNudge = bookmarkNudgeMessageId === message.id;

						return (
							<div key={message.id} className={classes.chatMainContainer}>
								<div
									className={clsx(
										classes.chatContainer,
										isSameUserSent && classes.chatContainerColored,
										isNextMsgDiffUserSent && !isLastMessage && classes.chatContainerMarginBottom
									)}
								>
									<div
										className={clsx(
											classes.textWithReaction,
											showReactedIcon && classes.textContentMargin
										)}
									>
										<Text
											variant="p"
											small
											light
											white={isSameUserSent}
											customClass={clsx(classes.content, isSameUserSent && classes.contentColored)}
										>
											<Linkify options={{ target: '_blank' }}>{message.content}</Linkify>
										</Text>
										{showReactedIcon && (
											<Button
												btnText={<ThumpsUpIconColored size={1.4} />}
												customClass={clsx(
													classes.selectedReactionBtn,
													isSameUserSent && classes.selectReactionSameUser
												)}
												onClick={() => {
													if (isSameUserSent) return;
													setShowUndoReactionModal(true);
													setChatMessageToUndoReaction(message);
												}}
											/>
										)}
									</div>
									{attachmentMetaData && (
										<div
											className={clsx(
												classes.pdfAttachmentContainer,
												isSameUserSent && classes.contentColored
											)}
											onClick={() => {
												if (message.id && userDetails?.userId && attachmentMetaData) {
													handlePdfDownload(
														message.id,
														userDetails.userId,
														attachmentMetaData,
														message.attachmentType
													);
												}
											}}
											role="button"
										>
											{isSameUserSent && isPdfAttachment && <PdfFileWhiteIcon size={1.4} />}
											{!isSameUserSent && isPdfAttachment && <PdfFileIcon size={1.4} />}
											{isSameUserSent && isImageAttachment && <ImageWhiteIcon size={1.4} />}
											{!isSameUserSent && isImageAttachment && <ImageIcon size={1.4} />}
											<Text
												variant="p"
												white={isSameUserSent}
												small
												light
												customClass={classes.pdfFileName}
											>
												{attachmentMetaData.name}
											</Text>
											<DownloadIcon
												size={1.4}
												className={clsx(!isSameUserSent && classes.downloadIconBlack)}
											/>
										</div>
									)}
									{isChatFailed && (
										<Button
											btnText={
												<Text variant="p" error extraTiny>
													{statusText}
												</Text>
											}
											prefixIcon={<ErrorIcon size={1.4} />}
											onClick={() => {
												handleSendMessage({
													chatMessage: message,
													canBookmarkChat: message.canBookmarkChat,
													message: message.content,
													isCannedResponse: message.isCannedResponse,
												});
											}}
											customClass={classes.failedTextBtn}
										/>
									)}
									{isChatSending && (
										<Text
											variant="p"
											extraTiny
											tertiary
											light
											customClass={classes.timeIconContainer}
										>
											{statusText}
										</Text>
									)}
									{displayTimeText && (
										<Text
											variant="p"
											extraTiny
											tertiaryV0
											light
											customClass={classes.timeIconContainer}
										>
											{timeDisplayText}
											{isChatSent && (
												<Text variant="span" extraTiny tertiary light>
													{statusText}
												</Text>
											)}
										</Text>
									)}
								</div>
								<EmailSignifier
									emailSignifierList={emailSignifier}
									userId={userId}
									senderFirstName={formatFirstName(senderFirstName)}
									customClassName={
										!isRecentMessage || displayBookmarkNudge
											? classes.emailSignifierMarginBottom
											: classes.emailSignifierMarginBottomRecent
									}
									inSwipeCard
								/>

								{displayBookmarkNudge && (
									<BookmarkNudge
										onCancel={() => {
											setBookmarkNudgeMessageId(null);
										}}
										chatBookmarked={chatBookmarked}
										currUserId={userId}
										selectedChatId={chatId}
										selectedChatUserId={senderId}
										senderUsername={senderUsername}
										customClassName={classes.bookmarkNudge}
									/>
								)}
							</div>
						);
					})}
				</div>
				{displayCustomScrollbar && (
					<CustomScrollBar
						onScroll={(value) => {
							const ele = messagesListContainerRef.current;

							if (!ele) return;

							const scrollTopValue = ele.scrollHeight - ele.clientHeight - value * scrollScale;

							ele.scrollTop = scrollTopValue;

							if (!cardMessageContentScrolled.current) {
								mixpanelActions.trackCardSwipe(mixPanelEvents.CARD_SCROLL_DONE);
								cardMessageContentScrolled.current = true;
							}
						}}
						onTouchStart={() => {
							onMessageContentScroll();
						}}
						onTouchEnd={() => {
							onMessageContentEndScroll();
						}}
					/>
				)}
			</div>
			{displayCannedResponseSuggestions && (
				<CannedResponsesHorizontalView
					senderFirstName={formatFirstName(senderDetails?.profileData?.firstName ?? '')}
					onSendBtnClick={(text, shouldBookmark) => {
						handleSendMessage({
							canBookmarkChat: shouldBookmark,
							message: text,
							isCannedResponse: true,
						});
					}}
					onSelectCannedResponse={(cannedResponse) => {
						mixpanelActions.trackCannedResponse({
							eventName: mixPanelEvents.QUICK_REPLY_SELECTED,
							screen: 'card',
							type: 'pill',
							chatUsername: senderUsername,
							labelName: cannedResponse.name,
						});
					}}
					isChatBookmarked={chatBookmarked}
					onTouchStart={() => {
						onMessageContentScroll();
					}}
					onTouchEnd={() => {
						onMessageContentEndScroll();
					}}
				/>
			)}
			<div className={classes.inputContainer}>
				<div className={classes.inputWithIcons}>
					<textarea
						rows={1}
						ref={textAreaRef}
						className={classes.input}
						placeholder={'Type your message here'}
						onChange={handleTextAreaChange}
						value={textAreaStr}
						autoComplete="off"
						onClick={(e) => {
							e.stopPropagation();
						}}
					/>
					{displayCannedResponseBtn && (
						<Button
							btnText={<QuickReplyIcon size={2} />}
							onClick={handleCannedResponseBtnClick}
							customClass={classes.cannedResponseBtn}
						/>
					)}
				</div>

				<Button
					btnText={
						<SendIcon size={1.8} className={clsx(!sendButtonDisabled && classes.sendIconActive)} />
					}
					onClick={handleSendMessage}
					customClass={clsx(classes.sendBtn, sendButtonDisabled && classes.sendBtnDisabled)}
					disabled={sendButtonDisabled}
				/>
			</div>
			{openCannedResponse && (
				<CannedResponseMobile
					onCloseModal={handleCloseCannedResponse}
					senderFirstName={formatFirstName(senderDetails?.profileData?.firstName ?? '')}
					onSendBtnClick={(text, shouldBookmark) => {
						handleSendMessage({
							canBookmarkChat: shouldBookmark,
							message: text,
							isCannedResponse: true,
						});
					}}
					isChatBookmarked={chatBookmarked}
					onSelectCannedResponse={(cannedResponse) => {
						mixpanelActions.trackCannedResponse({
							eventName: mixPanelEvents.QUICK_REPLY_SELECTED,
							screen: 'card',
							type: 'list',
							chatUsername: senderUsername,
							labelName: cannedResponse.name,
						});
					}}
				/>
			)}
			{showUndoReactionModal && (
				<Modal
					header={'Undo Like?'}
					onCloseModal={handleCloseUndoReactionModal}
					showModal={showUndoReactionModal}
					bottomInMobile
				>
					<div className={classes.undoReactionAlertModalContentContainer}>
						<Text variant="p" light small secondary>
							{'Please confirm if you want to undo. '}
						</Text>

						<div className={classes.buttonsContainer}>
							<Button
								btnText={
									<Text variant="span" small semiBold>
										{'Cancel'}
									</Text>
								}
								onClick={handleCloseUndoReactionModal}
								customClass={classes.modalCancelBtn}
								disabled={callReactToMessageStatus === 'loading'}
							/>
							<Button
								btnText={
									<Text variant="span" white small semiBold>
										{'Yes, undo'}
									</Text>
								}
								onClick={() => {
									if (chatMessageToUndoReaction) {
										handleReactToMessageClick('NONE', chatMessageToUndoReaction);
									}
									setShowUndoReactionModal(false);
								}}
								customLoader={<Loader allWhite />}
								primary
								isLoading={callReactToMessageStatus === 'loading'}
								customClass={classes.modalUndoBtn}
							/>
						</div>
					</div>
				</Modal>
			)}
		</div>
	);
}

export default SwipeableChatCardContent;
