import {
	IDraftUser,
	IUserDetails,
	IUserInboxCategory,
	IUserLinkedInProfileMetaDataApiResponse,
	IUserLocationData,
	IUserMetaDataAPIResponse,
	IUserNotificationsAllowedDevices,
	IUserTabs,
	TChatsListView,
} from '@api/auth/auth.types';
import { getInboxCategoriesObjectMapping } from './inbox';
import { IAppliedFiltersPayload } from '@api/chats/chats.types';
import {
	IGetInboxOverviewApiResponse,
	IOverviewCategories,
	IOverviewInsights,
	IOverviewSavedFilters,
	IOverviewTopPicks,
	IUserCannedResponse,
	IUserOtherAccount,
	TFilterImageType,
	TOverviewType,
	ISecondaryUser,
} from '@api/user/user.types';

export interface UserLinkedProfileInMetaData {
	firstName: string;
	lastName?: string | null;
	headline?: string | null;
	imageURL?: string | null;
	location: IUserLocationData | null;
}

export function getStructuredUserLinkedProfileInMetaData(
	obj?: IUserLinkedInProfileMetaDataApiResponse
) {
	if (!obj) return null;
	const structuredObj: UserLinkedProfileInMetaData = {
		firstName: obj.first_name,
		lastName: obj.last_name,
		imageURL: obj.image_url,
		headline: obj.headline,
		location: getStructuredUserLocationData(obj.location),
	};

	return structuredObj;
}

export interface UserLocationData {
	city: string;
	country: string;
	state: string;
	default: string;
}

export function getStructuredUserLocationData(obj?: IUserLocationData | null) {
	if (!obj) return null;

	const structuredObj: UserLocationData = {
		city: obj.city,
		country: obj.country,
		default: obj.default,
		state: obj.state,
	};

	return structuredObj;
}

export interface InboxCategories {
	name: string;
	important: boolean;
	priority: number;
	tabName: string;
	senderPriority: number;
	valid: boolean;
}

export function getStructuredInboxCategories(obj: IUserInboxCategory) {
	const structuredObj: InboxCategories = {
		name: obj.name,
		important: obj.important,
		senderPriority: obj.sender_priority,
		valid: obj.valid,
		priority: obj.priority,
		tabName: obj.tab_name,
	};

	return structuredObj;
}

export interface Tabs {
	name: string;
	displayName: string;
	preAppliedFilters: IAppliedFiltersPayload | null;
	archiveCountRequired?: boolean;
	showTimePeriodFilter?: boolean;
	unreadFilterRequired?: boolean;
	chatsListView?: TChatsListView;
	overviewEnabled?: boolean;
}

export interface TabsWithCount {
	name: string;
	count: number;
	preAppliedFilters: IAppliedFiltersPayload | null;
}

export function getStructuredTabsCount(obj: IUserTabs) {
	const structuredObj: TabsWithCount = {
		name: obj.name,
		preAppliedFilters: obj.pre_applied_filter,
		count: obj.count ?? 0,
	};

	return structuredObj;
}

export interface UserNotificationAllowedDevices {
	deviceType: string;
	fcmToken: string;
}

export function getStructuredUserNotificationAllowedDevices(obj: IUserNotificationsAllowedDevices) {
	const structuredObj: UserNotificationAllowedDevices = {
		deviceType: obj.device_type,
		fcmToken: obj.fcm_token,
	};

	return structuredObj;
}

export interface OverviewCategories {
	name: string;
	appliedFilters: IAppliedFiltersPayload | null;
	priority: number;
	count?: number;
	displayCardType: TOverviewDisplayCardType;
}

export function getStructuredOverviewCategories(obj: IOverviewCategories) {
	const structuredObj: OverviewCategories = {
		appliedFilters: obj.applied_filter,
		name: obj.name,
		priority: obj.priority,
		count: obj.count,
		displayCardType: 'CATEGORIES',
	};

	return structuredObj;
}

export type TOverviewDisplayCardType = 'SAVED' | 'INSIGHTS' | 'TOP_PICKS' | 'CATEGORIES';

export interface OverviewSavedFilters {
	name: string;
	appliedFilters: IAppliedFiltersPayload | null;
	priority: number;
	count?: number;
	images?: string[];
	cardType?: TFilterImageType | null;
	text?: string;
	displayCardType: TOverviewDisplayCardType;
}

export function getStructuredOverviewSavedFilters(obj: IOverviewSavedFilters) {
	const structuredObj: OverviewSavedFilters = {
		appliedFilters: obj.applied_filter,
		name: obj.name,
		priority: obj.priority,
		count: obj.count,
		images: obj.images,
		cardType: obj.card_type,
		text: obj.text,
		displayCardType: 'SAVED',
	};

	return structuredObj;
}

export interface OverviewTopPicks {
	text: string;
	count?: number;
	appliedFilters: IAppliedFiltersPayload | null;
	images?: string[];
	cardType?: TFilterImageType | null;
	displayCardType: TOverviewDisplayCardType;
}

export function getStructuredOverviewTopPicks(obj: IOverviewTopPicks | null) {
	if (!obj) return null;
	const structuredObj: OverviewTopPicks = {
		appliedFilters: obj.applied_filter,
		count: obj.count,
		text: obj.text,
		images: obj.images,
		cardType: obj.card_type,
		displayCardType: 'TOP_PICKS',
	};

	return structuredObj;
}

export interface OverviewInsights {
	resultsName: string;
	text: string;
	appliedFilters: IAppliedFiltersPayload | null;
	images?: string[];
	cardType?: TFilterImageType | null;
	count?: number;
	priority: number;
	displayCardType: TOverviewDisplayCardType;
}

export function getStructuredOverviewInsights(obj: IOverviewInsights) {
	const structuredOverviewInsights: OverviewInsights = {
		resultsName: obj.result_name,
		appliedFilters: obj.applied_filter,
		text: obj.text,
		images: obj.images,
		cardType: obj.card_type,
		count: obj.count,
		priority: obj.priority,
		displayCardType: 'INSIGHTS',
	};

	return structuredOverviewInsights;
}

export interface Overview {
	type: TOverviewType;
	savedFilters: OverviewSavedFilters[];
	categories: OverviewCategories[];
	topPicks: OverviewTopPicks | null;
	insights: OverviewInsights[];
}

export function getStructuredOverview(obj: IGetInboxOverviewApiResponse) {
	if (!obj) return null;
	const structuredObj: Overview = {
		type: obj.type,
		savedFilters: obj.saved_filters?.map(
			(savedFilter) => getStructuredOverviewSavedFilters(savedFilter) ?? []
		),
		categories: obj.categories?.map((category) => getStructuredOverviewCategories(category)) ?? [],
		topPicks: getStructuredOverviewTopPicks(obj.top_picks),
		insights: obj.insights?.map((insight) => getStructuredOverviewInsights(insight)) ?? [],
	};

	return structuredObj;
}

export interface UserOtherAccount {
	userId: number;
	firstName: string;
	lastName: string;
	imageURL: string;
	loggedIn: boolean;
}

export function getStructuredUserOtherAccount(obj: IUserOtherAccount) {
	const structuredObj: UserOtherAccount = {
		userId: obj.user_id,
		firstName: obj.first_name ?? '',
		lastName: obj.last_name ?? '',
		imageURL: obj.image_url ?? '',
		loggedIn: obj.logged_in ?? false,
	};

	return structuredObj;
}

export interface User {
	firstName: string;
	lastName: string;
	email: string;
	imageURL: string;
	userType: IUserDetails['user_type'];
	linkedInVerificationPossible: boolean;
	linkedInVerificationStatus: IUserDetails['linkedin_verification_status'];
	userStatus: IUserDetails['user_status'];
	username: string;
	headline: string;
	location: UserLocationData | null;
	inboxCategories: InboxCategories[];
	userId: number;
	preferences: string;
	devicesInfo: UserNotificationAllowedDevices[];
	cannedResponses?: IUserCannedResponse[];
	personalLabels?: string[];
	minCharCountEnabled?: boolean;
	maxCharCountEnabled?: boolean;
	archivalEnabled?: boolean;
	isSecondaryUser: boolean;
	secondaryUserEmail: string | null;
	topPicksThreshold?: number;
	swipeEducationDone?: boolean;
	secondaryUsers?: SecondaryUser[];
}

export function getStructuredUser(obj?: IUserDetails) {
	if (!obj) return null;

	const structuredObj: User = {
		userId: obj.user_id,
		firstName: obj.first_name,
		lastName: obj.last_name,
		email: obj.email,
		imageURL: obj.image_url,
		userType: obj.user_type,
		linkedInVerificationPossible: obj.linkedin_verification_possible ?? true,
		linkedInVerificationStatus: obj.linkedin_verification_status,
		username: obj.username,
		headline: obj.headline,
		location: getStructuredUserLocationData(obj.location),
		inboxCategories:
			obj.inbox_categories?.map((category) => getStructuredInboxCategories(category)) ?? [],
		preferences: obj.preferences,
		devicesInfo:
			obj.device_info?.map((deviceInfo) =>
				getStructuredUserNotificationAllowedDevices(deviceInfo)
			) ?? [],
		userStatus: obj.user_status,
		maxCharCountEnabled: obj.max_char_count_enabled,
		minCharCountEnabled: obj.min_char_count_enabled,
		archivalEnabled: obj.archival_enabled,
		isSecondaryUser: obj.is_secondary_user ?? false,
		secondaryUserEmail: obj.secondary_user_email ?? null,
		swipeEducationDone: obj.swipe_education_done,
		topPicksThreshold: obj.top_picks_threshold,
	};

	return structuredObj;
}

export function getFullNameUserFunc(obj: User | UserOtherAccount) {
	return `${obj.firstName ?? ''} ${obj.lastName ?? ''}`.trim();
}

export interface DraftUser {
	firstName: string;
	lastName: string;
	email: string;
	imageURL: string;
}

export function getStructuredDraftUser(obj?: IDraftUser) {
	if (!obj) return null;
	const structuredObj: DraftUser = {
		firstName: obj.first_name,
		lastName: obj.last_name,
		email: obj.email,
		imageURL: obj.image_url,
	};

	return structuredObj;
}

export interface UserMetaData {
	firstName: string;
	lastName: string;
	preferences: string;
	imageURL: string;
	inboxCategories: InboxCategories[];
	InboxCategoriesMap: {
		[key: string]: InboxCategories;
	};
	introUpdatesText: string[];
	maxCharCountEnabled?: boolean;
	minCharCountEnabled?: boolean;
}

export function getStructuredUserMetaData(obj?: IUserMetaDataAPIResponse) {
	if (!obj) return null;

	const structuredObj: UserMetaData = {
		firstName: obj.first_name,
		lastName: obj.last_name,
		imageURL: obj.image_url,
		preferences: obj.preferences,
		inboxCategories:
			obj.inbox_categories?.map((inboxCategory) => getStructuredInboxCategories(inboxCategory)) ??
			[],
		InboxCategoriesMap: getInboxCategoriesObjectMapping(
			obj.inbox_categories?.map((inboxCategory) => getStructuredInboxCategories(inboxCategory)) ??
				[]
		),
		introUpdatesText: obj.intro_fomo_text,
		maxCharCountEnabled: obj.max_char_count_enabled,
		minCharCountEnabled: obj.min_char_count_enabled,
	};

	return structuredObj;
}

export interface SecondaryUser {
	name: string;
	email: string;
	fullInboxAccess: boolean;
	accessToLabels: string[] | null;
}

export function getStructuredSecondaryUser(obj: ISecondaryUser) {
	const structuredObj: SecondaryUser = {
		name: obj.name,
		email: obj.email,
		fullInboxAccess: obj.full_inbox_access,
		accessToLabels: obj.access_to_labels,
	};

	return structuredObj;
}

export function getFullNameUserMetaDataFunc(obj: UserMetaData) {
	return `${obj.firstName ?? ''} ${obj.lastName}`.trim();
}

export function isProUserFunc(obj: User | null) {
	return obj?.userType === 'PRO';
}

export function isLiteUserFunc(obj: User | null) {
	return obj?.userType === 'LITE';
}

export function isAccountVerifiedFunc(obj: User | null) {
	return (
		obj?.linkedInVerificationStatus === 'VERIFIED' ||
		obj?.linkedInVerificationStatus === 'UNVERIFIED'
	);
}

export function isAccountRejectedFunc(obj: User | null) {
	return (
		obj?.linkedInVerificationStatus === 'REJECTED' ||
		obj?.linkedInVerificationStatus === 'MANUAL_VERIFICATION_PENDING'
	);
}

export function isAccountPendingFunc(obj: User | null) {
	return (
		obj?.linkedInVerificationStatus === 'PENDING' ||
		obj?.linkedInVerificationStatus === 'PENDING_MESSAGE_SENT'
	);
}

export function isPendingMessageSentFunc(obj: User | null) {
	return (
		obj?.linkedInVerificationStatus === 'PENDING_MESSAGE_SENT' &&
		!!obj?.linkedInVerificationPossible
	);
}

export function isAccountBlockedFunc(obj: User | null) {
	return obj?.userStatus === 'BLOCKED';
}

export function getFullNameFunc(obj: User | null) {
	return `${obj?.firstName ?? ''} ${obj?.lastName ?? ''}`;
}

export function getFullNameLinkedInFunc(obj: UserLinkedProfileInMetaData | null) {
	return `${obj?.firstName ?? ''} ${obj?.lastName ?? ''}`;
}

export function isLinkedInStatusAvailableFunc(obj: User | null) {
	return (
		obj?.linkedInVerificationStatus === 'MANUAL_VERIFICATION_PENDING' ||
		obj?.linkedInVerificationStatus === 'PENDING' ||
		obj?.linkedInVerificationStatus === 'PENDING_MESSAGE_SENT' ||
		obj?.linkedInVerificationStatus === 'REJECTED' ||
		obj?.linkedInVerificationStatus === 'UNVERIFIED' ||
		obj?.linkedInVerificationStatus === 'VERIFIED'
	);
}
