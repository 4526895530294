import React, { useEffect, useRef, useState } from 'react';
import classes from './ScrollChatCards.styles.module.scss';
import { IScrollChatCardsProps } from './ScrollChatCards.types';
import { AppDispatch } from '@store/index';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from '@hooks/useWindow';
import { useSearchParams } from 'react-router-dom';
import {
	fetchPaginatedInboxData,
	getInboxDataFromGlobalState,
	setCurrChatsDataSelectedChatId,
} from '@store/inboxSlice/inboxSlice';
import {
	getSelectedChatDetailsFromGlobalState,
	setSelectedChat,
} from '@store/selectedChatSlice/selectedChatSlice';
import useInboxLayoutContext from '@hooks/useInboxLayoutContext';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import {
	BULK_ACTIONS_NUDGE_CHATS_DATA_THRESHOLD,
	CHAT_SEARCH_PARAM,
	FETCH_CHATS_DATA_PAGE_SIZE,
	UNREAD,
	UNREAD_OPTIONS,
	mixPanelEvents,
} from '@utils/constants';
import mixpanelActions from '@utils/mixpanel';
import InboxError from '@components/InboxError';
import { getEmptyStatesData } from '@utils/common';
import NoResultsFound from '@components/NoResultsFound';
import Text from '@components/ui/Text';
import { SenderChatData } from '@src/models/inbox';
import ScrollChatCard from './ScrollChatCard/ScrollChatCard';
import clsx from 'clsx';
import InboxMoreActions from '@components/InboxLayout/InboxMoreActions';

function ScrollChatCards({ isFiltersDisplayed, parentRef }: IScrollChatCardsProps) {
	const dispatch = useDispatch<AppDispatch>();
	const { isMobile } = useWindowSize();
	const [searchParams, setSearchParams] = useSearchParams();
	const {
		chatsData,
		fetchStatus,
		paginationDetails,
		currChatsDataSelectedChatId,
		currChatsDataSelectedChatInboxType,
		selectedMainFilter,
		tapToRefresh,
		selectedSingleFilters,
		currChatsDataSelectedChatIndex,
		filters,
		chatsDataTotalCount,
	} = useSelector(getInboxDataFromGlobalState);
	const { selectedChat } = useSelector(getSelectedChatDetailsFromGlobalState);
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const { isTabDefaultFiltersChanged, onClickMainFilter, onClickSingleFilter } =
		useInboxLayoutContext();
	const [canDismissChatCard, setCanDismissChatCard] = useState(false);

	const loadingRef = useRef<HTMLDivElement | null>(null);
	const chatIdRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
	const nudgeContainerRef = useRef<HTMLDivElement | null>(null);

	const pageSize = paginationDetails?.pageSize ?? FETCH_CHATS_DATA_PAGE_SIZE;

	const isLoadingPaginatedData = paginationDetails?.hasMoreChats ?? false;

	const selectedChatUsernameFromURL = decodeURIComponent(searchParams.get(CHAT_SEARCH_PARAM) ?? '');

	const isUnreadFilterSelected = selectedSingleFilters[UNREAD] === UNREAD;

	const showEmptyState =
		!chatsData.length &&
		fetchStatus !== 'loading' &&
		fetchStatus !== 'idle' &&
		!selectedChatUsernameFromURL;
	const showErrorState = fetchStatus === 'error';

	const showBulkActionsNudge =
		!isLoadingPaginatedData && chatsDataTotalCount >= BULK_ACTIONS_NUDGE_CHATS_DATA_THRESHOLD;

	const handleOpenChat = (selectedChatData: SenderChatData) => {
		const selectedChatDataSenderDetails = selectedChatData.senderDetails;

		if (!selectedChatDataSenderDetails) return;

		mixpanelActions.trackInboxChat(
			mixPanelEvents.INBOX_CHAT_OPENED,
			selectedChatDataSenderDetails.username ?? '',
			`${selectedChatData.read ? 'true' : 'false'}`,
			''
		);

		dispatch(
			setCurrChatsDataSelectedChatId({
				chatId: selectedChatData.chatId,
				index: chatsData.length,
				inboxType: 'INBOX_VIEW',
			})
		);
		dispatch(setSelectedChat({ selectedChatData: selectedChatData }));

		setSearchParams(
			(prevParams) => {
				const searchParamsToUpdate = new URLSearchParams(prevParams);
				searchParamsToUpdate.set(
					CHAT_SEARCH_PARAM,
					encodeURIComponent(selectedChatDataSenderDetails.username)
				);
				return searchParamsToUpdate;
			},
			{ replace: !!selectedChatUsernameFromURL }
		);
	};

	useEffect(() => {
		mixpanelActions.trackInbox(mixPanelEvents.INBOX_OPENED, `${chatsData.length}`, 'no');
	}, [chatsData]);

	useEffect(() => {
		if (
			!selectedChat &&
			currChatsDataSelectedChatId &&
			currChatsDataSelectedChatInboxType === 'INBOX_VIEW'
		) {
			chatIdRefs.current[currChatsDataSelectedChatId]?.scrollIntoView({
				behavior: 'instant',
				block: 'center',
			});
			setCanDismissChatCard(true);
			if (isUnreadFilterSelected && !isMobile) {
				onClickSingleFilter('card_layout', 'SCROLL_CARD', true);
			}
		} else {
			setCanDismissChatCard(false);
		}
	}, [selectedChat]);

	useEffect(() => {
		const obsElement = loadingRef.current;

		if (!obsElement || !chatsData) return;

		const obsOptions = {
			root: null,
			threshold: 0.5,
		};

		const obsCallback = (entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;

			if (entry.isIntersecting) {
				dispatch(
					fetchPaginatedInboxData({
						lastFiveMessagesRequired: false,
						topPicksThreshold: userDetails?.topPicksThreshold,
					})
				);
			}
		};

		const observer = new IntersectionObserver(obsCallback, obsOptions);
		observer.observe(obsElement);

		return () => {
			if (!obsElement) return;
			observer.unobserve(obsElement);
		};
	}, [chatsData]);

	useEffect(() => {
		const obsElement = nudgeContainerRef.current;
		const parentElement = parentRef?.current;

		if (parentElement) {
			parentElement.classList.remove(classes.parentEleBackgroundChange);
		}

		if (!obsElement || !parentElement) return;

		const obsOptions = {
			root: null,
			threshold: 0.5,
		};

		const obsCallback = (entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;

			if (entry.isIntersecting) {
				parentElement.classList.add(classes.parentEleBackgroundChange);
			} else {
				parentElement.classList.remove(classes.parentEleBackgroundChange);
			}
		};

		const observer = new IntersectionObserver(obsCallback, obsOptions);

		observer.observe(obsElement);

		return () => {
			if (!obsElement) return;
			observer.unobserve(obsElement);
		};
	}, [showBulkActionsNudge, parentRef]);

	if (showErrorState) {
		return <InboxError />;
	}

	if (showEmptyState) {
		const emptyStateData = getEmptyStatesData({
			selectedMainFilterName: selectedMainFilter?.name,
			isMobile: isMobile,
			isFiltersDisplayed: isFiltersDisplayed,
			isUnreadFilterSelected: isUnreadFilterSelected,
		});

		const Icon = emptyStateData.icon;

		const ctaText = isTabDefaultFiltersChanged
			? emptyStateData.clearFiltersCTAText
			: tapToRefresh
			? emptyStateData.tapToRefreshCTAText
			: isUnreadFilterSelected
			? emptyStateData.viewAllDMsCTAText
			: '';

		const unreadFilter = filters.find((filter) => filter.filterName === UNREAD);

		return (
			<NoResultsFound
				heading={emptyStateData.heading}
				subText={emptyStateData.subText}
				styles={emptyStateData.containerStyles}
				icon={<Icon size={emptyStateData.iconSize} style={emptyStateData.iconStyles} />}
				ctaActionText={ctaText}
				ctaAction={() => {
					if (isTabDefaultFiltersChanged) {
						selectedMainFilter && onClickMainFilter(selectedMainFilter);
					} else if (tapToRefresh) {
						onClickSingleFilter('tap_to_refresh', 'true', true);
					} else {
						unreadFilter && onClickSingleFilter(UNREAD, UNREAD_OPTIONS['NOT_UNREAD'], true);
					}
				}}
			/>
		);
	}

	return (
		<div className={classes.scrollChatCardsContainer}>
			<div className={classes.cardsContainer}>
				{chatsData.map((chatData, index) => {
					const chatId = chatData.chatId;
					const disableAnimation =
						currChatsDataSelectedChatIndex && index < currChatsDataSelectedChatIndex;

					return (
						<div
							key={chatId}
							role="button"
							tabIndex={0}
							aria-label="click to chat"
							className={clsx(classes.chatRow, disableAnimation && classes.chatRowNoAnimation)}
							onClick={(e) => {
								e.stopPropagation();
								handleOpenChat(chatData);
							}}
							style={{
								animationDelay: disableAnimation ? 'unset' : `${(index % pageSize) * 0.05}s`,
							}}
							ref={(el) => {
								if (chatId) {
									chatIdRefs.current[chatId] = el;
								}
							}}
						>
							<ScrollChatCard
								withBorder={
									!(index === chatsData.length - 1 && !isLoadingPaginatedData) ||
									showBulkActionsNudge
								}
								inboxChatsDataType={'INBOX_VIEW'}
								chatData={chatData}
								onOpenChatClick={() => handleOpenChat(chatData)}
								chatsDataSelectedChatId={currChatsDataSelectedChatId}
								canDismissCard={canDismissChatCard && isMobile}
							/>
						</div>
					);
				})}
				<div aria-hidden ref={loadingRef} className={classes.loadingContainer}>
					{isLoadingPaginatedData && (
						<Text variant="p" ultraLight small secondary>
							{'Loading DMs...'}
						</Text>
					)}
				</div>
				{showBulkActionsNudge && (
					<div ref={nudgeContainerRef}>
						<InboxMoreActions nudgeMode chatsView={'SCROLL_CARD'} />
					</div>
				)}
			</div>
		</div>
	);
}

export default ScrollChatCards;
