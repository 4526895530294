import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { getUserMetaData } from '@api/auth';
import useFetch from '@hooks/useFetch';
import classes from './ShareableLinkPage.styles.module.scss';
import {
	PAGE_NOT_FOUND_PATH,
	INTERNAL_ERROR,
	INBOX_PATH,
	CHAT_SEARCH_PARAM,
} from '@utils/constants';
import { getFullNameUserMetaDataFunc } from '@src/models/user';
import DotsLoader from '@components/ui/DotsLoader';
import Onboarding from '@components/Onboarding';

function ShareableLinkPage() {
	const params = useParams();
	const username = params.username ?? '';

	const {
		callApi: fetchMetaData,
		status: fetchUserMetaDataStatus,
		response: fetchUserMetaDataResponse,
		error: fetchUserMetaDataError,
		errorStatus: fetchUserMetaDataErrorStatus,
	} = useFetch(getUserMetaData);

	useEffect(() => {
		if (!username) return;
		(async function () {
			try {
				await fetchMetaData(username);
			} catch (error) {
				// handle error
			}
		})();
	}, []);

	const userMetaDataLoading =
		(fetchUserMetaDataStatus === 'idle' || fetchUserMetaDataStatus === 'loading') && !!username;

	if (userMetaDataLoading) {
		return (
			<div className={classes.shareableLinkContainer}>
				<DotsLoader />
			</div>
		);
	}

	if (fetchUserMetaDataError && fetchUserMetaDataErrorStatus === 400) {
		return <Navigate to={PAGE_NOT_FOUND_PATH} />;
	}

	if (fetchUserMetaDataError) {
		return <Navigate to={INTERNAL_ERROR} />;
	}

	return (
		<Onboarding
			redirectURL={`${INBOX_PATH}?${CHAT_SEARCH_PARAM}=${username}`}
			receiverProfilePicURL={fetchUserMetaDataResponse?.imageURL ?? ''}
			receiverUserName={username}
			receiverPreferences={fetchUserMetaDataResponse?.preferences ?? ''}
			receiverIntroUpdatesTextArr={fetchUserMetaDataResponse?.introUpdatesText ?? []}
			receiverFirstName={fetchUserMetaDataResponse?.firstName ?? username}
			receiverFullName={
				fetchUserMetaDataResponse ? getFullNameUserMetaDataFunc(fetchUserMetaDataResponse) : ''
			}
			receiverInboxCategoriesMap={fetchUserMetaDataResponse?.InboxCategoriesMap ?? {}}
			receiverMaxCharCountEnabled={fetchUserMetaDataResponse?.maxCharCountEnabled}
			receiverMinCharCountEnabled={fetchUserMetaDataResponse?.minCharCountEnabled}
		/>
	);
}

export default ShareableLinkPage;
