import React, { useEffect, useMemo, useRef, useState } from 'react';
import SettingsSection from '../SettingsSection';
import ICategoriesProps, { INewInboxCategories } from './Categories.types';
import { useDispatch, useSelector } from 'react-redux';
import {
	getUserDetailsFromGlobalState,
	setUserSettings,
} from '@store/userDetailsSlice/userDetailsSlice';
import classes from './Categories.styles.module.scss';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import { AlertIcon, CheckCircleIcon, DeleteIcon, DragIcon } from '@src/hoc/withIconStyles';
import Toast from '@components/ui/Toast';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import { InboxCategories } from '@src/models/user';
import useFetch from '@hooks/useFetch';
import { editUserSettings } from '@api/user';
import { IEditUserSettingsPayload } from '@api/user/user.types';
import { AppDispatch } from '@store/index';
import Modal from '@components/ui/Modal';
import useWindowSize from '@hooks/useWindow';

const CHARACTER_LIMIT = 40;

function Categories({ setCurrEditSection, currEditSection }: ICategoriesProps) {
	const dispatch = useDispatch<AppDispatch>();
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const { isDesktop } = useWindowSize();

	const [editMode, setEditMode] = useState(false);
	const [showAlertModal, setShowAlertModal] = React.useState(false);
	const [newCategoryName, setNewCategoryName] = useState('');
	const [newInboxCategories, setNewInboxCategories] = useState<INewInboxCategories[]>([]);
	const toastRef = useRef<IRefProps>(null);

	const dragItem = useRef<number | null>(null);
	const dragOverItem = useRef<number | null>(null);

	const {
		callApi: callEditUserCategories,
		error: editUserCategoriesError,
		status: editUserCategoriesStatus,
	} = useFetch(editUserSettings);

	const getNewCategoriesArrFromOriginal = (categories: InboxCategories[]) => {
		const categoriesToAdd: INewInboxCategories[] = categories.map((category) => {
			return {
				id: category.name,
				name: category.name,
			};
		});

		return categoriesToAdd;
	};

	useEffect(() => {
		if (!userDetails) return;
		setNewInboxCategories(getNewCategoriesArrFromOriginal(userDetails?.inboxCategories ?? []));
	}, [userDetails]);

	const isCategoryNameExist = newInboxCategories.find(
		(newCategory) => newCategory.name.trim() === newCategoryName.trim()
	);

	const addBtnDisabled = newCategoryName.length === 0 || !!isCategoryNameExist;

	const isCurrSectionInEditMode = currEditSection === 'CATEGORIES';
	const isEditBtnDisabled = currEditSection !== null && currEditSection !== 'CATEGORIES';

	const isSaveBtnDisabled = useMemo(() => {
		const inboxCategories = userDetails?.inboxCategories ?? [];

		if (inboxCategories.length !== newInboxCategories.length) {
			return false; // Arrays are not equal in length
		}

		// Check if the order or names of categories have changed
		for (let i = 0; i < newInboxCategories.length; i++) {
			const newCategory = newInboxCategories[i];
			const originalCategory = inboxCategories[i];

			if (newCategory.name !== originalCategory.name) {
				return false; // Categories are not equal
			}
		}

		return true; // Arrays are equal
	}, [userDetails?.inboxCategories, newInboxCategories]);

	const handleDeleteCategory = (categoryId: string) => {
		setNewInboxCategories((prevState) =>
			prevState.filter((newCategory) => newCategory.id !== categoryId)
		);
	};

	const handleOnChangeCategoryName = (categoryId: string, value: string) => {
		if (value.length > CHARACTER_LIMIT) return;
		const updatedNewCategories = newInboxCategories.map((newCategory) => {
			if (newCategory.id !== categoryId) return newCategory;
			return { ...newCategory, name: value };
		});

		setNewInboxCategories(updatedNewCategories);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > CHARACTER_LIMIT) return;
		setNewCategoryName(e.target.value);
	};

	const handleAddCategory = () => {
		const newCategoryToAdd: INewInboxCategories = {
			id: newCategoryName,
			name: newCategoryName,
		};

		setNewInboxCategories((prevState) => [...prevState, newCategoryToAdd]);
		setNewCategoryName('');
	};

	const handleOnClickEdit = () => {
		setCurrEditSection('CATEGORIES');
	};

	const handleOnClickCancel = () => {
		setNewCategoryName('');
		setNewInboxCategories(getNewCategoriesArrFromOriginal(userDetails?.inboxCategories ?? []));
		setCurrEditSection(null);
	};

	const callEditApi = async () => {
		toastRef.current?.unPublish();
		if (!userDetails) return;

		const payload: IEditUserSettingsPayload = {
			user_id: userDetails.userId,
			inbox_categories: newInboxCategories.map((newCategory, index) => {
				return {
					name: newCategory.name.trim(),
					sender_priority: newInboxCategories.length - index,
				};
			}),
		};

		try {
			const res = await callEditUserCategories(payload);
			dispatch(
				setUserSettings({
					inboxCategories: res.inboxCategories,
					preferences: res.preferences,
				})
			);
			setEditMode(false);
			setShowAlertModal(false);
			setCurrEditSection(null);
			toastRef.current?.publish();
		} catch (error) {
			toastRef.current?.publish();
		}
	};

	const handleOnClickSave = async () => {
		setShowAlertModal(true);
	};

	const handleDragEnd = () => {
		dragItem.current = null;
		dragOverItem.current = null;
	};

	const handleDragStart = (position: number) => {
		dragItem.current = position;
	};

	const handleDragEnter = (position: number) => {
		dragOverItem.current = position;
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const handleDrop = () => {
		const copyNewInboxCategories = [...newInboxCategories];
		if (dragItem.current !== null && dragOverItem.current !== null) {
			const dragItemContent = copyNewInboxCategories[dragItem.current];
			copyNewInboxCategories.splice(dragItem.current, 1);
			copyNewInboxCategories.splice(dragOverItem.current, 0, dragItemContent);
			setNewInboxCategories(copyNewInboxCategories);
			handleDragEnd(); // Reset dragItem and dragOverItem
		}
	};

	const handleCloseReportModal = () => {
		if (editUserCategoriesStatus === 'loading') return;
		setShowAlertModal(false);
	};

	return (
		<SettingsSection
			header={'Categories'}
			headerSubText={
				'People will need to choose one of these categories when messaging you for the first time.'
			}
			editBtnDisabled={isEditBtnDisabled}
			saveBtnDisabled={isSaveBtnDisabled}
			onClickCancel={handleOnClickCancel}
			onClickEdit={handleOnClickEdit}
			onClickSave={handleOnClickSave}
			editMode={editMode}
			setEditMode={setEditMode}
		>
			{newInboxCategories.map((category, index) => {
				if (!isCurrSectionInEditMode) {
					return (
						<div className={classes.categoryBox} key={category.id}>
							<Text variant="p" tiny lineHeight={1.6}>
								{category.name}
							</Text>
						</div>
					);
				}

				return (
					<div
						className={classes.categoryBox}
						key={category.id}
						draggable
						onDragStart={() => handleDragStart(index)}
						onDragEnter={() => handleDragEnter(index)}
						onDragOver={handleDragOver}
						onDrop={handleDrop}
					>
						<div className={classes.categoryInputContainer}>
							{isDesktop && <DragIcon size={1.6} className={classes.dragIcon} />}
							<input
								type="text"
								className={classes.input}
								value={category.name}
								onChange={(e) => {
									handleOnChangeCategoryName(category.id, e.target.value);
								}}
								autoComplete="off"
							/>
							<Button
								btnText={<DeleteIcon size={1.6} />}
								onClick={() => handleDeleteCategory(category.id)}
								customClass={classes.deleteBtn}
							/>
						</div>
					</div>
				);
			})}
			{isCurrSectionInEditMode && (
				<div className={classes.inputContainer}>
					<input
						className={classes.input}
						placeholder="Add a new category..."
						value={newCategoryName}
						onChange={handleInputChange}
						autoComplete="off"
					/>
					<Button
						btnText={
							<Text variant="span" small white={!addBtnDisabled} disabled={addBtnDisabled}>
								{'Add'}
							</Text>
						}
						primary
						disabled={addBtnDisabled}
						onClick={handleAddCategory}
					/>
				</div>
			)}
			{showAlertModal && (
				<Modal
					header={'Confirm Edit?'}
					onCloseModal={handleCloseReportModal}
					showModal={showAlertModal}
				>
					<div className={classes.alertModalContentContainer}>
						<Text variant="p" light small>
							{
								'New people reaching out to you will now need to choose from the updated list of categories. Previous chats will not be affected.'
							}
						</Text>
						<div className={classes.buttonsContainer}>
							<Button
								btnText={
									<Text variant="span" small semiBold>
										{'Cancel'}
									</Text>
								}
								onClick={handleCloseReportModal}
								customClass={classes.modalCancelBtn}
								disabled={editUserCategoriesStatus === 'loading'}
							/>
							<Button
								btnText={
									<Text variant="span" white small semiBold>
										{'Yes'}
									</Text>
								}
								primary
								onClick={callEditApi}
								customClass={classes.modalContinueBtn}
								isLoading={editUserCategoriesStatus === 'loading'}
							/>
						</div>
					</div>
				</Modal>
			)}
			<Toast
				ref={toastRef}
				toastType={editUserCategoriesStatus === 'success' ? 'INFO' : 'ERROR'}
				icon={
					editUserCategoriesStatus === 'success' ? (
						<CheckCircleIcon size={1.8} />
					) : (
						<AlertIcon size={1.8} className={classes.toastIcon} />
					)
				}
				header={
					editUserCategoriesStatus === 'success'
						? 'Categories updated'
						: editUserCategoriesError ?? 'something went wrong, please try again'
				}
			/>
		</SettingsSection>
	);
}

export default Categories;
