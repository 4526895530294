import React from 'react';
import clsx from 'clsx';
import Text from '@components/ui/Text';
import ISettingsSectionProps from './SettingsSection.types';
import classes from './SettingsSection.styles.module.scss';
import Button from '@components/ui/Button';
import { EditIconV4 } from '@src/hoc/withIconStyles';

function SettingsSection({
	header,
	headerSubText,
	editBtnDisabled,
	onClickSave,
	onClickEdit,
	onClickCancel,
	saveBtnDisabled,
	editMode,
	setEditMode,
	children,
}: ISettingsSectionProps) {
	const handleOnClickEdit = () => {
		setEditMode(true);
		onClickEdit();
	};

	const handleOnClickCancel = () => {
		setEditMode(false);
		onClickCancel();
	};

	const handleOnClickSave = async () => {
		onClickSave();
	};

	return (
		<section className={classes.settingsSection}>
			<Text variant="h2" semiBold>
				{header}
			</Text>
			<Text variant="p" tiny secondary light lineHeight={1.6}>
				{headerSubText}
			</Text>
			<div className={classes.btnsContainer}>
				{!editMode && (
					<Button
						prefixIcon={
							<EditIconV4
								size={1.6}
								disabled={editBtnDisabled}
								className={clsx(classes.editIcon, editBtnDisabled && classes.editIconDisabled)}
							/>
						}
						btnText={
							<Text variant="span" small lineHeight={1.6} disabled={editBtnDisabled}>
								{'Edit'}
							</Text>
						}
						disabled={editBtnDisabled}
						onClick={handleOnClickEdit}
						customClass={classes.editBtn}
					/>
				)}
				{editMode && (
					<>
						<Button
							btnText={
								<Text variant="span" small lineHeight={1.6}>
									{'Cancel'}
								</Text>
							}
							onClick={handleOnClickCancel}
							customClass={classes.cancelBtn}
						/>
						<Button
							btnText={
								<Text
									variant="span"
									small
									lineHeight={1.6}
									white={!saveBtnDisabled}
									disabled={saveBtnDisabled}
								>
									{'Save'}
								</Text>
							}
							primary
							disabled={saveBtnDisabled}
							onClick={handleOnClickSave}
							customClass={classes.saveBtn}
						/>
					</>
				)}
			</div>
			{children}
		</section>
	);
}

export default SettingsSection;
