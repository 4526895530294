/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchInboxSearchData,
	getInboxDataFromGlobalState,
	setCurrChatsDataSelectedChatVisited,
} from '@store/inboxSlice/inboxSlice';
import { AppDispatch } from '@store/index';
import Text from '@components/ui/Text';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import clsx from 'clsx';
import MainLayout from '@components/MainLayout';
import {
	clearSelectedChat,
	getSelectedChatDetailsFromGlobalState,
} from '@store/selectedChatSlice/selectedChatSlice';
import { isProUserFunc } from '@src/models/user';
import withAuth from '@src/hoc/withAuth';
import Chats from '@components/Chats';
import { INBOX_PATH, INBOX_SEARCH_PATH, mixPanelEvents } from '@utils/constants';
import useWindowSize from '@hooks/useWindow';
import classes from './SearchPage.styles.module.scss';
import SearchInbox from './SearchInbox';
import mixpanelActions from '@utils/mixpanel';
import DotsLoader from '@components/ui/DotsLoader';

export function SearchPage() {
	const dispatch = useDispatch<AppDispatch>();
	const [searchParams] = useSearchParams();

	const {
		searchFetchStatus,
		searchTermsQueryMap,
		searchChatsData,
		currChatsDataSelectedChatId,
		currChatsDataSelectedChatInboxType,
		currChatDataSelectedChatVisited,
	} = useSelector(getInboxDataFromGlobalState);
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const { selectedChat } = useSelector(getSelectedChatDetailsFromGlobalState);

	const { isMobile } = useWindowSize();

	const [renderChats, setRenderChats] = useState<boolean>(false);

	const searchStr = decodeURIComponent(searchParams.get('search') ?? '');

	useEffect(() => {
		dispatch(clearSelectedChat());
		setRenderChats(true);
	}, []);

	useEffect(() => {
		if (!searchStr) return;

		if (
			currChatsDataSelectedChatId &&
			currChatsDataSelectedChatInboxType === 'SEARCH_VIEW' &&
			currChatDataSelectedChatVisited
		) {
			dispatch(setCurrChatsDataSelectedChatVisited({ isVisited: false }));
			return;
		}

		const searchTermQuery = searchTermsQueryMap[searchStr] ?? null;

		dispatch(fetchInboxSearchData({ searchStr: searchStr, searchQuery: searchTermQuery }));
	}, [searchStr]);

	useEffect(() => {
		if (!searchStr) return;
		if (searchFetchStatus === 'success') {
			mixpanelActions.trackSearch(
				mixPanelEvents.SEARCH_RESULTS_SHOWN,
				searchStr,
				searchChatsData.length
			);
		}
	}, [searchStr, searchChatsData, searchFetchStatus]);

	const isProUser = isProUserFunc(userDetails) ?? false;

	const isLoadingData = searchFetchStatus === 'loading';

	const inChatOpenMode = renderChats && !!selectedChat;

	if (!isProUser || !searchStr) {
		return <Navigate to={INBOX_PATH} replace />;
	}

	if (isMobile) {
		return <Navigate to={`${INBOX_SEARCH_PATH}?search=${searchStr}`} replace />;
	}

	return (
		<MainLayout withSearch>
			<section className={classes.messageLayout}>
				<div className={classes.mainFiltersContainer}>
					<Text variant="h2" medium light customClass={classes.searchStrContainer}>
						{`Showing results for `}
						<Text variant="span" bold medium>
							{searchStr}
						</Text>
					</Text>
				</div>
				<section
					className={clsx(classes.inboxMainLayout, !isProUser && classes.liteUserInboxLayout)}
				>
					{isLoadingData && (
						<div className={classes.loadingContainer}>
							<DotsLoader />
						</div>
					)}
					{!isLoadingData && (
						<div
							className={clsx(classes.inboxMainChild, selectedChat && classes.inboxMainChildBorder)}
						>
							<SearchInbox />
							<div className={clsx(classes.chats, selectedChat && classes.showChats)}>
								{inChatOpenMode && <Chats />}
							</div>
						</div>
					)}
				</section>
			</section>
		</MainLayout>
	);
}

export default withAuth(SearchPage);
