import React from 'react';
import Text from '@components/ui/Text';
import { LinkIcon } from '@src/hoc/withIconStyles';
import Button from '@components/ui/Button';
import classes from './JoinWaitList.styles.module.scss';
import useWindowSize from '@hooks/useWindow';
import { EARLY_ACCESS_FORM, mixPanelEvents } from '@utils/constants';
import mixpanelActions from '@utils/mixpanel';
import ScrollableLayout from '@components/ScrollableLayout';
import { IJoinWaitListProps } from './JoinWaitList.types';
import clsx from 'clsx';

function JoinWaitList({ withLayout }: IJoinWaitListProps) {
	const { isMobile } = useWindowSize();

	const handleJoinBtnClick = () => {
		mixpanelActions.trackInboxEmptyStates(mixPanelEvents.INBOX_CLAIM_LINK_CLICKED);
		window.open(EARLY_ACCESS_FORM, '_blank');
	};

	const container = (
		<section className={classes.joinWaitListContainer}>
			<section className={clsx(classes.joinWaitListCard, !withLayout && classes.noBoxShadow)}>
				<div className={classes.linkIconContainer}>
					<LinkIcon size={isMobile ? 4.2 : 6.4} className={classes.icon} />
				</div>
				<Text variant="h1" extraMedium={!isMobile} medium2M={isMobile} semiBold>
					{'Get your SuperDM link'}
				</Text>
				<Text variant="p" secondary light small={isMobile}>
					{`To start receiving messages, you need a SuperDM link.`}
				</Text>
				<Button
					btnText={
						<Text variant="span" white semiBold small={isMobile}>
							{'Get early access'}
						</Text>
					}
					primary
					onClick={handleJoinBtnClick}
					customClass={classes.joinBtn}
				/>
			</section>
		</section>
	);

	if (withLayout) {
		return <ScrollableLayout>{container}</ScrollableLayout>;
	}

	return container;
}

export default JoinWaitList;
