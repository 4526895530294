/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearTabOverviewChatsData,
	getFiltersObj,
	getInboxDataFromGlobalState,
	setCurrChatsListView,
	setTabOverviewDetails,
} from '@store/inboxSlice/inboxSlice';
import { AppDispatch } from '@store/index';
import {
	archivePreAppliedFilters,
	archiveOverviewHeading,
	ALL,
	UNREAD,
	mixPanelEvents,
} from '@utils/constants';
import Inbox from '@components/Inbox/Inbox';
import { Tabs, isPendingMessageSentFunc } from '@src/models/user';
import { IAppliedFiltersPayload } from '@api/chats/chats.types';
import Filters from '@components/Filters';
import { IFiltersRefObj } from '@components/Filters/Filters.types';
import classes from './InboxLayoutMobile.styles.module.scss';
import DotsLoader from '@components/ui/DotsLoader';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import {
	ChevronRightIcon,
	CloseIcon,
	ListViewIcon,
	RowsIcon,
	SwipeViewIcon,
} from '@src/hoc/withIconStyles';
import MainFilters from '../MainFilters';
import useInboxLayoutContext from '@hooks/useInboxLayoutContext';
import MainLayoutHeader from '@components/MainLayout/MainLayoutHeader';
import Chats from '@components/Chats';
import SwipeCardsContainer from '@components/SwipeChatCards/SwipeChatCards';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import VerifyLinkedInAlertTopHeader from '@components/VerifyLinkedInAlertTopHeader';
import ScrollChatCards from '@components/ScrollChatCards';
import Overview from '../Overview/Overview';
import mixpanelActions from '@utils/mixpanel';
import InboxMoreActions from '../InboxMoreActions';

export function InboxLayoutMobile() {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const {
		isLoadingData,
		selectedChatUsernameFromURL,
		filtersWithOptions,
		mainFilters,
		savedFiltersFromInboxOverview,
		isTabDefaultFiltersChanged,
		onClickMainFilter,
		onClickMultiFilter,
		onClickSingleFilter,
		onClickOverviewFilter,
		isProUser,
		isFiltersChanged,
	} = useInboxLayoutContext();

	const {
		selectedMainFilter,
		newMainFilterOverviewDetails,
		archivedChatsDataTotalCount,
		chatsDataTotalCount,
		chatsData,
		selectedMultiFilters,
		selectedSingleFilters,
		filters,
		currChatsListView,
	} = useSelector(getInboxDataFromGlobalState);

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const filtersRefObj = useRef<IFiltersRefObj>({} as IFiltersRefObj);
	const layoutWithFiltersRef = useRef<HTMLDivElement | null>(null);
	const recentSelectedFilterRef = useRef<string>('');
	const backgroundModeFiltersRef = useRef<{ [key: string]: string }>({});

	const showVerifyLinkedInAlert = isPendingMessageSentFunc(userDetails);

	const showMainFilters = !!mainFilters.length;

	const showSaveFiltersAsTab = isFiltersChanged;

	const filtersToDisplay = filtersWithOptions;

	const showFilters = showMainFilters && filtersToDisplay.length > 0;

	const isUnreadFilterSelected = selectedSingleFilters[UNREAD] === UNREAD;

	const swipeCardMode = isUnreadFilterSelected && currChatsListView === 'SWIPE_CARD';
	const scrollCardMode = isUnreadFilterSelected && currChatsListView === 'SCROLL_CARD';

	const showCardView = swipeCardMode || scrollCardMode;

	const isOverviewDetailsAvailable =
		newMainFilterOverviewDetails &&
		newMainFilterOverviewDetails.overview &&
		(newMainFilterOverviewDetails.overview.categories.length > 0 ||
			newMainFilterOverviewDetails.overview.savedFilters.length > 0);

	const showOverviewData =
		!!isOverviewDetailsAvailable &&
		!selectedChatUsernameFromURL &&
		!isTabDefaultFiltersChanged &&
		selectedMainFilter?.name === ALL &&
		!!chatsData.length;

	const scrollMode = !!chatsData.length && !isLoadingData;

	const showCountText =
		!!chatsDataTotalCount && selectedMainFilter?.name === ALL && showMainFilters;

	const showCountTextToDisplay = `Showing ${chatsDataTotalCount} ${
		chatsDataTotalCount > 1 ? 'DMs' : 'DM'
	}`;

	const showArchivedCountCTA = showCountText && !!archivedChatsDataTotalCount;

	const archivedTextToDisplay = `${archivedChatsDataTotalCount} archived`;

	const handleOnClickMainFilter = async (mainFilterToSelect: Tabs) => {
		onClickMainFilter(mainFilterToSelect);
		recentSelectedFilterRef.current = '';
		backgroundModeFiltersRef.current = {};
	};

	const handleOnClickMultiFilter = (filterName: string, updatedOptionsIds: string[]) => {
		onClickMultiFilter(filterName, updatedOptionsIds);
		recentSelectedFilterRef.current = updatedOptionsIds.length > 0 ? filterName : '';
	};

	const handleOnClickSingleFilter = (filterName: string, optionId: string, isSelected: boolean) => {
		onClickSingleFilter(filterName, optionId, isSelected);
		recentSelectedFilterRef.current = isSelected ? filterName : '';
		backgroundModeFiltersRef.current[filterName] = filterName;
	};

	const handleOverviewFilterClick = (preAppliedFiltersObj: IAppliedFiltersPayload) => {
		onClickOverviewFilter(preAppliedFiltersObj);
		recentSelectedFilterRef.current = '';
	};

	const handleOnSaveFilters = () => {
		if (!selectedMainFilter) return;
		handleOnClickMainFilter(selectedMainFilter);
	};

	const handleArchiveFilterClick = (
		preAppliedFilters: IAppliedFiltersPayload,
		filterName: string
	) => {
		if (!selectedMainFilter) return;
		dispatch(clearTabOverviewChatsData());
		dispatch(
			setTabOverviewDetails({
				preAppliedFilters: {
					...getFiltersObj(selectedSingleFilters, selectedMultiFilters, filters),
					...preAppliedFilters,
				},
				filterName: filterName,
			})
		);
		navigate(`/${selectedMainFilter.name.toLocaleLowerCase()}/overview`);
	};

	const handleInboxLayoutScroll = () => {
		const ele = layoutWithFiltersRef.current;

		if (!ele) return;

		if (ele.scrollTop > 0 && !ele.classList.contains(classes.inboxLayoutMobileScrollMode)) {
			ele.classList.add(classes.inboxLayoutMobileScrollMode);
		}

		if (ele.scrollTop <= 0 && ele.classList.contains(classes.inboxLayoutMobileScrollMode)) {
			ele.classList.remove(classes.inboxLayoutMobileScrollMode);
		}
	};

	// handling selected filter focus on mobile
	useEffect(() => {
		const recentSelectedFilterName = recentSelectedFilterRef.current;

		if (!filtersWithOptions.length) return;

		const filtersRef =
			filtersRefObj.current?.getFiltersRef && filtersRefObj.current.getFiltersRef();
		const filtersDivRef =
			filtersRefObj.current?.getFiltersDivRef && filtersRefObj.current.getFiltersDivRef();

		const filterToBeFocused = filtersRef?.[recentSelectedFilterName];
		const shouldFocusFilter = searchParams.has(recentSelectedFilterName) && filterToBeFocused;

		if (shouldFocusFilter) {
			filterToBeFocused.scrollIntoView({ behavior: 'instant', inline: 'center' });
			layoutWithFiltersRef.current?.scrollTo({ top: 0, behavior: 'instant' });
		} else {
			filtersDivRef?.scrollTo({ behavior: 'instant', left: 0 });
		}
	}, [filtersWithOptions]);

	if (selectedChatUsernameFromURL) {
		return (
			<div
				className={clsx(
					classes.inboxLayoutMobileChatView,
					isLoadingData && classes.inboxLayoutMobileLoading,
					!isLoadingData && classes.inboxLayoutMobileLoaded
				)}
			>
				<div
					className={clsx(
						classes.loadingContainer,
						!isProUser && classes.lightUserLoadingContainer
					)}
				>
					<DotsLoader />
				</div>
				<header className={classes.mainInboxLayoutHeader}>
					{showVerifyLinkedInAlert && <VerifyLinkedInAlertTopHeader />}
				</header>
				<Chats />
			</div>
		);
	}

	if (showCardView && swipeCardMode) {
		return (
			<div
				className={clsx(
					classes.inboxLayoutSwipeView,
					!scrollMode && classes.inboxLayoutSwipeViewEmptyState,
					isLoadingData && classes.inboxLayoutMobileLoading,
					!isLoadingData && classes.inboxLayoutMobileLoaded
				)}
			>
				<div
					className={clsx(
						classes.loadingContainer,
						!isProUser && classes.lightUserLoadingContainer
					)}
				>
					<DotsLoader />
				</div>
				<header className={clsx(classes.mainInboxLayoutHeader, classes.inboxLayoutSwipeViewHeader)}>
					<Button
						btnText={
							<Text variant="span" white lineHeight={2.2}>
								{'Focus mode'}
							</Text>
						}
						prefixIcon={<CloseIcon size={2.4} className={classes.closeIcon} />}
						onClick={() => {
							dispatch(setCurrChatsListView({ chatsListView: 'SCROLL_CARD' }));
							mixpanelActions.trackCardLayoutChange(
								mixPanelEvents.CARD_LAYOUT_CHANGED,
								'SCROLL_CARD'
							);
							onClickSingleFilter('card_layout', 'SCROLL_CARD', true);
						}}
					/>
				</header>
				<SwipeCardsContainer />
			</div>
		);
	}

	return (
		<div
			className={clsx(
				classes.inboxLayoutMobile,
				isLoadingData && classes.inboxLayoutMobileLoading,
				!isLoadingData && classes.inboxLayoutMobileLoaded
			)}
			ref={layoutWithFiltersRef}
			onScroll={handleInboxLayoutScroll}
		>
			<div
				className={clsx(classes.loadingContainer, !isProUser && classes.lightUserLoadingContainer)}
			>
				<DotsLoader />
			</div>
			<header className={classes.mainInboxLayoutHeader}>
				{showVerifyLinkedInAlert && <VerifyLinkedInAlertTopHeader />}
				<MainLayoutHeader
					withSearch
					renderLeftSection={
						showMainFilters ? (
							<MainFilters
								mainFilters={mainFilters}
								onSelectMainFilter={handleOnClickMainFilter}
								selectedMainFilter={selectedMainFilter}
								mobileView={true}
							/>
						) : null
					}
				/>
			</header>

			{showFilters && (
				<Filters
					filters={filtersToDisplay}
					isLoading={isLoadingData}
					onClickMultiFilter={handleOnClickMultiFilter}
					onClickSingleFilter={handleOnClickSingleFilter}
					savedFiltersFromInboxOverview={savedFiltersFromInboxOverview}
					selectedMainFilter={selectedMainFilter}
					selectedMultiFilters={selectedMultiFilters}
					selectedSingleFilters={selectedSingleFilters}
					showSaveFiltersAsTab={showSaveFiltersAsTab}
					onSaveFilters={handleOnSaveFilters}
					backgroundModeFiltersNameMap={backgroundModeFiltersRef.current}
					showClearFilters={isTabDefaultFiltersChanged}
					onClickClearFilters={() => {
						selectedMainFilter && handleOnClickMainFilter(selectedMainFilter);
					}}
					ref={filtersRefObj}
				/>
			)}

			{showOverviewData && (
				<Overview
					overviewDetails={newMainFilterOverviewDetails}
					onClickFilter={handleOverviewFilterClick}
				/>
			)}

			{showCountText && (
				<div
					className={clsx(
						classes.countTextContainerWrapper,
						!showOverviewData && classes.countTextContainerWrapperMargin,
						isUnreadFilterSelected && classes.countTextContainerWrapperUnreadSelected
					)}
				>
					<div className={classes.countTextContainer}>
						<Text variant="p" fontSize={1.3} lineHeight={1.8} semiBold secondary>
							{showCountTextToDisplay}
						</Text>
						{showArchivedCountCTA && (
							<Button
								onClick={() => {
									handleArchiveFilterClick(archivePreAppliedFilters, archiveOverviewHeading);
								}}
								btnText={
									<Text variant="span" tiny lineHeight={1.6} customClass={classes.archiveCtaText}>
										{archivedTextToDisplay}
									</Text>
								}
								suffixIcon={<ChevronRightIcon size={1.4} className={classes.archiveChevron} />}
							/>
						)}
					</div>
					{isUnreadFilterSelected && (
						<div className={classes.btnsContainer}>
							<Button
								btnText={<SwipeViewIcon size={3.2} />}
								onClick={() => {
									dispatch(setCurrChatsListView({ chatsListView: 'SWIPE_CARD' }));
									mixpanelActions.trackCardLayoutChange(
										mixPanelEvents.CARD_LAYOUT_CHANGED,
										'SWIPE_CARD'
									);
									onClickSingleFilter('card_layout', 'SWIPE_CARD', true);
								}}
							/>

							{!showCardView && (
								<Button
									btnText={<RowsIcon size={3.2} />}
									onClick={() => {
										dispatch(setCurrChatsListView({ chatsListView: 'SCROLL_CARD' }));
										mixpanelActions.trackCardLayoutChange(
											mixPanelEvents.CARD_LAYOUT_CHANGED,
											'SCROLL_CARD'
										);
										onClickSingleFilter('card_layout', 'SCROLL_CARD', true);
									}}
								/>
							)}

							{showCardView && (
								<Button
									btnText={<ListViewIcon size={3.2} />}
									onClick={() => {
										dispatch(setCurrChatsListView({ chatsListView: 'LIST_VIEW' }));
										mixpanelActions.trackCardLayoutChange(
											mixPanelEvents.CARD_LAYOUT_CHANGED,
											'LIST_VIEW'
										);
										onClickSingleFilter('card_layout', 'LIST_VIEW', true);
									}}
								/>
							)}

							<InboxMoreActions />
						</div>
					)}
				</div>
			)}

			{!showCardView && <Inbox scrollMode={!!scrollMode} isFiltersDisplayed={showFilters} />}

			{showCardView && scrollCardMode && <ScrollChatCards isFiltersDisplayed={showFilters} />}
		</div>
	);
}

export default InboxLayoutMobile;
