import { isSuperDMApp, shouldShowWebviewErrorModal } from '@utils/common';
import { Workbox } from 'workbox-window';

const isLocal = process.env.ENVIRONMENT === 'LOCAL';

export const ignoreServiceWorkerRegistration =
	shouldShowWebviewErrorModal(window.innerWidth <= 650) || isLocal || isSuperDMApp();

export default function registerServiceWorker() {
	if (ignoreServiceWorkerRegistration) return;

	// Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
	if ('serviceWorker' in navigator) {
		const wb = new Workbox(process.env.PUBLIC_PATH + 'firebase-messaging-sw.js');

		wb.addEventListener('installed', (event) => {
			if (event.isUpdate) {
				// TODO don't automatically reload the window
				// window.location.reload();
				console.log('New update available!!!');
			}
		});

		wb.register();
	} else {
		console.error('service worker not available');
	}
}

// Utility function to check if a service worker is registered and get its instance
export const getServiceWorkerInstance = async () => {
	if (ignoreServiceWorkerRegistration) {
		return {
			registered: false,
			instance: null,
		};
	}

	if ('serviceWorker' in navigator) {
		try {
			const wb = new Workbox(process.env.PUBLIC_PATH + 'firebase-messaging-sw.js');
			const serviceWorkerInstance = await wb.register();

			return {
				registered: true,
				instance: serviceWorkerInstance,
			};
		} catch (error) {
			console.error('Error getting service worker instance:', error);
			return {
				registered: false,
				instance: null,
			};
		}
	} else {
		return {
			registered: false,
			instance: null,
		};
	}
};
