import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { ILinkedInStepProps, TLinkedInConnectState } from './LinkedInStep.types';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/index';
import useFetch from '@hooks/useFetch';
import { getLinkedInProfileMetaData, registerUser } from '@api/auth';
import { getUserDetails } from '@api/user';
import { ACCESS_TOKEN, mainFiltersArr, mixPanelEvents } from '@utils/constants';
import mixpanelActions from '@utils/mixpanel';
import { isValidLinkedInProfileURL } from '@utils/common';
import { setUserData } from '@store/userDetailsSlice/userDetailsSlice';
import { IUserRegisterPayload } from '@api/auth/auth.types';
import { getLocalStorage, setLocalStorage } from '@utils/localStorage';
import { getFullNameLinkedInFunc, isProUserFunc } from '@src/models/user';
import { setMainFilters } from '@store/inboxSlice/inboxSlice';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import { AlertIcon, ArrowUpRightIcon } from '@src/hoc/withIconStyles';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';
import Avatar from '@components/ui/Avatar';
import Toast from '@components/ui/Toast';
import useWindowSize from '@hooks/useWindow';
import classes from './LinkedInStep.styles.module.scss';
import { getSenderChatDetails, sendChatMessage, sendChatMessageInFormFormat } from '@api/chats';
import { ISendChatMessagePayload } from '@api/chats/chats.types';

const LINKEDIN_URL_PREFIX = 'www.linkedin.com/in/';

function LinkedInStep({
	draftJWTToken,
	email,
	googleOAuthCode,
	otp,
	setLoadingState,
	setSendingMessageState,
	onRegisterError,
	receiverUsername,
	withAnimation,
	receiverName,
	message: messageToSend,
	selectedInboxCategory: selectedInboxCategoryToSend,
	selectedPdfFile: selectedAttachmentToSend,
	onLinkedInMetaDataAvailable,
	initialLinkedInProfileMetaData,
	initialLinkedInURL,
}: ILinkedInStepProps) {
	const { isMobile } = useWindowSize();
	const [linkedInURLState, setLinkedInURLState] = React.useState({
		value: initialLinkedInURL ?? '',
		error: '',
	});
	const [connectLinkedInState, setConnectLinkedInstate] = useState<TLinkedInConnectState>(
		initialLinkedInProfileMetaData ? 'PROFILE_DISPLAY' : 'LINKEDIN_URL'
	);

	const inputRef = useRef<HTMLInputElement>(null);
	const divToScrollEndRef = useRef<HTMLDivElement | null>(null);
	const clickedRememberBtn = useRef<boolean>(false);
	const toastRef = useRef<IRefProps>(null);

	const dispatch = useDispatch<AppDispatch>();

	const {
		callApi: register,
		status: registerStatus,
		errorStatus: registerErrorStatus,
		response: registerResponse,
	} = useFetch(registerUser);
	const { callApi: fetchUserDetails, status: fetchUserDetailsStatus } = useFetch(getUserDetails);
	const {
		callApi: callLinkedInProfileMetaData,
		status: callLinkedInProfileMetaDataStatus,
		response: callLinkedInProfileMetaDataResponse,
	} = useFetch(getLinkedInProfileMetaData);
	const { callApi: sendMessage, status: sendMessageStatus } = useFetch(sendChatMessage);
	const { callApi: callSendMessageFormData, status: callSendMessageFormDataStatus } = useFetch(
		sendChatMessageInFormFormat
	);
	const { callApi: callSenderChatDetails, status: callSenderChatDetailsStatus } =
		useFetch(getSenderChatDetails);

	const { isValid: isURLValid, profileLinkURL } = isValidLinkedInProfileURL(
		linkedInURLState.value.trim().toLocaleLowerCase(),
		LINKEDIN_URL_PREFIX
	);

	const handleCallLinkedInProfileMetaData = async () => {
		if (isLoading) return;

		const payload: IUserRegisterPayload = {
			email: email,
			linkedin_url: profileLinkURL,
			login_type: googleOAuthCode ? 'GOOGLE' : 'EMAIL',
			otp: otp,
			token_id: googleOAuthCode,
			draft_jwt_token: draftJWTToken,
		};

		try {
			const data = await callLinkedInProfileMetaData(payload);
			setConnectLinkedInstate('PROFILE_DISPLAY');
			mixpanelActions.trackOnboarding(
				mixPanelEvents.LINKEDIN_CONNECT_PREVIEW_SHOWN,
				receiverUsername,
				linkedInURLState.value
			);
			onLinkedInMetaDataAvailable(data, profileLinkURL);
		} catch (error) {
			mixpanelActions.trackOnboarding(
				mixPanelEvents.LINKEDIN_CONNECT_PREVIEW_ERROR,
				receiverUsername,
				linkedInURLState.value,
				error ? error : 'LinkedIn profile not found'
			);
			setLinkedInURLState((prevState) => {
				return {
					...prevState,
					error: error ? error : 'LinkedIn profile not found',
				};
			});
		}
	};

	const handleCallRegister = async () => {
		if (isLoading) return;

		toastRef.current?.unPublish();
		const payload: IUserRegisterPayload = {
			email: email,
			linkedin_url: profileLinkURL,
			login_type: googleOAuthCode ? 'GOOGLE' : 'EMAIL',
			otp: otp,
			token_id: googleOAuthCode,
			draft_jwt_token: draftJWTToken,
		};

		try {
			let resData = registerResponse;

			if (!resData) {
				resData = await register(payload);
			}

			const jwtToken = resData?.jwtToken ?? getLocalStorage(ACCESS_TOKEN) ?? null;

			if (!jwtToken) {
				await Promise.reject('There was some problem, please try again');
				return;
			}

			setLocalStorage(ACCESS_TOKEN, jwtToken);

			const userData = await fetchUserDetails(jwtToken);

			if (!userData) {
				await Promise.reject('There was some problem, please try again');
				return;
			}

			const currUserId = userData.userId;

			const senderChatDetails = await callSenderChatDetails({
				user_id: currUserId,
				receiver_username: receiverUsername,
			});

			const receiverUserId = senderChatDetails.senderDetails?.userId;

			if (!receiverUserId || !messageToSend) {
				await Promise.reject('There was some problem, please try again');
				return;
			}

			const chatPayload: ISendChatMessagePayload = {
				chat_id: null,
				content: messageToSend,
				content_type: 'TEXT',
				label: selectedInboxCategoryToSend ?? null,
				receiver_id: receiverUserId,
				sender_id: currUserId,
				timestamp: new Date().toISOString(),
				attachment: selectedAttachmentToSend ?? null,
			};

			if (selectedAttachmentToSend) {
				await callSendMessageFormData(chatPayload);
			} else {
				await sendMessage(chatPayload);
			}

			mixpanelActions.trackOnboarding(
				mixPanelEvents.LINKEDIN_CONNECT_SUCCESS_SHOW,
				receiverUsername
			);

			mixpanelActions.trackChatInboxCategory(
				mixPanelEvents.INBOX_MESSAGE_SUCCESS,
				receiverUsername ?? '',
				'no',
				chatPayload.label ?? '',
				chatPayload.attachment?.type,
				false
			);

			mixpanelActions.identifyUserAndSetEmail({
				email: userData.email,
				username: userData.username,
				userType: userData.userType,
				userID: userData.userId,
			});
			mixpanelActions.registerSecondaryUser(
				userData.isSecondaryUser ? 'yes' : 'no',
				userData.secondaryUserEmail
			);
			mixpanelActions.setUserLinkedInProperties(profileLinkURL, false, userData.username);

			dispatch(setUserData({ userData: userData ? { ...userData } : null }));

			isProUserFunc(userData) &&
				dispatch(
					setMainFilters({
						filtersToPrepend: [...mainFiltersArr],
					})
				);
		} catch (error) {
			mixpanelActions.trackOnboarding(
				mixPanelEvents.LINKEDIN_CONNECT_SUBMIT_FAILED,
				receiverUsername,
				linkedInURLState.value,
				error ? error : 'LinkedIn profile not found'
			);
			setLinkedInURLState((prevState) => {
				return {
					...prevState,
					error: error ? error : 'LinkedIn profile not found',
				};
			});
			toastRef.current?.publish();
		}
	};

	const handleSubmit = () => {
		if (isLoading) return;

		if (connectLinkedInState === 'LINKEDIN_URL') {
			mixpanelActions.trackOnboarding(
				mixPanelEvents.LINKEDIN_CONNECT_SUBMIT_CLICKED,
				receiverUsername,
				linkedInURLState.value
			);
		}

		if (!isURLValid) {
			inputRef.current?.focus();

			mixpanelActions.trackOnboarding(
				mixPanelEvents.LINKEDIN_CONNECT_SUBMIT_FAILED,
				receiverUsername,
				linkedInURLState.value,
				'Invalid LinkedIn URL'
			);

			setLinkedInURLState((prevState) => {
				return {
					...prevState,
					error: `Link doesn’t seem correct`,
				};
			});

			return;
		}

		const shouldCallFetchLinkedInProfileMetaData =
			connectLinkedInState === 'LINKEDIN_URL' || connectLinkedInState === 'RE_SUBMIT';

		if (shouldCallFetchLinkedInProfileMetaData) {
			handleCallLinkedInProfileMetaData();
			return;
		}

		if (connectLinkedInState === 'PROFILE_DISPLAY') {
			mixpanelActions.trackOnboarding(
				mixPanelEvents.LINKEDIN_CONNECT_PREVIEW_YES_CLICKED,
				receiverUsername,
				linkedInURLState.value
			);
			handleCallRegister();
			return;
		}
	};

	const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (isLoading) return;

		setLinkedInURLState({
			value: e.target.value,
			error: '',
		});
	};

	const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (isLoading) return;

		if (e.key === 'Enter') {
			handleSubmit();
		}
	};

	const handleNotMeBtnClick = () => {
		if (isLoading) return;

		mixpanelActions.trackOnboarding(
			mixPanelEvents.LINKEDIN_CONNECT_PREVIEW_NO_CLICKED,
			receiverUsername,
			linkedInURLState.value
		);
		setConnectLinkedInstate('RE_SUBMIT');
		setLinkedInURLState({ error: '', value: '' });
		onLinkedInMetaDataAvailable(null, '');
	};

	const handleKnowMyURLClick = () => {
		if (isLoading) return;

		mixpanelActions.trackOnboarding(
			mixPanelEvents.LINKEDIN_CONNECT_KNOW_URL_CLICKED,
			receiverUsername
		);
		window.open('https://www.linkedin.com/in/', '__blank');
		inputRef.current?.focus();
	};

	const handleOnClickRememberLinkedInURL = () => {
		if (isLoading) return;

		mixpanelActions.trackOnboarding(
			mixPanelEvents.LINKEDIN_CONNECT_REMEMBER_USERNAME_CLICKED,
			receiverUsername
		);
		clickedRememberBtn.current = true;
		setLinkedInURLState({
			value: LINKEDIN_URL_PREFIX,
			error: '',
		});
		inputRef.current?.focus();
	};

	const isLoading =
		registerStatus === 'loading' ||
		fetchUserDetailsStatus === 'loading' ||
		callLinkedInProfileMetaDataStatus === 'loading' ||
		callSendMessageFormDataStatus === 'loading' ||
		sendMessageStatus === 'loading' ||
		callSenderChatDetailsStatus === 'loading';

	const isFetchingLinkedInProfile = callLinkedInProfileMetaDataStatus === 'loading';

	const displaySendingMessageLoader = !isFetchingLinkedInProfile && isLoading;

	const isLinkedInURLError = !!linkedInURLState.error;
	const hideAddLinkedURLText = linkedInURLState.value.length > 0;

	const showInput = connectLinkedInState === 'LINKEDIN_URL' || connectLinkedInState === 'RE_SUBMIT';

	const linkedInProfileMetaDataToDisplay =
		callLinkedInProfileMetaDataResponse ?? initialLinkedInProfileMetaData;

	const showLinkedInProfile =
		connectLinkedInState === 'PROFILE_DISPLAY' && !!linkedInProfileMetaDataToDisplay;

	useEffect(() => {
		setLoadingState(isLoading);
	}, [isLoading]);

	useEffect(() => {
		setSendingMessageState(displaySendingMessageLoader);
	}, [displaySendingMessageLoader]);

	useEffect(() => {
		if (registerErrorStatus === 401) {
			onRegisterError();
		}
	}, [registerErrorStatus]);

	useEffect(() => {
		if (!receiverUsername) return;

		mixpanelActions.trackOnboarding(mixPanelEvents.LINKEDIN_CONNECT_PAGE_SHOWN, receiverUsername);
	}, [receiverUsername]);

	useEffect(() => {
		const handleResize = () => {
			// for ios
			window.scrollTo({
				behavior: 'instant',
				top: document.documentElement.scrollHeight - (window.visualViewport?.height ?? 0),
			});

			const ele = document.getElementById('root');

			// for android
			ele?.scrollIntoView({ behavior: 'smooth', block: 'end' });
		};

		window.visualViewport?.addEventListener('resize', handleResize);

		return () => {
			window.visualViewport?.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div
			ref={divToScrollEndRef}
			className={clsx(
				classes.connectLinkedInContainer,
				withAnimation && classes.animateConnectLinkedInContainer,
				!withAnimation && classes.connectLinkedInContainerHeight,
				isLoading && classes.connectLinkedInContainerLoading
			)}
		>
			{isFetchingLinkedInProfile && (
				<div className={classes.loadingContainer}>
					<Text variant="h2" lineHeight={2} semiBold>
						{'Fetching your public Linkedin profile...'}
					</Text>
					<div className={classes.profileContainerLoading}>
						<div className={classes.profileImage} />
						<div className={classes.profileName} />
						<div className={classes.profileHeadline} />
					</div>
				</div>
			)}

			{showInput && (
				<div className={classes.inputContentContainer}>
					<div className={classes.inputContentHeader}>
						<Text variant="h2" lineHeight={2} semiBold>
							{connectLinkedInState === 'LINKEDIN_URL' && 'Last step, add your LinkedIn URL'}
							{connectLinkedInState === 'RE_SUBMIT' && 'Recheck your LinkedIn URL'}
						</Text>
						<Text variant="p" tertiary tiny light lineHeight={1.6}>
							{`We’ll fetch your LinkedIn and show a preview to ${receiverName}`}
						</Text>
						{!isMobile && (
							<Button
								onClick={handleKnowMyURLClick}
								btnText={
									<Text
										variant="span"
										tertiary
										tiny
										lineHeight={1.6}
										light
										customClass={classes.helpText}
									>
										{`Know my URL`}
									</Text>
								}
								suffixIcon={<ArrowUpRightIcon size={1.6} />}
							/>
						)}
					</div>
					<input
						className={classes.input}
						name="linkedInURL"
						value={linkedInURLState.value}
						type="text"
						onChange={handleOnChangeInput}
						placeholder="www.linkedin.com/in/username"
						ref={inputRef}
						onKeyDown={handleInputKeyDown}
						autoComplete="off"
						autoFocus={true}
					/>

					<div className={classes.inputContentFooter}>
						<div>
							{isLinkedInURLError && (
								<Text variant="p" error light tiny lineHeight={1.6} customClass={classes.errorText}>
									{linkedInURLState.error}
								</Text>
							)}
							{isMobile && !isLinkedInURLError && (
								<Button
									btnText={
										<Text variant="span" brandPrimaryColor semiBold tiny lineHeight={1.6}>
											{'Enter LinkedIn username instead'}
										</Text>
									}
									onClick={handleOnClickRememberLinkedInURL}
									customClass={clsx(
										classes.openLinkedInBtn,
										hideAddLinkedURLText && classes.hideBtn
									)}
								/>
							)}
						</div>
						<Button
							btnText={
								<Text variant="span" white semiBold fontSize={1.3} lineHeight={1.8}>
									{'Submit'}
								</Text>
							}
							onClick={handleSubmit}
							primary
							customClass={classes.connectBtn}
						/>
					</div>
				</div>
			)}

			{showLinkedInProfile && (
				<div className={classes.profileContentContainer}>
					<Text variant="h2" semiBold lineHeight={2} customClass={classes.headerText}>
						{`Is this you?`}
					</Text>
					<div
						className={clsx(
							classes.profileContainer,
							!linkedInProfileMetaDataToDisplay?.headline && classes.noHeadline
						)}
					>
						<Avatar
							size={4.8}
							fallbackIcon={FallbackPicIcon}
							profilePicURL={linkedInProfileMetaDataToDisplay?.imageURL ?? ''}
							customRootClass={classes.profileImage}
						/>
						<Text variant="h3" small lineHeight={2}>
							{getFullNameLinkedInFunc(linkedInProfileMetaDataToDisplay)}
						</Text>

						{!!linkedInProfileMetaDataToDisplay?.headline && (
							<Text variant="p" tertiary light tiny lineHeight={1.6}>
								{linkedInProfileMetaDataToDisplay.headline}
							</Text>
						)}
					</div>
					<div className={classes.buttonsContainer}>
						<Button
							btnText={
								<Text variant="span" semiBold fontSize={1.3} lineHeight={1.8}>
									{`That’s not me`}
								</Text>
							}
							onClick={handleNotMeBtnClick}
							customClass={classes.cancelBtn}
						/>
						<Button
							btnText={
								<Text variant="span" white semiBold fontSize={1.3} lineHeight={1.8}>
									{`Yes, confirm send`}
								</Text>
							}
							onClick={handleSubmit}
							primary
							customClass={classes.confirmBtn}
						/>
					</div>
				</div>
			)}
			<Toast
				ref={toastRef}
				toastType={'ERROR'}
				header={linkedInURLState.error}
				icon={<AlertIcon size={1.8} className={classes.toastIcon} />}
			/>
		</div>
	);
}

export default LinkedInStep;
