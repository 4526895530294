import React, { useEffect } from 'react';
import Text from '@components/ui/Text';
import classes from './AccountUnderReview.styles.module.scss';
import { AlertIcon } from '@src/hoc/withIconStyles';
import useWindowSize from '@hooks/useWindow';
import { mixPanelEvents } from '@utils/constants';
import mixpanelActions from '@utils/mixpanel';
import clsx from 'clsx';
import ScrollableLayout from '@components/ScrollableLayout';

function AccountUnderReview() {
	const { isMobile } = useWindowSize();

	useEffect(() => {
		mixpanelActions.trackOnboardingPause(mixPanelEvents.ONBOARDING_VERIFICATION_PAUSED_SHOWN);
	}, []);

	return (
		<ScrollableLayout>
			<section
				className={clsx(classes.onBoardingContainer, classes.onBoardingContainerPaddingMobile)}
			>
				<section className={classes.accountUnderReviewContainer}>
					<AlertIcon size={!isMobile ? 10 : 5} className={classes.icon} />
					<Text
						variant="h1"
						extraMedium={!isMobile}
						medium2M={isMobile}
						customClass={classes.underReviewText}
					>
						{'Problem with verification'}
					</Text>
					<Text variant="p" secondary medium={!isMobile} small={isMobile} light>
						{
							'There were an issue while verifying your account. Please reach us at support@superdm.com if this persists. '
						}
					</Text>
				</section>
			</section>
		</ScrollableLayout>
	);
}

export default AccountUnderReview;
